import "../App.css";
import { useState, useEffect, Fragment } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import "../fonts/fonts.css";
import NavBar from "../components/NavBar";
import BasicNewsArticle from "../components/BasicNewsArticle";
import GlassContainer from "../components/GlassContainer";
import ProjectItem from "../components/ProjectItem";
import Footer from "../components/Footer";
import LoginWindow from "../components/LoginWindow";
import { FaRegCheckCircle } from "react-icons/fa";
import BounceLoader from "react-spinners/BounceLoader";
import AdminDashboard from "../Screens/AdminDashboard";
// import ClientDashboard from "./Screens/ClientDashboard";


import {
  submitContactForm,
  fetchNewsArticles,
  fetchPortfolioProjects,
} from "../api/dataService";

function Home() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768); // Adjust the threshold as needed
  const [theme, setTheme] = useState("dark");
  const [newsArticles, setNewsArticles] = useState([]);
  const [portfolioProjects, setPortfolioProjects] = useState([]);
  const [contactFormLoading, setContactFormLoading] = useState(false);
  const [contactFormIndicatorColor, setContactFormIndicatorColor] =
    useState("#6e6ef4");
  const [contactFormSubmitted, setContactFormSubmitted] = useState(false);
  const [contactFormHasErrors, setContactFormHasErrors] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const [loginWindowVisible, setLoginWindowVisible] = useState(false);

  

  const handleResize = () => {
    const isCurrentlyMobile = window.innerWidth < 768;
    setIsMobile(isCurrentlyMobile);
  };

  const handleMoveToComponent = (value) => {
    const sectionIds = ["home", "projects", "about", "contact"];
    const targetSection = document.getElementById(sectionIds[value]);

    if (targetSection) {
      targetSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    fetchNewsArticles()
      .then((response) => {
        if (response.status === "OK") {
          console.log(response.data);
          setNewsArticles(response.data);
        } else {
          console.log(`Error getting news articles:, ${response.status}`);
        }
      })
      .catch((error) => {
        console.log(`Error getting news articles:, ${error}`);
      });

    fetchPortfolioProjects()
      .then((response) => {
        if (response.status === "OK") {
          setPortfolioProjects(response.data);
        } else {
          console.log(`Error getting portfolio projects:, ${response.status}`);
        }
      })
      .catch((error) => {
        console.log(`Error getting portfolio projects:, ${error}`);
      });
  }, []);

  const handleToggleTheme = () => {
    if (theme === "dark") {
      setTheme("light");
    } else {
      setTheme("dark");
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    setContactFormLoading(true);
    e.preventDefault();

    submitContactForm(formData)
      .then((response) => {
        setTimeout(() => {
          if (response.status === "OK") {
            setContactFormLoading(false);
            setContactFormSubmitted(true);
            setFormData({ name: "", email: "", message: "" });
          } else {
            setContactFormHasErrors(true);
            setContactFormLoading(false);
          }
        }, 1000);
      })
      .catch((error) => {
        setContactFormHasErrors(true);
        setContactFormLoading(false);
        console.log(`error, ${error}`);
      });
  };

  const handleGoToLoginScreen = (navigate) => {
    navigate("/LoginScreen", { state: { isMobile: isMobile } });
  };

  const closeLoginWindow = () => {
    setLoginWindowVisible(false);
  };

  const handleOpenAdminDashboard = (userData, navigate) => {
    navigate("/AdminDashboard", { state: { userData: userData } });
  };
  const handleOpenClientDashboard = (userData, navigate) => {
    //
    navigate("/ClientDashboard", { state: { userData: userData } });
  };

  const navigate = useNavigate();
  return (
    <div
      className="App"
      style={{
        height: "100%",
        width: "100%",
        backgroundColor: "black",
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        position: "absolute",
        overflow: "hidden",
        zIndex: 0,
        overflowY: "scroll",
        position: "absolute",
      }}
    >

      {!isMobile && (
        <div
          style={{
            border: "0px solid yellow",
            width: "100%",
            padding: 50,
            position: "absolute",
          }}
        >
          <div
            onClick={() => handleGoToLoginScreen(navigate)}
            style={{
              zIndex: 12000,
              cursor: "pointer",
              // border: "3px solid yellow",
              display: "flex",
              marginLeft: "auto",
              borderRadius: 20,
              alignSelf: "center",
              width: 120,
              height: 40,
              backgroundColor: "#6e6ef4",
              alignItems: "center",
              justifyContent: "center",
              // position: 'absolute'
            }}
          >
            <p className="bolden-gate" style={{ color: "white" }}>
              Log in
            </p>
          </div>
        </div>
      )}

      <div
        id={"home"}
        style={{
          height: 500,
          width: "100%",
          position: "absolute",
          background:
            "linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(14, 14, 33, 0.6) 70%, rgba(25, 23, 53, 1) 100%)",
          zIndex: -1,
        }}
      />

      {(!isMobile && (
        <div
          style={{
            marginTop: 40,
            display: "flex",
            flexDirection: "column",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            pointerEvents: "none",
            zIndex: 2,
            border: "0px solid GREEN",
          }}
        >
          <div
            style={{
              // border: "3px solid red",
              display: "flex",
              flexDirection: "row",
              width: "80%",
              justifyContent: "flex-start",
              alignItems: "flex-start",
            }}
          >
            <h1
              style={{
                fontSize: 40,
                color: theme === "light" ? "#5a59c9" : "white",
                userSelect: "none",
              }}
            >
              JORDAN
            </h1>
            <h1
              className="bolden-gate"
              style={{
                fontSize: 40,
                color: theme === "light" ? "#5a59c9" : "#6e6ef4",
                userSelect: "none",
              }}
            >
              JANCIC
            </h1>
          </div>
          <div
            style={{
              border: "0px solid red",
              display: "flex",
              flexDirection: "row",
              width: "80%",
              justifyContent: "flex-start",
              alignItems: "flex-start",
            }}
          >
            <p
              style={{
                lineHeight: 0,
                marginRight: "auto",
                color: theme === "light" ? "#5a59c9" : "white",
                userSelect: "none",
                // width: '80%',
                letterSpacing: 7.3,
              }}
            >
              &nbsp;SOFTWARE&nbsp;SOLUTIONS&nbsp;
            </p>
          </div>
        </div>
      )) || (
        <div
          style={{
            marginTop: 40,
            display: "flex",
            flexDirection: "column",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
            pointerEvents: "none",
            zIndex: 2,
            border: "0px solid GREEN",
          }}
        >
          <div
            style={{
              // border: "3px solid red",
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h1
              style={{
                fontSize: 40,
                color: theme === "light" ? "#5a59c9" : "white",
                userSelect: "none",
              }}
            >
              JORDAN
            </h1>
            <h1
              className="bolden-gate"
              style={{
                fontSize: 40,
                color: theme === "light" ? "#5a59c9" : "#6e6ef4",
                userSelect: "none",
              }}
            >
              JANCIC
            </h1>
          </div>
          <div
            style={{
              border: "0px solid red",
              // display: "flex",
              // flexDirection: "row",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p
              style={{
                lineHeight: 0,
                // marginRight: "auto",
                color: theme === "light" ? "#5a59c9" : "white",
                // userSelect: "none",
                // width: '80%',
                letterSpacing: 7.3,
              }}
            >
              &nbsp;SOFTWARE&nbsp;SOLUTIONS&nbsp;
            </p>
          </div>
        </div>
      )}

      {/* {!isMobile && ( */}
      <NavBar
        handleMoveToComponent={(value) => handleMoveToComponent(value)}
        theme={theme}
        handleToggleTheme={() => handleToggleTheme()}
        handleGoToLoginScreen={() => handleGoToLoginScreen(navigate)}
      />
      {/* )} */}

      <div style={{ height: 45 }} />

      <div
        id={"projects"}
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          borderWidth: 0,
        }}
      >
        <GlassContainer
          disabled={isMobile}
          style={{ display: "flex", flexDirection: "column" }}
          theme={theme}
        >
          <div
            style={{
              borderWidth: 0,
              width: "100%",
              flexDirection: "row",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div style={{ width: "100%", marginTop: !isMobile ? 35 : 0 }}>
              <h1
                className="bolden-gate"
                style={{
                  fontSize: 22,
                  color: theme === "light" ? "#5a59c9" : "#6e6ef4",
                  userSelect: "none",
                  textAlign: !isMobile ? "left" : "left",
                  paddingTop: !isMobile ? 35 : 20,
                  padding: isMobile ? "0px 30px" : "0px 100px",
                }}
              >
                PROJECTS
              </h1>
              <div
                style={{
                  width: "100%",
                  border: "0px solid red",
                  paddingBottom: 20,
                }}
              >
                <p
                  // className="boldengate"
                  style={{
                    padding: isMobile ? "0px 30px" : "0px 100px",
                    color: "white",
                    textAlign: "left",
                  }}
                >
                  Explore a selection of my past projects that showcase my
                  skills and creativity as a developer. Each project reflects my
                  commitment to quality, innovation, and user-centric design.
                  From web applications to mobile solutions, I strive to create
                  impactful experiences that address real-world challenges.
                </p>
              </div>
            </div>
          </div>

          <div
            style={{
              paddingLeft: !isMobile ? 25 : 0,
              paddingRight: !isMobile ? 25 : 0,
              borderWidth: 0,
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              flexWrap: "wrap",
            }}
          >
            {portfolioProjects.map((project, index) => (
              <ProjectItem
                key={project.id}
                title={project.title}
                description={project.description}
                icon={project.icon}
                technologies={project.technologies}
                sourceCodeAvailable={project.sourceCodeAvailable}
                onClick={() => window.open(`${project.url}`, "_blank")}
                url={project.url}
              />
            ))}
          </div>
        </GlassContainer>
      </div>

      <div
        style={{
          marginTop: 10,
          display: "flex",
          flexDirection: "column",
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
          pointerEvents: "none",
          zIndex: 2,
          border: "0px solid GREEN",
        }}
      ></div>
      <div
        id={"about"}
        style={{
          width: "100%",
          display: "flex",
          border: "0px solid white",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        {newsArticles.map((article, index) => (
          <BasicNewsArticle
            key={index}
            theme={theme}
            articleDate={article.articleDate}
            imageUrl={article.image.url}
            imageCaption={article.imageCaption}
            imageCaptionAuthor={article.imageCaptionAuthor}
            title={article.title}
            subtitle={article.subtitle}
            subtitleAuthor={article.subtitleAuthor}
            articleBody={article.articleBody}
            articleAuthor={article.articleAuthor}
          />
        ))}
      </div>

      <div
        id={"contact"}
        style={{
          width: "100%",
          display: "flex",
          border: "0px solid white",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          marginTop: 0,
        }}
      >
        {(!isMobile && (
          <GlassContainer
            disabled={isMobile}
            style={{
              padding: 20,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              paddingBottom: 50,
            }}
            theme={theme}
          >
            <div style={{ width: "50%" }}>
              {!contactFormSubmitted && (
                <h2
                  style={{
                    fontSize: 22,
                    padding: !isMobile ? 30 : 0,
                    paddingTop: !isMobile ? 50 : 20,
                    color: "white",
                  }}
                >
                  Please complete the form below:
                </h2>
              )}

              <div
                style={{
                  width: "100%",
                  border: "0px solid white",
                  display: "flex",
                  justifyContent: "center",
                  height: contactFormLoading === true ? 350 : "auto",
                }}
              >
                {(contactFormLoading && (
                  <div
                    className="sweet-loading"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      border: "0px solid white",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <BounceLoader
                      color={contactFormIndicatorColor}
                      loading={contactFormLoading}
                      size={100}
                      aria-label="Loading Spinner"
                      data-testid="loader"
                      speedMultiplier={1}
                    />
                    <h3
                      style={{
                        fontSize: 25,
                        padding: !isMobile ? 30 : 0,
                        color: "white",
                      }}
                    >
                      making contact...
                    </h3>
                  </div>
                )) ||
                  (contactFormSubmitted && (
                    <div
                      style={{
                        width: "100%",
                        flexDirection: "column",
                        border: "0px solid white",
                        display: "flex",
                        justifyContent: "center",
                        height: 350,
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <FaRegCheckCircle
                        size={55}
                        style={{ color: "#03fc0b" }}
                      />
                      <h3
                        style={{
                          fontSize: 25,
                          padding: !isMobile ? 15 : 0,
                          color: "#03fc0b",
                          paddingLeft: 30,
                          paddingRight: 30,
                        }}
                      >
                        Message received! I'll be in touch shortly.
                      </h3>
                    </div>
                  )) || (
                    <form
                      onSubmit={handleSubmit}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "85%",
                      }}
                    >
                      <input
                        type="text"
                        name="name"
                        placeholder="Your Name"
                        value={formData.name}
                        onChange={handleInputChange}
                        required
                        style={{
                          padding: 10,
                          margin: 10,
                          border: "1px solid #ccc",
                          borderRadius: 5,
                          outline: "none",
                          fontSize: 16,
                          color: "black",
                        }}
                      />
                      <input
                        type="email"
                        name="email"
                        placeholder="Your Email"
                        value={formData.email}
                        onChange={handleInputChange}
                        required
                        style={{
                          padding: 10,
                          margin: 10,
                          border: "1px solid #ccc",
                          borderRadius: 5,
                          outline: "none",
                          fontSize: 16,
                          color: "black",
                        }}
                      />
                      <textarea
                        name="message"
                        placeholder="Your Message"
                        value={formData.message}
                        onChange={handleInputChange}
                        required
                        rows="4"
                        style={{
                          padding: 10,
                          margin: 10,
                          border: "1px solid #ccc",
                          borderRadius: 5,
                          outline: "none",
                          fontSize: 16,
                          color: "black",
                        }}
                      />
                      <button
                        type="submit"
                        style={{
                          padding: 10,
                          margin: 10,
                          backgroundColor:
                            theme === "light" ? "#5a59c9" : "#6e6ef4",
                          color: "white",
                          border: "none",
                          borderRadius: 5,
                          cursor: "pointer",
                          fontSize: 16,
                          transition: "background-color 0.3s",
                        }}
                        onMouseEnter={(e) => {
                          e.target.style.backgroundColor =
                            theme === "light" ? "#6e6ef4" : "white";
                          e.target.style.color =
                            theme === "light" ? "#6e6ef4" : "black";
                        }}
                        onMouseLeave={(e) => {
                          e.target.style.backgroundColor =
                            theme === "light" ? "#6e6ef4" : "#6e6ef4";
                          e.target.style.color =
                            theme === "light" ? "#ffffff" : "white";
                        }}
                      >
                        Send Message
                      </button>
                      {contactFormHasErrors && (
                        <p
                          className="bolden-gate"
                          style={{ color: "red", fontSize: 20 }}
                        >
                          Error: Please try again later!
                        </p>
                      )}
                      <div style={{ height: 50 }}></div>
                    </form>
                  )}
              </div>
              {/*  */}
            </div>
            <div
              style={{
                width: "50%",
                border: "0px solid red",
                display: "flex",
                flexDirection: "column",
                height: "100%",
                marginBottom: "auto",
              }}
            >
              <h2
                className="bolden-gate"
                style={{
                  fontSize: 25,
                  padding: !isMobile ? 30 : 0,
                  paddingTop: !isMobile ? 50 : 20,
                  color: "#6e6ef4",
                  letterSpacing: 2,
                }}
              >
                CONTACT
              </h2>
              <h3
                style={{
                  fontSize: 22,
                  paddingLeft: !isMobile ? 35 : 0,
                  color: "white",
                  textAlign: "left",
                }}
              >
                Feel free to reach out if you have any questions or need more
                information. I'm here to help. Just fill out the form, and I'll
                get back to you as soon as possible. Looking forward to
                connecting with you!
              </h3>
              <h3
                style={{
                  marginTop: 5,
                  fontSize: 22,
                  paddingLeft: !isMobile ? 35 : 0,
                  color: "white",
                  textAlign: "left",
                }}
              >
                - Jordan
              </h3>
            </div>
          </GlassContainer>
        )) || (
          <GlassContainer
            disabled={isMobile}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              paddingBottom: 50,
            }}
            theme={theme}
          >
            <h2
              style={{
                fontSize: 25,
                padding: !isMobile ? 30 : 0,
                paddingTop: !isMobile ? 50 : 20,
                color: "white",
                letterSpacing: 10,
              }}
            >
              CONTACT
            </h2>
            <div
              style={{
                width: "100%",
                border: "0px solid white",
                display: "flex",
                justifyContent: "center",
                height: contactFormLoading === true ? 350 : "auto",
              }}
            >
              {(contactFormLoading && (
                <div
                  className="sweet-loading"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    border: "0px solid white",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <BounceLoader
                    color={contactFormIndicatorColor}
                    loading={contactFormLoading}
                    size={100}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                    speedMultiplier={1}
                  />
                  <h3
                    style={{
                      fontSize: 25,
                      padding: !isMobile ? 30 : 0,
                      color: "white",
                    }}
                  >
                    making contact...
                  </h3>
                </div>
              )) ||
                (contactFormSubmitted && (
                  <div
                    style={{
                      width: "100%",
                      flexDirection: "column",
                      border: "0px solid white",
                      display: "flex",
                      justifyContent: "center",
                      height: 350,
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <FaRegCheckCircle size={55} style={{ color: "#03fc0b" }} />
                    <h3
                      style={{
                        fontSize: 25,
                        padding: !isMobile ? 15 : 0,
                        color: "#03fc0b",
                        paddingLeft: 30,
                        paddingRight: 30,
                      }}
                    >
                      Message received! I'll be in touch shortly.
                    </h3>
                  </div>
                )) || (
                  <form
                    onSubmit={handleSubmit}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                      maxWidth: 400,
                    }}
                  >
                    <input
                      type="text"
                      name="name"
                      placeholder="Your Name"
                      value={formData.name}
                      onChange={handleInputChange}
                      required
                      style={{
                        padding: 10,
                        margin: 10,
                        border: "1px solid #ccc",
                        borderRadius: 5,
                        outline: "none",
                        fontSize: 16,
                        color: "black",
                      }}
                    />
                    <input
                      type="email"
                      name="email"
                      placeholder="Your Email"
                      value={formData.email}
                      onChange={handleInputChange}
                      required
                      style={{
                        padding: 10,
                        margin: 10,
                        border: "1px solid #ccc",
                        borderRadius: 5,
                        outline: "none",
                        fontSize: 16,
                        color: "black",
                      }}
                    />
                    <textarea
                      name="message"
                      placeholder="Your Message"
                      value={formData.message}
                      onChange={handleInputChange}
                      required
                      rows="4"
                      style={{
                        padding: 10,
                        margin: 10,
                        border: "1px solid #ccc",
                        borderRadius: 5,
                        outline: "none",
                        fontSize: 16,
                        color: "black",
                      }}
                    />
                    <button
                      type="submit"
                      style={{
                        padding: 10,
                        margin: 10,
                        backgroundColor:
                          theme === "light" ? "#5a59c9" : "#6e6ef4",
                        color: "white",
                        border: "none",
                        borderRadius: 5,
                        cursor: "pointer",
                        fontSize: 16,
                        transition: "background-color 0.3s",
                      }}
                      onMouseEnter={(e) => {
                        e.target.style.backgroundColor =
                          theme === "light" ? "#6e6ef4" : "white";
                        e.target.style.color =
                          theme === "light" ? "#6e6ef4" : "black";
                      }}
                      onMouseLeave={(e) => {
                        e.target.style.backgroundColor =
                          theme === "light" ? "#6e6ef4" : "#6e6ef4";
                        e.target.style.color =
                          theme === "light" ? "#ffffff" : "white";
                      }}
                    >
                      Send Message
                    </button>
                    {contactFormHasErrors && (
                      <p
                        className="bolden-gate"
                        style={{ color: "red", fontSize: 20 }}
                      >
                        Error: Please try again later!
                      </p>
                    )}
                    <div style={{ height: 50 }}></div>
                  </form>
                )}
            </div>
          </GlassContainer>
        )}
      </div>

      <div style={{ height: 20 }} />

      <div style={{ position: "fixed", bottom: 0, width: "100%", zIndex: 999 }}>
        {/* {isMobile && (
          <BottomTabNavigator navigate={(value) => handleMoveToComponent(value)} icons={[FaHouseUser, FaProjectDiagram, FaSmile, FaPaperPlane]} theme={theme} handleToggleTheme={() => handleToggleTheme()} />
        )} */}
      </div>
      {(!isMobile && (
        <>
          <div style={{ height: 50 }}></div>
          <Footer
            handleMoveToComponent={(value) => handleMoveToComponent(value)}
          />
        </>
      )) || (
        <>
          <div style={{ height: 50 }}></div>
          <Footer
            handleMoveToComponent={(value) => handleMoveToComponent(value)}
          />
          <div style={{ height: 80 }}></div>
        </>
      )}
    </div>
  );
}

export default Home;
