import React, { useState, useEffect, useRef } from "react";
import { FaTimes } from "react-icons/fa";
import Hixenis from "../images/HIXENIS_NEW_W_P.png";
import BounceLoader from "react-spinners/BounceLoader";
import { loginWithEmailAndPassword } from "../api/dataService";

function LoginWindow({ style, windowHeight, closeWindow, handleOpenAdminDashboard, handleOpenClientDashboard }) {
  const [visible, setVisible] = useState(false);
  const [loading, setLoadingState] = useState(false);
  const [loadingStateMessage, setLoadingStateMessage] = useState("");
  const [messageOverlayOpen, setMessageOverlayOpen] = useState(false);
  const [messageOverlayTitleText, setMessageOverlayTitleText] = useState("");
  const [messageOverlayTitleTextColor, setMessageOverlayTitleTextColor] = useState("");
  const [messageOverlayText, setMessageOverlayText] = useState("");
  const [fadeIn, setFadeIn] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [messageOverlayWidth, setMessageOverlayWidth] = useState(0); //max 255

  useEffect(() => {
    setVisible(true); // Trigger fade-in for main window
    setFadeIn(true); // Trigger fade-in for loading overlay

    // Disable scrolling
    const disableScroll = () => window.scrollTo(0, 0);
    window.addEventListener("scroll", disableScroll);

    // Cleanup on unmount
    return () => {
      window.removeEventListener("scroll", disableScroll);
    };
  }, []);

  // const handleLoginClick = () => {
  //   setLoadingState(true);
  //   setLoadingStateMessage("Logging in...");
  //   setTimeout(() => {
  //     setLoadingState(false);
  //   }, 1000); // Time in milliseconds (2000ms = 2 seconds)
  // };
  const handleRegisterClick = () => {
    setLoadingState(true);
    setLoadingStateMessage("Registering...");
    setTimeout(() => {
      setLoadingState(false);
    }, 1000); // Time in milliseconds (2000ms = 2 seconds)
  };
  const handleResetPasswordClick = () => {
    setLoadingState(true);
    setLoadingStateMessage("Resetting password...");
    setTimeout(() => {
      setLoadingState(false);
    }, 1000); // Time in milliseconds (2000ms = 2 seconds)
  };

  const handleLoginClick = (e) => {
    // Input validation
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    
    if (!email || !emailRegex.test(email)) {
      setMessageOverlayOpen(true);
      const interval = setInterval(() => {
        setMessageOverlayWidth(prevWidth => {
          if (prevWidth < 255) {
            return prevWidth + 1;
          } else {
            // Once width reaches 255, clear interval and reset the width to 0
            clearInterval(interval);
            setTimeout(() => {
              setMessageOverlayWidth(0);
            }, 500); // Wait for a short delay before resetting to 0
            return prevWidth;
          }
        });
      }, 10); // Adjust the interval duration for the speed of the update
      setMessageOverlayTitleText('Invalid Email');
      setMessageOverlayText('Please enter a valid email address.');
      setTimeout(() => {
        setMessageOverlayOpen(false);
      }, 2500); // Hide after 3 seconds
      return; // Stop further processing
    }
  
    if (!password || password.length < 8) {
      setMessageOverlayOpen(true);
      const interval = setInterval(() => {
        setMessageOverlayWidth(prevWidth => {
          if (prevWidth < 255) {
            return prevWidth + 1;
          } else {
            // Once width reaches 255, clear interval and reset the width to 0
            clearInterval(interval);
            setTimeout(() => {
              setMessageOverlayWidth(0);
            }, 500); // Wait for a short delay before resetting to 0
            return prevWidth;
          }
        });
      }, 10); // Adjust the interval duration for the speed of the update
      setMessageOverlayTitleText('Invalid Password');
      setMessageOverlayText('Password must be at least 8 characters long.');
      setTimeout(() => {
        setMessageOverlayOpen(false);
      }, 2500); // Hide after 3 seconds
      return; // Stop further processing
    }
  
    setLoadingState(true);
    setLoadingStateMessage("Logging in...");
  
    loginWithEmailAndPassword({ email: email, password: password })
      .then((response) => {
        if (response.status === "OK") {
          const userData = response.data.user;
          const userToken = response.data.token;
          console.log(response);
          setLoadingState(false);
          setMessageOverlayOpen(true);
          const interval = setInterval(() => {
            setMessageOverlayWidth(prevWidth => {
              if (prevWidth < 255) {
                return prevWidth + 1;
              } else {
                // Once width reaches 255, clear interval and reset the width to 0
                clearInterval(interval);
                setTimeout(() => {
                  setMessageOverlayWidth(0);
                }, 500); // Wait for a short delay before resetting to 0
                return prevWidth;
              }
            });
          }, 10); // Adjust the interval duration for the speed of the update
          setMessageOverlayTitleText('Login Successful');
          setMessageOverlayTitleTextColor('#03fc0b');
          setMessageOverlayText('Your login was successful. Please wait a moment while we securely enter you into our domain.');
          setEmail("");
          setPassword("");
          setTimeout(() => {
            setMessageOverlayOpen(false);
            console.log('userdata', userData);
            if(userData.role === 'admin') {
              handleOpenAdminDashboard({user: userData, token: userToken});
            }
            else {
              handleOpenClientDashboard({user: userData, token: userToken});
            }
            
            
          }, 2500); // Hide after 3 seconds
        } else {
          console.log(response);
          setLoadingState(false);
          setMessageOverlayOpen(true);
          const interval = setInterval(() => {
            setMessageOverlayWidth(prevWidth => {
              if (prevWidth < 255) {
                return prevWidth + 1;
              } else {
                // Once width reaches 255, clear interval and reset the width to 0
                clearInterval(interval);
                setTimeout(() => {
                  setMessageOverlayWidth(0);
                }, 500); // Wait for a short delay before resetting to 0
                return prevWidth;
              }
            });
          }, 10); // Adjust the interval duration for the speed of the update
          setMessageOverlayTitleText('Login failed');
          setMessageOverlayTitleTextColor('red');
          setMessageOverlayText(`${response.detail}`);
  
          setTimeout(() => {
            setMessageOverlayOpen(false);
          }, 2500); // Hide after 3 seconds
        }
      })
      .catch((error) => {
        setLoadingState(false);
        console.log(`error, ${error}`);
      });
  };
  

  return (
    <div
      // onClick={closeWindow} // Close when clicking outside the modal
      style={{
        ...style,
        // border: '3px solid red',
        // // width: "100%",
        // // height: windowHeight || "100vh",
        position: "absolute",
        // top: 0,
        // left: 0,
        // zIndex: 12000,
        // // backgroundColor: "rgba(0, 0, 0, 0.8)",
        // // opacity: visible ? 1 : 0,
        // // transition: "opacity 0.2s ease-in-out",
        // display: "flex",
        // alignItems: "center",
        // justifyContent: "center",
      }}
    >
      {loading && (
        <div
          style={{
            position: "absolute",
            zIndex: 12000,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            opacity: fadeIn ? 1 : 0, // Apply fade-in effect
            transition: "opacity 1s ease-in-out", // Smooth transition,
          }}
        >
          <div
            style={{
              width: 200,
              height: 200,
              backgroundColor: "rgba(44, 41, 63, 1)",
              borderRadius: 35,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              color: "white",
              boxShadow: "0 0px 15px rgba(0, 0, 0, .8)",
              border: "3px solid #6E6EF4",
            }}
          >
            <BounceLoader
              color={"#717DFA"}
              size={100}
              aria-label="Loading Spinner"
              data-testid="loader"
              speedMultiplier={1}
            />
            <p style={{ marginTop: 5 }}>{loadingStateMessage}</p>
          </div>
        </div>
      )}

      {messageOverlayOpen && (
        <div
          style={{
            position: "absolute",
            zIndex: 12000,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            opacity: fadeIn ? 1 : 0, // Apply fade-in effect
            transition: "opacity 1s ease-in-out", // Smooth transition,
          }}
        >
          <div
            style={{
              width: 350,
              height: 200,
              backgroundColor: "rgba(44, 41, 63, 1)",
              borderRadius: 35,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              color: "white",
              boxShadow: "0 0px 15px rgba(0, 0, 0, .8)",
              border: "3px solid #6E6EF4",
            }}
          >
            <div style={{border: '0px solid red', width: '100%', flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: 5}}>
              <p className="bolden-gate" style={{ marginTop: 5, fontSize: 25, color: messageOverlayTitleTextColor }}>{messageOverlayTitleText}</p>
            </div>
            <div style={{border: '0px solid red', width: '100%', flex: 4, display: 'flex',  justifyContent: 'center', padding: 15}}>
              <p style={{ marginTop: 5 }}>{messageOverlayText}</p>
            </div>
            
            <div style={{border: '3px solid white', width: messageOverlayWidth, height: 1, marginBottom: 5, display: 'flex',  justifyContent: 'center', }}>
              {/* this is the bar that will extend */}
            </div>
          </div>
        </div>
      )}

      <div
        // onClick={handleBackgroundClick} // Prevent closing when clicking inside modal
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          // ref={modalRef} // Attach ref to modal container
          className="frosted-glass-container-blur-plus-plus-plus"
          style={{
            border: "1.5px solid #6e6ef4",
            width: "100%",
            maxWidth: "375px",
            height: 600,
            borderRadius: 30,
            transition: "width 0.3s ease",
            boxShadow: "0 0px 15px rgba(120, 0, 255, .5)",
            position: "relative",
          }}
        >
          <div style={{ width: "100%", color: "white" }}>
            <FaTimes
              onClick={closeWindow}
              size={25}
              style={{
                marginLeft: 15,
                marginTop: 15,
                color: "#a6a6fc",
                cursor: "pointer",
              }}
            />
          </div>

          <div
            style={{
              height: "15%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              src={Hixenis}
              alt="Logo"
              style={{
                maxWidth: "80%",
                maxHeight: "100%",
                objectFit: "contain",
              }}
            />
          </div>

          <div
            style={{
              height: "15%",
              color: "white",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: 15,
              textAlign: "center",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                fontSize: "1.5rem",
                fontWeight: "bold",
                color: "#a6a6fc",
                // border: '2px solid yellow',
                paddingBottom: 0,
                marginBottom: 0,
                lineHeight: 1,
              }}
            >
              Sign Into Your Account
            </div>
            <div style={{ fontSize: "1rem" }}>
              Access your client dashboard and stay connected.
            </div>
          </div>

          <div
            style={{
              height: "10%",
              color: "white",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <input
              style={{
                color: "black",
                width: "80%",
                height: 50,
                padding: 15,
                borderRadius: 5,
              }}
              type={"email"}
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>

          <div
            style={{
              height: "10%",
              color: "white",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <input
              style={{
                color: "black",
                width: "80%",
                height: 50,
                padding: 15,
                borderRadius: 5,
              }}
              type={"password"}
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>

          <div
            style={{
              height: "8%",
              color: "white",
              display: "flex",
              marginTop: 20,
              justifyContent: "center",
              // border: '2px solid red',
            }}
          >
            <button
              className="bolden-gate"
              onClick={() => handleLoginClick()}
              style={{
                color: "white",
                backgroundColor: "#6e6ef4",
                width: "80%",
                height: 50,
                padding: 15,
                borderRadius: 15,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              // type={"submit"}
            >
              Log In
            </button>
          </div>

          <div
            style={{
              // border: '2px solid red',
              height: "8%",
              color: "white",
              display: "flex",
              // alignItems: "center",
              justifyContent: "center",
            }}
          >
            <button
              onClick={() => handleRegisterClick()}
              style={{
                color: "white",
                width: "80%",
                height: 50,
                padding: 15,
                borderRadius: 5,
                // marginBottom: 'auto'
              }}
            >
              Register
            </button>
          </div>
          <div
            style={{
              height: "10%",
              color: "#a6a6fc",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <div>Forgot your password?</div>
            <div>
              <button
                onClick={() => handleResetPasswordClick()}
                style={{
                  color: "#a6a6fc",
                  fontWeight: "bold",
                  textDecoration: "underline",
                  border: "none",
                  backgroundColor: "transparent",
                  color: "white",
                }}
              >
                Reset Password
              </button>
            </div>
          </div>
          <div
            style={{
              height: "15%",
              color: "#a6a6fc",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
              padding: 15,
              fontSize: "0.65rem",
              lineHeight: "1.5",
            }}
          >
            By signing in, you agree to our Terms of Service and Privacy Policy.
            Your data may be used in accordance with our security guidelines.
            Please review our Cookie Policy for more information on data
            collection practices.
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginWindow;
