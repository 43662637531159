import React, { useState, useEffect } from "react";
import "../App.css";
import { useLocation, useNavigate } from "react-router-dom";
import Hixenis from "../images/HIXENIS_NEW_W_P.png";
import Xena from "../images/xena.jpg";
import Xena2 from "../images/xena2.jpg";
import Code from "../images/code.jpg";
import zIndex from "@mui/material/styles/zIndex";
import DashboardNavBar from "../components/DashboardNavbar";
import moment from 'moment';

function ClientDashboard() {
  const location = useLocation();
  const { userData } = location.state || {}; // Safely extract data

  const [user, setUser] = useState(userData.user);
  const [userNameFirst, setUserNameFirst] = useState(userData.user.firstName);
  const [userNameLast, setUserNameLast] = useState(userData.user.lastName);
  const [userToken, setUserToken] = useState(userData.token);
  const [subscriptions, setSubscriptions] = useState([
    { _id: "aebvs213sd2", type: 'product', active: true, thumbnailURL: require("../images/xena.jpg"), name: "XENA", description: "Your personalized GPT-4-powered chat assistant, designed to enhance customer interaction and provide seamless support on your business website through intelligent, real-time responses tailored to your specific needs and goals.", purchaseDate: new Date(), renewalDate: new Date() },
    // { _id: "tdsda213sad", type: 'service', active: false, name: "Consulting Level 1", description: "Comprehensive software development consultation services tailored to help your business navigate technical challenges, optimize development processes, and implement innovative solutions for long-term success.", purchaseDate: new Date(), renewalDate: new Date() },
  ]);
  const [subscriptionsLoading, setSubscriptionsLoading] = useState(false);

  const [products, setProducts] = useState([
    { _id: "tdsda213sad", type: 'service', active: false, thumbnailURL: require("../images/code.jpg"), name: "Consulting", description: "Comprehensive software development consultation services tailored to help your business navigate technical challenges, optimize development processes, and implement innovative solutions for long-term success.", purchaseDate: new Date(), renewalDate: new Date(), subscriptionPrice: 4999, plan: 'Monthly' },
    { _id: "aebvs213sd2", type: 'product', active: true, thumbnailURL: require("../images/xena.jpg"), name: "XENA", description: "Your personalized GPT-4-powered chat assistant, designed to enhance customer interaction and provide seamless support on your business website through intelligent, real-time responses tailored to your specific needs and goals.", purchaseDate: new Date(), renewalDate: new Date(), subscriptionPrice: 4999, plan: 'Monthly' },
    
  ]);
  const [productsLoading, setProductsLoading] = useState(false);

  const [isMobile, setIsMobile] = useState(window.innerWidth < 900); // Adjust the threshold as needed
  const handleResize = () => {
    const isCurrentlyMobile = window.innerWidth < 900;
    setIsMobile(isCurrentlyMobile);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleLogout = (navigate) => {
    navigate("/");
  };

  const navigate = useNavigate();

  return (
    <div style={styles.container}>
      <div style={styles.coloredBackground} />
      {(!isMobile && (
        <div
          style={{
            width: "100%",
            height: 175,
            border: "0px solid red",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <div
            style={{
              width: "30%",
              height: "100%",
              border: "0px solid blue",
              height: "100%",
              padding: 30,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <img
              src={Hixenis}
              alt="Logo"
              style={{
                maxWidth: 250,
                maxHeight: "100%",
                objectFit: "contain",
              }}
            />
          </div>
          <div
            style={{
              display: "flex",
              width: "40%",
              border: "0px solid green",
              height: "100%",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              // padding: 30,
            }}
          >
            <h2
              style={{
                color: "#7484F9",
                fontSize: 22,
                paddingBottom: 10,
                // letterSpacing: 2
              }}
              // className="bolden-gate"
            >
              Client Dashboard
            </h2>
            <p style={{ color: "white" }}>
              {`Welcome back, ${userNameFirst}!`}
            </p>
          </div>

          <div
            style={{
              width: "30%",
              border: "0px solid yellow",
              height: "100%",
              padding: 30,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              // flexDirection: 'column'
            }}
          >
            <div
              onClick={() => handleLogout(navigate)}
              style={{
                zIndex: 12000,
                cursor: "pointer",
                // border: "3px solid yellow",
                display: "flex",
                // marginLeft: "auto",
                borderRadius: 20,
                // alignSelf: "center",
                width: 120,
                height: 40,
                backgroundColor: "#6e6ef4",
                alignItems: "center",
                justifyContent: "center",
                // position: 'absolute'
              }}
            >
              <p className="bolden-gate" style={{ color: "white" }}>
                Log out
              </p>
            </div>
          </div>
        </div>
      )) || (
        <div
          style={{
            width: "100%",
            height: 175,
            border: "0px solid red",

            // flexDirection: "row",
          }}
        >
          <div
            style={{
              width: "100%",
              height: "100%",
              border: "0px solid blue",
              height: "100%",
              padding: 30,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <img
              src={Hixenis}
              alt="Logo"
              style={{
                maxWidth: 400,
                maxHeight: "100%",
                objectFit: "contain",
              }}
            />
            <div
              style={{
                display: "flex",
                // width: "40%",
                border: "0px solid green",
                height: "100%",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                // padding: 30,
              }}
            >
              <h2
                style={{
                  color: "#7484F9",
                  fontSize: 22,
                  paddingBottom: 10,
                  // letterSpacing: 2,
                  // lineHeight: 1,
                }}
                // className="bolden-gate"
              >
                Client Dashboard
              </h2>
              <p style={{ color: "white", lineHeight: 0 }}>
                {`Welcome back, ${userNameFirst}!`}
              </p>
            </div>
          </div>
        </div>
      )}

      <div
        style={{
          width: "100%",
          // height: 175,
          border: "0px solid yellow",
          // display: "flex",
          // flexDirection: "row",
        }}
      >
        <DashboardNavBar
          handleMoveToComponent={(value) => alert()}
          theme={"dark"}
          handleToggleTheme={() => alert()}
          handleGoToLoginScreen={() => handleLogout(navigate)}
          // style={{border: '3px solid white'}}
        />
      </div>
      <div
        style={{
          width: "100%",
          // padding: 30,
          // height: 175,
          // border: "3px solid blue",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          // flexDirection: "row",
        }}
      >
        {(!isMobile && (
          <div
            className="frosted-glass-container-blur-plus-plus"
            style={{
              marginTop: 15,
              border: "1px solid #3c4684",
              width: "75%",
              padding: 30,
              borderRadius: 5,
              // height: '100vh',
              // border: "3px solid blue",
              display: "flex",
              // alignItems: 'center',
              // justifyContent: 'center'
              flexDirection: "row",
            }}
          >
            <div style={{ flexDirection: "column", width: "100%" }}>
              <div
                style={{ display: "flex", flexDirection: "row", width: "100%" }}
              >
                <div style={{ border: "0px solid yellow", flex: 1 }}></div>
                <div style={{ border: "0px solid yellow", flex: 1 }}></div>
                <div style={{ border: "0px solid yellow", flex: 1 }}>
                  <h2
                    style={{ color: "white", fontSize: 18, textAlign: "right" }}
                  >
                    {/* {`You have ${services.length} active services`} */}
                  </h2>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  borderRadius: 15,
                  flexDirection: "column",
                  width: "100%",
                  // backgroundColor: "#1d0e54",
                  backgroundColor: "#1c1538",
                  border: "1px solid #7484F9",
                  padding: 15,
                  minHeight: 250,
                  // justifyContent: "center",
                }}
              >
                {/*  */}
                <div style={{ flexDirection: "column", width: "100%" }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                    }}
                  >
                    <div style={{ borderBottom: "1px solid #34227c", flex: 1 }}>
                      <h2
                        style={{
                          // color: "#7484F9",
                          // color: "#03fc0b",
                          color: 'white',
                          fontSize: 18,
                          padding: 10,
                          paddingTop: 0,
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          // position: 'absolute'
                        }}
                        className="bolden-gate"
                      >
                        Your Products & Services
                      </h2>
                    </div>
                    <div
                      style={{ borderBottom: "1px solid #34227c", flex: 1 }}
                    ></div>
                    <div style={{ borderBottom: "1px solid #34227c", flex: 1 }}>
                      <h2
                        style={{
                          color: "white",
                          fontSize: 18,
                          textAlign: "right",
                        }}
                      >
                        {/* {`You have ${services.length} active services`} */}
                      </h2>
                    </div>
                  </div>

                  {/*  */}
                </div>
                <div
                  style={{
                    textAlign: "center",
                    width: "100%",
                    minHeight: 250,
                    border: "0px solid white",
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  {!subscriptionsLoading && (
                    (subscriptions.length > 0 && (
                      subscriptions.map((subscription, index) => (
                        <div
                          // className="frosted-glass-container-blur-plus-plus"
                          
                          style={{
                            cursor: 'pointer',
                            userSelect: "none",
                            textAlign: "center",
                            width: "100%",
                            // minHeight: 200,
                            // border: subscription.active ? '1px solid white' :  "1px solid #7484F9",
                            border: "2px solid #7484F9",
                            // alignItems: "center",
                            display: "flex",
                            justifyContent: "center",
                            backgroundColor: '#0d0433',
                            backgroundColor: '#281c56',
                            borderRadius: 25,
                            flexDirection: 'column',
                            marginTop: 5,
                            marginBottom: 'auto',
                            // boxShadow: subscription.active ? "0 0px 10px rgba(255, 255, 255, .4)" : "0 0px 15px rgba(0, 0, 0, .5)" ,
                            // boxShadow: "0 0px 15px rgba(0, 0, 0, .5)" ,
                          }}
                        >

                        <div style={{width: '100%', flexDirection: 'row', display: 'flex'}}>
                        <div style={{width: '25%', border: '0px solid yellow', padding: 10,}}>
                          <img
                            src={subscription.thumbnailURL}
                            alt="Xena AI Assistant"
                            style={{
                              // maxWidth: 250,
                              maxHeight: "100%",
                              objectFit: "contain",
                              borderRadius: 25,
                              border: '2px solid #3C4684'
                            }}
                          />
                        </div>
                        <div
                            style={{
                              border: "0px solid white",
                              height: '100%',
                              width: "75%",
                              display: 'flex',
                              flexDirection: 'column',
                              marginBottom: 'auto',
                              minHeight: 180,
                              maxHeight: 200,
                              // flexDirection: 'flex-end'
                              alignItems: 'flex-end'
                            }}
                          >
               
                          
                            <div
                              style={{width: '100%', display: 'flex', border: '0px solid yellow', flexDirection: 'row', }}
                            >
                              {/* '#c6c6f2', */}
                              
                              
                              <div className="bolden-gate" style={{width: '80%', height: 50, border: '0px solid yellow', display: 'flex', alignItems: 'center', paddingLeft: 0, flexDirection: 'row'}}>
                                <p style={{whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", color: 'white', fontSize: 22, marginTop: 'auto'}}>{subscription.name}</p>
                              </div>

                              <div style={{width: '15%', alignItems: 'center', justifyContent: 'center', height: 50, border: '0px  solid white', display: 'flex', flexDirection: 'column',}}>
                                <div style={{width: 15, height: 15, borderRadius: 30, marginTop: 'auto', backgroundColor: subscription.active ? '#2AD640' : 'red'}}/>
                                <p style={{fontSize: 10, color: 'white'}}>{`${subscription.active ? 'ACTIVE' : 'INACTIVE'}`}</p>
                              </div> 
                              <div style={{width: '5%'}} />
                            </div>

                            <div
                              style={{width: '100%',  display: 'flex', border: '0px solid green', flexDirection: 'row'}}
                            >
                              <div style={{width: '100%', paddingLeft: 0, paddingRight: 20, border: '0px solid yellow', display: 'flex', flexDirection: 'row',}}>
                              <p style={{
                                textAlign: 'left',
                                color: '#C6C6F2',
                                fontSize: 16,
                                display: '-webkit-box',WebkitLineClamp: 3,WebkitBoxOrient: 'vertical',overflow: 'hidden'
                              }}>
                                {`${subscription.description}`}
                              </p>
                              </div>
                        
                            </div>

                    
                            <div
                              style={{width: '100%', marginTop: 'auto', marginBottom: 10, display: 'flex', border: '0px solid red', flexDirection: 'column'}}
                            >
                              <div style={{width: '100%', paddingLeft: 0, paddingRight: 20, border: '0px solid yellow', display: 'flex', flexDirection: 'column',}}>
                                <p className="bolden-gate" style={{textAlign: 'left', color: 'white', fontSize: 14}}>{`Purchased: ${moment(subscription.purchaseDate).format('MMMM D yyyy')}`}</p>
                                <p className="bolden-gate" style={{textAlign: 'left', color: 'white', fontSize: 14}}>{`Renews: ${moment(subscription.renewalDate).format('MMMM D yyyy')}`}</p>
                                
                              </div>
                            </div>
                          </div>
                        </div>
                        
                          
                        </div>
                      ))
                    
                  )) ||
                    `You currently aren't subscribed to any products or services.`
                  )
                  ||
                  (
                    <div>
                      {`Loading...`}
                    </div>
                  )}
                  
                </div>
              </div>
            </div>

            {/* divider */}
            <div style={{ width: 25 }} />
            {/* divider */}

            <div style={{ flexDirection: "column", width: "100%" }}>
              <div
                style={{ display: "flex", flexDirection: "row", width: "100%" }}
              >
                <div style={{ border: "0px solid yellow", flex: 1 }}></div>
                <div style={{ border: "0px solid yellow", flex: 1 }}></div>
                <div style={{ border: "0px solid yellow", flex: 1 }}>
                  <h2
                    style={{ color: "white", fontSize: 18, textAlign: "right" }}
                  >
                    {/* {`You have ${services.length} active services`} */}
                  </h2>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  borderRadius: 15,
                  flexDirection: "column",
                  width: "100%",
                  // backgroundColor: "#1d0e54",
                  backgroundColor: "#1c1538",
                  border: "1px solid #7484F9",
                  padding: 15,
                  minHeight: 250,
                  // justifyContent: "center",
                }}
              >
                {/*  */}
                <div style={{ flexDirection: "column", width: "100%" }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                    }}
                  >
                    <div style={{ borderBottom: "1px solid #34227c", flex: 1 }}>
                      <h2
                        style={{
                          // color: "#03fc0b",
                          color: "white",
                          fontSize: 18,
                          padding: 10,
                          paddingTop: 0,

                          // position: 'absolute'
                        }}
                        className="bolden-gate"
                      >
                        Purchase
                      </h2>
                    </div>
                    <div
                      style={{ borderBottom: "1px solid #34227c", flex: 1 }}
                    ></div>
                    <div style={{ borderBottom: "1px solid #34227c", flex: 1 }}>
                      <h2
                        style={{
                          color: "white",
                          fontSize: 18,
                          textAlign: "right",
                        }}
                      >
                        {/* {`You have ${services.length} active services`} */}
                      </h2>
                    </div>
                  </div>

                  {/*  */}
                </div>
                <div
                  style={{
                    textAlign: "center",
                    width: "100%",
                    minHeight: 250,
                    border: "0px solid white",
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection:'column'
                  }}
                >
                  {!productsLoading && (
                    (products.length > 0 && (
                      products.map((product, index) => (
                        <div
                          // className="frosted-glass-container-blur-plus-plus"
                          
                          style={{
                            cursor: 'pointer',
                            userSelect: "none",
                            textAlign: "center",
                            width: "100%",
                            // minHeight: 200,
                            // border: subscription.active ? '1px solid white' :  "1px solid #7484F9",
                            border: "2px solid #7484F9",
                            // alignItems: "center",
                            display: "flex",
                            justifyContent: "center",
                            backgroundColor: '#0d0433',
                            backgroundColor: '#281c56',
                            borderRadius: 25,
                            flexDirection: 'column',
                            marginTop: 5,
                            marginBottom: 'auto',
                            // boxShadow: subscription.active ? "0 0px 10px rgba(255, 255, 255, .4)" : "0 0px 15px rgba(0, 0, 0, .5)" ,
                            // boxShadow: "0 0px 15px rgba(0, 0, 0, .5)" ,
                          }}
                        >

                        <div style={{width: '100%', flexDirection: 'row', display: 'flex'}}>
                        <div style={{width: '25%', border: '0px solid yellow', padding: 10,}}>
                          <img
                            src={product.thumbnailURL}
                            alt="Xena AI Assistant"
                            style={{
                              // maxWidth: 250,
                              maxHeight: "100%",
                              objectFit: "contain",
                              borderRadius: 25,
                              border: '2px solid #3C4684'
                            }}
                          />
                        </div>
                        <div
                            style={{
                              border: "0px solid white",
                              height: '100%',
                              width: "75%",
                              display: 'flex',
                              flexDirection: 'column',
                              marginBottom: 'auto',
                              minHeight: 180,
                              maxHeight: 200,
                              // flexDirection: 'flex-end'
                              alignItems: 'flex-end'
                            }}
                          >
               
                          
                            <div
                              style={{width: '100%', display: 'flex', border: '0px solid yellow', flexDirection: 'row', }}
                            >
                              {/* '#c6c6f2', */}
                              
                              
                              <div className="bolden-gate" style={{width: '80%', height: 50, border: '0px solid yellow', display: 'flex', alignItems: 'center', paddingLeft: 0, flexDirection: 'row'}}>
                                <p style={{whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", color: 'white', fontSize: 22, marginTop: 'auto'}}>{product.name}</p>
                              </div>
{/* 
                              <div style={{width: '15%', alignItems: 'center', justifyContent: 'center', height: 50, border: '0px  solid white', display: 'flex', flexDirection: 'column',}}>
                                <div style={{width: 15, height: 15, borderRadius: 30, marginTop: 'auto', backgroundColor: product.active ? '#2AD640' : 'red'}}/>
                                <p style={{fontSize: 10, color: 'white'}}>{`${product.active ? 'ACTIVE' : 'INACTIVE'}`}</p>
                              </div>  */}
                              <div style={{width: '5%'}} />
                            </div>

                            <div
                              style={{width: '100%',  display: 'flex', border: '0px solid green', flexDirection: 'row'}}
                            >
                              <div style={{width: '100%', paddingLeft: 0, paddingRight: 20, border: '0px solid yellow', display: 'flex', flexDirection: 'row',}}>
                              <p style={{
                                textAlign: 'left',
                                color: '#C6C6F2',
                                fontSize: 16,
                                display: '-webkit-box',WebkitLineClamp: 3,WebkitBoxOrient: 'vertical',overflow: 'hidden'
                              }}>
                                {`${product.description}`}
                              </p>
                              </div>
                        
                            </div>

                    
                            <div
                              style={{width: '100%', marginTop: 'auto', marginBottom: 10, display: 'flex', border: '0px solid red', flexDirection: 'column'}}
                            >
                              <div style={{width: '100%', paddingLeft: 0, paddingRight: 20, border: '0px solid yellow', display: 'flex', flexDirection: 'column',}}>
                                
                                {subscriptions.some(sub => sub._id === product._id) && (
                                  <p 
                                    className="bolden-gate" 
                                    style={{ textAlign: 'left', color: '#2AD640', fontSize: 14 }}
                                  >
                                    {`You are already subscribed to this ${product.type}.`}
                                  </p>
                                )
                                ||
                                (
                                  <p className="bolden-gate" style={{textAlign: 'left', color: 'white', fontSize: 14}}>{`Price: $${product.subscriptionPrice / 100} CAD / month`}</p>
                                )
                                }
                                
                              </div>
                            </div>
                          </div>
                        </div>
                        
                          
                        </div>
                      ))
                    
                  )) ||
                    `You currently aren't subscribed to any products or services.`
                  )
                  ||
                  (
                    <div>
                      {`Loading...`}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )) || (
          <div
            // className="frosted-glass-container-blur-plus-plus"
            style={{
              marginTop: 15,
              // border: "1px solid #3c4684",
              width: "100%",
              padding: 5,
              borderRadius: 5,
              // height: '100vh',
              // border: "3px solid blue",
              display: "flex",
              // alignItems: 'center',
              // justifyContent: 'center'
              flexDirection: "column",
              
            }}
          >
            <div style={{ flexDirection: "column", width: "100%",marginBottom: 10, }}>
              <div
                style={{ display: "flex", flexDirection: "row", width: "100%" }}
              >
                <div style={{ border: "0px solid yellow", flex: 1 }}></div>
                <div style={{ border: "0px solid yellow", flex: 1 }}></div>
                <div style={{ border: "0px solid yellow", flex: 1 }}>
                  <h2
                    style={{ color: "white", fontSize: 18, textAlign: "right" }}
                  >
                    {/* {`You have ${services.length} active services`} */}
                  </h2>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  borderRadius: 15,
                  flexDirection: "column",
                  width: "100%",
                  // backgroundColor: "#1d0e54",
                  backgroundColor: "#1c1538",
                  border: "1px solid #7484F9",
                  padding: 15,
                  minHeight: 250,
                  // justifyContent: "center",
                }}
              >
                {/*  */}
                <div style={{ flexDirection: "column", width: "100%" }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                    }}
                  >
                    <div style={{ borderBottom: "1px solid #34227c", flex: 1 }}>
                      <h2
                        style={{
                          // color: "#7484F9",
                          // color: "#03fc0b",
                          color: 'white',
                          fontSize: 18,
                          padding: 10,
                          paddingTop: 0,
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          // position: 'absolute'
                        }}
                        className="bolden-gate"
                      >
                        Your Products & Services
                      </h2>
                    </div>
                    <div
                      style={{ borderBottom: "1px solid #34227c", flex: 1 }}
                    ></div>
                    <div style={{ borderBottom: "1px solid #34227c", flex: 1 }}>
                      <h2
                        style={{
                          color: "white",
                          fontSize: 18,
                          textAlign: "right",
                        }}
                      >
                        {/* {`You have ${services.length} active services`} */}
                      </h2>
                    </div>
                  </div>

                  {/*  */}
                </div>
                <div
                  style={{
                    textAlign: "center",
                    width: "100%",
                    minHeight: 250,
                    border: "0px solid white",
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  {!subscriptionsLoading && (
                    (subscriptions.length > 0 && (
                      subscriptions.map((subscription, index) => (
                        <div
                          // className="frosted-glass-container-blur-plus-plus"
                          
                          style={{
                            cursor: 'pointer',
                            userSelect: "none",
                            textAlign: "center",
                            width: "100%",
                            // minHeight: 200,
                            // border: subscription.active ? '1px solid white' :  "1px solid #7484F9",
                            border: "2px solid #7484F9",
                            // alignItems: "center",
                            display: "flex",
                            justifyContent: "center",
                            backgroundColor: '#0d0433',
                            backgroundColor: '#281c56',
                            borderRadius: 25,
                            flexDirection: 'column',
                            marginTop: 5,
                            marginBottom: 'auto',
                            // boxShadow: subscription.active ? "0 0px 10px rgba(255, 255, 255, .4)" : "0 0px 15px rgba(0, 0, 0, .5)" ,
                            // boxShadow: "0 0px 15px rgba(0, 0, 0, .5)" ,
                          }}
                        >

                        <div style={{width: '100%', flexDirection: 'row', display: 'flex'}}>
                        <div style={{width: '25%', border: '0px solid yellow', padding: 10,}}>
                          <img
                            src={subscription.thumbnailURL}
                            alt="Xena AI Assistant"
                            style={{
                              // maxWidth: 250,
                              maxHeight: "100%",
                              objectFit: "contain",
                              borderRadius: 25,
                              border: '2px solid #3C4684'
                            }}
                          />
                        </div>
                        <div
                            style={{
                              border: "0px solid white",
                              height: '100%',
                              width: "75%",
                              display: 'flex',
                              flexDirection: 'column',
                              marginBottom: 'auto',
                              minHeight: 180,
                              maxHeight: 200,
                              // flexDirection: 'flex-end'
                              alignItems: 'flex-end'
                            }}
                          >
               
                          
                            <div
                              style={{width: '100%', display: 'flex', border: '0px solid yellow', flexDirection: 'row', }}
                            >
                              {/* '#c6c6f2', */}
                              
                              
                              <div className="bolden-gate" style={{width: '80%', height: 50, border: '0px solid yellow', display: 'flex', alignItems: 'center', paddingLeft: 0, flexDirection: 'row'}}>
                                <p style={{whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", color: 'white', fontSize: 22, marginTop: 'auto'}}>{subscription.name}</p>
                              </div>

                              <div style={{width: '15%', alignItems: 'center', justifyContent: 'center', height: 50, border: '0px  solid white', display: 'flex', flexDirection: 'column',}}>
                                <div style={{width: 15, height: 15, borderRadius: 30, marginTop: 'auto', backgroundColor: subscription.active ? '#2AD640' : 'red'}}/>
                                <p style={{fontSize: 10, color: 'white'}}>{`${subscription.active ? 'ACTIVE' : 'INACTIVE'}`}</p>
                              </div> 
                              <div style={{width: '5%'}} />
                            </div>

                            <div
                              style={{width: '100%',  display: 'flex', border: '0px solid green', flexDirection: 'row'}}
                            >
                              <div style={{width: '100%', paddingLeft: 0, paddingRight: 20, border: '0px solid yellow', display: 'flex', flexDirection: 'row',}}>
                              <p style={{
                                textAlign: 'left',
                                color: '#C6C6F2',
                                fontSize: 16,
                                display: '-webkit-box',WebkitLineClamp: 3,WebkitBoxOrient: 'vertical',overflow: 'hidden'
                              }}>
                                {`${subscription.description}`}
                              </p>
                              </div>
                        
                            </div>

                    
                            <div
                              style={{width: '100%', marginTop: 'auto', marginBottom: 10, display: 'flex', border: '0px solid red', flexDirection: 'column'}}
                            >
                              <div style={{width: '100%', paddingLeft: 0, paddingRight: 20, border: '0px solid yellow', display: 'flex', flexDirection: 'column',}}>
                                <p className="bolden-gate" style={{textAlign: 'left', color: 'white', fontSize: 14}}>{`Purchased: ${moment(subscription.purchaseDate).format('MMMM D yyyy')}`}</p>
                                <p className="bolden-gate" style={{textAlign: 'left', color: 'white', fontSize: 14}}>{`Renews: ${moment(subscription.renewalDate).format('MMMM D yyyy')}`}</p>
                                
                              </div>
                            </div>
                          </div>
                        </div>
                        
                          
                        </div>
                      ))
                    
                  )) ||
                    `You currently aren't subscribed to any products or services.`
                  )
                  ||
                  (
                    <div>
                      {`Loading...`}
                    </div>
                  )}
                  
                </div>
              </div>
            </div>

            {/* divider */}
            <div style={{ width: 25 }} />
            {/* divider */}

            <div style={{ flexDirection: "column", width: "100%" }}>
              <div
                style={{ display: "flex", flexDirection: "row", width: "100%" }}
              >
                <div style={{ border: "0px solid yellow", flex: 1 }}></div>
                <div style={{ border: "0px solid yellow", flex: 1 }}></div>
                <div style={{ border: "0px solid yellow", flex: 1 }}>
                  <h2
                    style={{ color: "white", fontSize: 18, textAlign: "right" }}
                  >
                    {/* {`You have ${services.length} active services`} */}
                  </h2>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  borderRadius: 15,
                  flexDirection: "column",
                  width: "100%",
                  // backgroundColor: "#1d0e54",
                  backgroundColor: "#1c1538",
                  border: "1px solid #7484F9",
                  padding: 15,
                  minHeight: 250,
                  // justifyContent: "center",
                }}
              >
                {/*  */}
                <div style={{ flexDirection: "column", width: "100%" }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                    }}
                  >
                    <div style={{ borderBottom: "1px solid #34227c", flex: 1 }}>
                      <h2
                        style={{
                          // color: "#03fc0b",
                          color: "white",
                          fontSize: 18,
                          padding: 10,
                          paddingTop: 0,

                          // position: 'absolute'
                        }}
                        className="bolden-gate"
                      >
                        Purchase
                      </h2>
                    </div>
                    <div
                      style={{ borderBottom: "1px solid #34227c", flex: 1 }}
                    ></div>
                    <div style={{ borderBottom: "1px solid #34227c", flex: 1 }}>
                      <h2
                        style={{
                          color: "white",
                          fontSize: 18,
                          textAlign: "right",
                        }}
                      >
                        {/* {`You have ${services.length} active services`} */}
                      </h2>
                    </div>
                  </div>

                  {/*  */}
                </div>
                <div
                  style={{
                    textAlign: "center",
                    width: "100%",
                    minHeight: 250,
                    border: "0px solid white",
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection:'column'
                  }}
                >
                  {!productsLoading && (
                    (products.length > 0 && (
                      products.map((product, index) => (
                        <div
                          // className="frosted-glass-container-blur-plus-plus"
                          
                          style={{
                            cursor: 'pointer',
                            userSelect: "none",
                            textAlign: "center",
                            width: "100%",
                            // minHeight: 200,
                            // border: subscription.active ? '1px solid white' :  "1px solid #7484F9",
                            border: "2px solid #7484F9",
                            // alignItems: "center",
                            display: "flex",
                            justifyContent: "center",
                            backgroundColor: '#0d0433',
                            backgroundColor: '#281c56',
                            borderRadius: 25,
                            flexDirection: 'column',
                            marginTop: 5,
                            marginBottom: 'auto',
                            // boxShadow: subscription.active ? "0 0px 10px rgba(255, 255, 255, .4)" : "0 0px 15px rgba(0, 0, 0, .5)" ,
                            // boxShadow: "0 0px 15px rgba(0, 0, 0, .5)" ,
                          }}
                        >

                        <div style={{width: '100%', flexDirection: 'row', display: 'flex'}}>
                        <div style={{width: '25%', border: '0px solid yellow', padding: 10,}}>
                          <img
                            src={product.thumbnailURL}
                            alt="Xena AI Assistant"
                            style={{
                              // maxWidth: 250,
                              maxHeight: "100%",
                              objectFit: "contain",
                              borderRadius: 25,
                              border: '2px solid #3C4684'
                            }}
                          />
                        </div>
                        <div
                            style={{
                              border: "0px solid white",
                              height: '100%',
                              width: "75%",
                              display: 'flex',
                              flexDirection: 'column',
                              marginBottom: 'auto',
                              minHeight: 180,
                              maxHeight: 200,
                              // flexDirection: 'flex-end'
                              alignItems: 'flex-end'
                            }}
                          >
               
                          
                            <div
                              style={{width: '100%', display: 'flex', border: '0px solid yellow', flexDirection: 'row', }}
                            >
                              {/* '#c6c6f2', */}
                              
                              
                              <div className="bolden-gate" style={{width: '80%', height: 50, border: '0px solid yellow', display: 'flex', alignItems: 'center', paddingLeft: 0, flexDirection: 'row'}}>
                                <p style={{whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", color: 'white', fontSize: 22, marginTop: 'auto'}}>{product.name}</p>
                              </div>
{/* 
                              <div style={{width: '15%', alignItems: 'center', justifyContent: 'center', height: 50, border: '0px  solid white', display: 'flex', flexDirection: 'column',}}>
                                <div style={{width: 15, height: 15, borderRadius: 30, marginTop: 'auto', backgroundColor: product.active ? '#2AD640' : 'red'}}/>
                                <p style={{fontSize: 10, color: 'white'}}>{`${product.active ? 'ACTIVE' : 'INACTIVE'}`}</p>
                              </div>  */}
                              <div style={{width: '5%'}} />
                            </div>

                            <div
                              style={{width: '100%',  display: 'flex', border: '0px solid green', flexDirection: 'row'}}
                            >
                              <div style={{width: '100%', paddingLeft: 0, paddingRight: 20, border: '0px solid yellow', display: 'flex', flexDirection: 'row',}}>
                              <p style={{
                                textAlign: 'left',
                                color: '#C6C6F2',
                                fontSize: 16,
                                display: '-webkit-box',WebkitLineClamp: 3,WebkitBoxOrient: 'vertical',overflow: 'hidden'
                              }}>
                                {`${product.description}`}
                              </p>
                              </div>
                        
                            </div>

                    
                            <div
                              style={{width: '100%', marginTop: 'auto', marginBottom: 10, display: 'flex', border: '0px solid red', flexDirection: 'column'}}
                            >
                              <div style={{width: '100%', paddingLeft: 0, paddingRight: 20, border: '0px solid yellow', display: 'flex', flexDirection: 'column',}}>
                                
                                {subscriptions.some(sub => sub._id === product._id) && (
                                  <p 
                                    className="bolden-gate" 
                                    style={{ textAlign: 'left', color: '#2AD640', fontSize: 14 }}
                                  >
                                    {`You are already subscribed to this ${product.type}.`}
                                  </p>
                                )
                                ||
                                (
                                  <p className="bolden-gate" style={{textAlign: 'left', color: 'white', fontSize: 14}}>{`Price: $${product.subscriptionPrice / 100} CAD / month`}</p>
                                )
                                }
                                
                              </div>
                            </div>
                          </div>
                        </div>
                        
                          
                        </div>
                      ))
                    
                  )) ||
                    `You currently aren't subscribed to any products or services.`
                  )
                  ||
                  (
                    <div>
                      {`Loading...`}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
let styles = {
  coloredBackground: {
    // backgroundColor: "#090714",
    // width: "100%",
    // height: "100vh",
    // minHeight: "100vh",
    // position: "absolute",
    // zIndex: -1,
  },
  container: {
    // display: "flex",
    // alignItems: "center",
    backgroundColor: "#090714",
    justifyContent: "center",
    width: "100%",
    // height: "100vh",
    // minHeight: "100vh",
    color: "white",
  },
};
export default ClientDashboard;
