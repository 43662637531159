// JJ-108
import React, { useState, useEffect } from "react";
import "../App.css";
import { useLocation, useNavigate } from "react-router-dom";
// import Hixenis from "../images/HIXENIS_NEW_W_P.png";
import Hixenis from "../images/HIXENIS_NEW_W_P_SIMPLE.png";
import Earth from "../images/earf.jpg";
import Essential from "../images/essential.jpg";
import Essential2 from "../images/essential2.jpg";
import Enterprise from "../images/enterprise.jpg";
import Xena from "../images/xena.jpg";
import Xena2 from "../images/xena2.jpg";
import JJShowCase from "../images/jj_showcase.png";
import NuriShowCase from "../images/nuri_showcase.png";
import NuriShowCaseApp from "../images/nuri_showcase_app.png";
import Code from "../images/code.jpg";
import zIndex from "@mui/material/styles/zIndex";
import NavBar from "./NavBar";
import HixenisNavBar from "./HixenisNavBar";
import DashboardNavBar from "../components/DashboardNavbar";
import SubscriptionListItem from "../components/SubscriptionListItem";
import SubscriptionListItemMobile from "../components/SubscriptionListItemMobile";
import SubscriptionSpotlight from "../components/SubscriptionSpotlight";
import ProductListItemMobile from "../components/ProductListItemMobile";
import ProductListItem from "../components/ProductListItem";
import ProductSpotlight from "../components/ProductSpotlight";
import moment from "moment";
import {
  getSubscriptions,
  getProducts,
  getEmailVerificationStatus,
  resendVerificationEmail,
  cancelSubscription,
  submitContactForm
} from "../api/dataService";
import { useUser } from "../stores/UserContext";
import BounceLoader from "react-spinners/BounceLoader";
import { LineWeight, Opacity, StyleSharp } from "@mui/icons-material";
import { FaRegCheckCircle } from "react-icons/fa";
import { TbRulerMeasure } from "react-icons/tb";
import { GiResize } from "react-icons/gi";
import { LiaConnectdevelop } from "react-icons/lia";
import { BsRocketTakeoff } from "react-icons/bs";
import HixenisFooter from "./HixenisFooter";
import GlassContainer from "./GlassContainer";
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import { IoIosSend } from "react-icons/io";

import NuriWebHomeDesktop from "../images/nuri_web_home_desktop.png";
import NuriWebWatchDesktop from "../images/nuri_web_watch_desktop.png";
import NuriWebHomeMobile from "../images/nuri_web_home_mobile.png";
import NuriWebWatchMobile from "../images/nuri_web_watch_desktop.png";
import JordanJancicWebHomeDesktop from "../images/jordanjancic_web_home_desktop.png";
import JordanJancicWebHomeMobile from "../images/jordanjancic_web_home_mobile.png";

import JJShowCaseSquare from "../images/jj_showcase_square.png";
import NuriShowCaseAppSquare from "../images/nuri_showcase_mobile_app_square.png";
import NuriShowCaseSquare from "../images/nuri_web_showcase_square.png";
import { MdOutlinePause } from "react-icons/md";
import { IoMdPlay } from "react-icons/io";

import { MdDirectionsBike } from "react-icons/md";
import { Helmet } from 'react-helmet';
import Logo192 from "../hixenis/logo192.png";
import Logo512 from "../hixenis/logo512.png";
import Favicon from "../hixenis/favicon.ico";


function HixenisHome() {
  const navigate = useNavigate();
  const { user, updateUser } = useUser();

  const location = useLocation();
  const props = location.state || {};
  const [theme, setTheme] = useState("dark");
  const [messageOverlayOpen, setMessageOverlayOpen] = useState(false);
  const [messageOverlayTitleText, setMessageOverlayTitleText] = useState("");
  const [messageOverlayTitleTextColor, setMessageOverlayTitleTextColor] =
    useState("#bbbbed");
  const [messageOverlayText, setMessageOverlayText] = useState("");

  const [subscriptions, setSubscriptions] = useState([]);
  const [subscriptionsLoading, setSubscriptionsLoading] = useState(true);

  const [products, setProducts] = useState([]);
  const [productsLoading, setProductsLoading] = useState(true);

  const [isMobile, setIsMobile] = useState(window.innerWidth < 768); // Adjust the threshold as needed

  const [emailVerificationRequired, setEmailVerificationRequired] =
    useState(false);
  const [emailVerificationSending, setEmailVerificationSending] =
    useState(false);
  const [emailVerificationSent, setEmailVerificationSent] = useState(false);

  const [selectedSubscription, setSelectedSubscription] = useState();
  const [selectedProduct, setSelectedProduct] = useState();

  const [contactFormLoading, setContactFormLoading] = useState(false);
  const [contactFormIndicatorColor, setContactFormIndicatorColor] =
    useState("#6e6ef4");
  const [contactFormSubmitted, setContactFormSubmitted] = useState(false);
  const [contactFormHasErrors, setContactFormHasErrors] = useState(false);
  const [showPause, setShowPause] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const [loading, setLoadingState] = useState(false);
  const [loadingStateMessage, setLoadingStateMessage] = useState("");

  const handleResize = () => {
    const isCurrentlyMobile = window.innerWidth < 768;
    
    setIsMobile(isCurrentlyMobile);
    
    
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (user) {
      navigate("ClientDashboard");
    }
  }, []);

  const handleSubmit = (e) => {
    setContactFormLoading(true);
    e.preventDefault();

    submitContactForm(formData)
      .then((response) => {
        setTimeout(() => {
          if (response.status === "OK") {
            setContactFormLoading(false);
            setContactFormSubmitted(true);
            setFormData({ name: "", email: "", message: "" });
          } else {
            setContactFormHasErrors(true);
            setContactFormLoading(false);
          }
        }, 1000);
      })
      .catch((error) => {
        setContactFormHasErrors(true);
        setContactFormLoading(false);
        console.log(`error, ${error}`);
      });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleGetEmailVerificationStatus = () => {
    getEmailVerificationStatus({ userId: user.id })
      .then((response) => {
        if (response.status === "OK") {
          var userEmailVerified = response.data;
          if (userEmailVerified === true) {
            setEmailVerificationRequired(false);
          } else {
            setEmailVerificationRequired(true);
          }
        } else {
          console.log("Error getting email verification status", response);
        }
      })
      .catch((error) => {
        console.log(`Error getting email verification status: ${error}`);
      });
  };

  const handleGetSubscriptions = () => {
    if (user) {
      getSubscriptions({ userId: user.id, email: user.email })
        .then((response) => {
          if (response.status === "OK") {
            setSubscriptions(response.data);
            setSelectedSubscription(response.data[0]);
            setSubscriptionsLoading(false);
          } else {
            console.log(response);
            setSubscriptions([]);
            setSubscriptionsLoading(false);
          }
        })
        .catch((error) => {
          console.log(`error, ${error}`);
          setSubscriptions([]);
          setSubscriptionsLoading(false);
        });
    }
  };

  const handleGetProducts = () => {
    getProducts()
      .then((response) => {
        if (response.status === "OK") {
          console.log(response);
          setProducts(response.data);
          setSelectedProduct(response.data[0]);
          setProductsLoading(false);
        } else {
          console.log(response);
          setProducts([]);
          setProductsLoading(false);
        }
      })
      .catch((error) => {
        console.log(`error, ${error}`);
        setProducts([]);
        setProductsLoading(false);
      });
  };

  const handleResendVerificationEmail = () => {
    setEmailVerificationRequired(false);
    setEmailVerificationSending(true);
    resendVerificationEmail()
      .then((response) => {
        if (response.status === "OK") {
          setEmailVerificationRequired(false);
          setEmailVerificationSending(false);
          setEmailVerificationSent(true);
          console.log(response);
          setProducts(response.data);
          setProductsLoading(false);
        } else {
          setEmailVerificationRequired(false);
          setEmailVerificationSending(false);
          setEmailVerificationSent(true);
          console.log(response);
          setProducts([]);
          setProductsLoading(false);
        }
      })
      .catch((error) => {
        setEmailVerificationRequired(false);
        setEmailVerificationSending(false);
        setEmailVerificationSent(true);
        console.log(`error, ${error}`);
      });
  };

  const handleLogout = (navigate) => {
    updateUser(null);
    navigate("/");
  };

  const handleMoveToScreen = (screen, navigate) => {
    if (screen !== "SupportScreen") {
      navigate(`/${screen}`, { state: null });
    } else {
      alert("This screen is not ready yet!");
    }
  };

  const handleCheckoutProduct = (stripePriceId, stripeProductId) => {
    navigate(`/checkout`, {
      state: {
        stripePriceId: stripePriceId,
        stripeProductId: stripeProductId,
        userId: user.id,
      },
      replace: true, // Ensures this navigation replaces the current history entry
    });
  };

  const handleReactivateSubscription = (subscription) => {
    console.log("Reactivate this one", subscription);
  };

  const handleCancelSubscription = (subscription) => {
    setLoadingState(true);
    setLoadingStateMessage("Cancelling subscription...");
    cancelSubscription({ userId: user.id, subscriptionId: subscription.id })
      .then((response) => {
        if (response.status === "OK") {
          console.log(response.data);
          handleGetSubscriptions();
          setMessageOverlayOpen(true);
          setMessageOverlayTitleText("Subscription Cancelled");
          setMessageOverlayText(
            `You have successfully cancelled your subscription.`
          );
          setLoadingState(false);
        } else {
          setMessageOverlayOpen(true);
          setMessageOverlayTitleText("ERROR");
          setMessageOverlayText(`${response.detail}`);
          setLoadingState(false);
        }
      })
      .catch((error) => {
        setMessageOverlayOpen(true);
        setMessageOverlayTitleText("ERROR");
        setMessageOverlayText(
          `Something went wrong. Please try again later. ${error}`
        );
        setLoadingState(false);
      });
  };

  const handleMoveToComponent = (value) => {
    const sectionIds = ["home", "projects", "about", "contact"];
    const targetSection = document.getElementById(sectionIds[value]);

    if (targetSection) {
      targetSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleGoToLoginScreen = (navigate) => {
    navigate("/LoginScreen", { state: { isMobile: isMobile } });
  };

  const handleGoToRegisterScreen = (navigate) => {
    navigate("/RegisterScreen", { state: { isMobile: isMobile } });
  };

  const spanStyle = {
    padding: '20px',
    background: '#efefef',
    color: '#000000'
  }
  
  const divStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundSize: 'cover',
    height: '400px'
  }

  const slideImages = [
    {
      url: NuriShowCaseApp,
      caption: 'NuriWebWatchDesktop',
      type: 'desktop',
      project: 'nuri_app',
      webUrl: 'https://apps.apple.com/ca/app/n%C3%BCri/id1599369090',
    },
    {
      url: JJShowCase,
      caption: 'NuriWebHomeDesktop',
      type: 'desktop',
      project: 'jj_web',
      webUrl: 'https://jordanjancic.com/',
    },
    {
      url: NuriShowCase,
      caption: 'NuriWebWatchDesktop',
      type: 'desktop',
      project: 'nuri_web',
      webUrl: 'https://nurieats.com/'
    },
  ];

  const slideImagesMobile = [
    {
      url: NuriShowCaseAppSquare, 
      caption: 'NuriWebWatchDesktop',
      type: 'desktop',
      project: 'nuri_app',
      webUrl: 'https://apps.apple.com/ca/app/n%C3%BCri/id1599369090',
    },
    {
      url: JJShowCaseSquare,
      caption: 'NuriWebHomeDesktop',
      type: 'desktop',
      project: 'jj_web',
      webUrl: 'https://jordanjancic.com/',
      
    },
    {
      url: NuriShowCaseSquare,
      caption: 'NuriWebWatchDesktop',
      type: 'desktop',
      project: 'nuri_web',
      webUrl: 'https://nurieats.com/'
    },
  ];

  let styles = {
    earth: {
      // backgroundImage: "url(" + Earth + ")",
      // maxHeight: 300,
      position: 'absolute',
      // maxWidth: 1200,
      width: "100%",
      objectFit: "contain",
      opacity: .8,
      zIndex: 0,
      WebkitMaskImage: 'linear-gradient(to bottom, rgba(0,0,0,1), rgba(0,0,0,0))',
      // background: "linear-gradient(0deg, rgba(255, 0, 0, 0) 0%, rgba(255, 0, 0, 0.6) 70%, rgba(255, 23, 53, 1) 100%)",
    },
    container: {
      backgroundColor: "#090714",
      justifyContent: "center",
      width: "100%",
      color: "white",
      
    },
    loadingOverlayOuterContainer: {
      position: "absolute",
      zIndex: 12000,
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      width: "100%",
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      transition: "opacity 1s ease-in-out",
    },
    loadingOverlayInnerContainer: {
      padding: 30,
      backgroundColor: "rgba(44, 41, 63, 1)",
      borderRadius: 10,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      color: "white",
      boxShadow: "0 0px 10px rgba(120, 0, 255, 1)",
      border: "1px solid #6E6EF4",
    },
    messageOverlayContainer1: {
      position: "absolute",
      zIndex: 12000,
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      width: "100%",
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      transition: "opacity 1s ease-in-out",
    },
    messageOverlayContainer2: {
      width: 350,
      backgroundColor: "#191632",
      borderRadius: 10,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      color: "white",
      boxShadow: "0 0px 15px rgba(0, 0, 0, .8)",
      border: "1px solid #6E6EF4",
      overflow: "hidden",
    },
    messageOverlayTitleContainer: {
      borderBottom: "0px solid #6E6EF4",
      width: "90%",
      flex: 1,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      paddingTop: 5,
    },
    messageOverlayTitleText: {
      letterSpacing: 2,
      marginTop: 5,
      fontSize: 18,
      color: messageOverlayTitleTextColor,
    },
    messageOverlayBodyTextContainer: {
      border: "0px solid red",
      width: "100%",
      flex: 1,
      display: "flex",
      justifyContent: "center",
      padding: 16,
      paddingTop: 0,
    },
    messageOverlayButtonTextContainer: {
      cursor: "pointer",
      borderTop: "1px solid #6E6EF4",
      width: "100%",
      flex: 1,
      display: "flex",
      justifyContent: "center",
      padding: 16,
    },
    messageOverlayButtonText: {
      fontSize: 22,
      marginTop: 5,
      textAlign: "center",
    },
    topBarContainer: {
      width: "100%",
      height: 175,
      display: "flex",
      flexDirection: "row",
      
    },
    leftPanelContainer: {
      width: "30%",
      height: "100%",
      border: "0px solid blue",
      padding: 30,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
    },
    hixenisLogo: {
      maxWidth: 250,
      maxHeight: "100%",
      objectFit: "contain",
      zIndex: 1,
    },
    middlePanelContainer: {
      display: "flex",
      width: "40%",
      height: "100%",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      
    },
    screenTitle: {
      color: "#7484F9",
      fontSize: 22,
      paddingBottom: 10,
      
    },
    logoutButtonContainer: {
      width: "30%",
      border: "0px solid yellow",
      height: "100%",
      padding: 30,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    logoutButtonInnerContainer: {
      zIndex: 12000,
      cursor: "pointer",
      display: "flex",
      borderRadius: 5,
      width: 120,
      height: 40,
      backgroundColor: "#6e6ef4",
      alignItems: "center",
      justifyContent: "center",
    },
    topBarContainerMobile: {
      width: "100%",
      height: 175,
      
    },
    topBarImageContainer: {
      width: "100%",
      height: "100%",
      padding: 30,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      
    },
    hixenisLogoMobile: {
      maxWidth: 400,
      maxHeight: "100%",
      objectFit: "contain",
      zIndex: 1,
    },
    screenTitleMobile: {
      display: "flex",
      border: "0px solid green",
      height: "100%",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
    },
    screenTitleTextMobile: {
      color: "#7484F9",
      fontSize: 22,
      paddingBottom: 10,
    },
    navbarContainer: {
      width: "100%",
    },
    emailVerificationAlertContainer: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      border: "0px solid yellow",
    },
    emailVerificationAlertContainer2: {
      width: "75%",
      display: "flex",
      backgroundColor: "#965609",
      alignItems: "center",
      borderRadius: 5,
      padding: 10,
      justifyContent: "center",
      border: "1px solid #ff8c00",
    },
    emailVerificationAlertSendingContainer: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      border: "0px solid yellow",
    },
    emailVerificationAlertSendingInnerContainer: {
      width: "75%",
      display: "flex",
      backgroundColor: "#965609",
      alignItems: "center",
      borderRadius: 5,
      padding: 10,
      justifyContent: "center",
      border: "1px solid #ff8c00",
    },
    emailVerificationAlertSentContainer: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      border: "0px solid yellow",
    },
    emailVerificationAlertSentInnerContainer: {
      width: "75%",
      display: "flex",
      backgroundColor: "#965609",
      alignItems: "center",
      borderRadius: 5,
      padding: 10,
      justifyContent: "center",
      border: "1px solid #ff8c00",
    },
    hixenisHomeMainContainer: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginTop: !isMobile ? 0 : 20,
    },
    frostedGlassContainer: {
      marginTop: 0,
      // border: "1px solid #3c4684",
      width: "80%",
      padding: 30,
      borderRadius: 5,
      display: "flex",
      flexDirection: "column",
    },
    subscriptionsMainContainer: {
      flexDirection: "column",
      width: "100%",
      marginBottom: 25,
    },
    subscriptionsInnerContainer: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
    },
    subscriptionsLeftPanel: {
      display: "flex",
      borderRadius: 5,
      flexDirection: "column",
      width: "40%",
      backgroundColor: "#1c1538",
      border: "1px solid #7484F9",
      padding: 15,
    },
    subscriptionsTitleContainer: {
      flexDirection: "column",
      width: "100%",
    },
    subscriptionsTitleContainer2: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
    },
    subscriptionsTitleContainer3: {
      borderBottom: "1px solid #34227c",
      flex: 1,
    },
    subscriptionsTitle: {
      color: "white",
      fontSize: 18,
      padding: 10,
      paddingTop: 0,
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    subscriptionsDivider: { borderBottom: "1px solid #34227c", flex: 1 },
    subscriptionsListContainer: {
      textAlign: "center",
      width: "100%",
      minHeight: 250,
      alignItems: "center",
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
    },

    storeMain: {
      display: "flex",
      borderRadius: 5,
      flexDirection: "column",
      width: "100%",
      backgroundColor: "#1c1538",
      border: "1px solid #7484F9",
      padding: 15,
      paddingLeft: 0,
      paddingRight: 0,
      minHeight: 250,
    },
    storeTitleMain: { flexDirection: "column", width: "100%" },
    storeTitleInner: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      marginBottom: 5,
    },
    storeTitleDivider: {
      borderBottom: "1px solid #34227c",
      flex: 1,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    storeHeading: {
      color: "#94a1f7",
      fontSize: 18,
      padding: 10,
      paddingTop: 0,
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      letterSpacing: 2,
    },
    storeSecondaryContainerMain: {
      textAlign: "center",
      width: "100%",
      alignItems: "flex-start",
      display: "flex",
      justifyContent: "flex-start",
      flexDirection: "row",
      padding: 15,
      paddingTop: 0,
      paddingBottom: 15,
    },
    storeLeftPanel: {
      display: "flex",
      borderRadius: 5,
      flexDirection: "column",
      width: "40%",
      backgroundColor: "#1c1538",
      border: "1px solid #7484F9",
      padding: 15,
    },
    storeLeftPanelInner: { flexDirection: "column", width: "100%" },
    storeLeftPanelTitle: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
    },
    storeLeftPanelTitleContainer: {
      borderBottom: "1px solid #34227c",
      flex: 1,
    },
    storeLeftPanelHeader: {
      color: "white",
      fontSize: 18,
      padding: 10,
      paddingTop: 0,
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "flex",
    },
    productsListContainerMain: {
      textAlign: "center",
      width: "100%",
      minHeight: 250,
      alignItems: "center",
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
    },

    mobileContainerMain: {
      marginTop: 15,
      width: "100%",
      borderRadius: 5,
      display: "flex",
      flexDirection: "column",
      borderRight: "none",
    },
    mobileContainerInner: {
      flexDirection: "column",
      width: "100%",
      marginBottom: 25,
    },
    mobileSubsInnerContainer: {
      display: "flex",
      borderRadius: 5,
      flexDirection: "column",
      width: "100%",
      backgroundColor: "#1c1538",
      border: "1px solid #34227C",
      padding: 15,
      paddingLeft: 3,
      paddingRight: 3,
      minHeight: 250,
    },
    mobileSubsTitleContainer: { flexDirection: "column", width: "100%" },
    mobileSubsTitleInner: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      // border: '1px solid yellow'
    },
    mobileSubsDivider: { borderBottom: "1px solid #34227c", flex: 1 },
    mobileSubsTitleText: {
      color: "white",
      fontSize: 18,
      padding: 10,
      paddingTop: 0,
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      paddingLeft: 20,
    },
    mobileSubsList: {
      textAlign: "center",
      width: "100%",
      minHeight: 250,
      border: "0px solid white",
      alignItems: "center",
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
    },
    mobileStoreMain: { flexDirection: "column", width: "100%" },
    mobileStoreInner: {
      display: "flex",
      borderRadius: 5,
      flexDirection: "column",
      width: "100%",
      backgroundColor: "#1c1538",
      border: "1px solid #34227C",
      padding: 15,
      paddingLeft: 3,
      paddingRight: 3,
      minHeight: 250,
      borderBottom: "1px solid #3c4ab7",
    },
    mobileStoreTitleContainer: { flexDirection: "column", width: "100%" },
    mobileStoreTitleInner: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
    },
    mobileStoreTitleDivider: {
      borderBottom: "1px solid #34227c",
      flex: 1,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    mobileStoreTitleText: {
      color: "#94a1f7",
      fontSize: 18,
      padding: 10,
      paddingTop: 0,
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      letterSpacing: 2,
    },
    mobileStoreProducts: {
      textAlign: "center",
      width: "100%",
      minHeight: 250,
      border: "0px solid white",
      alignItems: "center",
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
    },
    registerAndLoginBar: {
      border: "0px solid yellow",
      width: "100%",
      padding: 50,
      position: "absolute",
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
    },
    registerButton: {
      zIndex: 12000,
      cursor: "pointer",
      display: "flex",
      borderRadius: 5,
      alignSelf: "center",
      width: 120,
      height: 40,
      alignItems: "center",
      justifyContent: "center",
    },
    registerText: {
      color: "white",
    },
    loginButton: {
      zIndex: 12000,
      cursor: "pointer",
      display: "flex",
      borderRadius: 5,
      alignSelf: "center",
      width: 120,
      height: 40,
      backgroundColor: "#6e6ef4",
      alignItems: "center",
      justifyContent: "center",
    },
    loginText: {
      color: "white",
    },
    ourServicesOuterContainer: {
      // border: '1px solid red',
      flex: 1,
      display: 'flex',
    },
    ourServicesOuterContainerMobile: {
      // border: '1px solid red',
      flex: 1,
      display: 'flex',
      flexDirection: "column"
    },
    ourServicesIconContainer: {
      flex: 1,
      // border: '1px solid red',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: "column",
      // textAlign: 'center'
      height: 100,
      marginTop: 15,
    },
    ourServicesIconContainerMobile: {
      flex: 1,
      // border: '1px solid red',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: "column",
      // textAlign: 'center'
      // height: 100,
      marginTop: 15,
    },
    ourServicesSubtitleContainer: {
      flex: 1,
      // border: '1px solid red',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: "column",
      // textAlign: 'center'
      // height: 100
    },
    ourServicesBlockInner: {
      margin: 20,
      marginTop: 5,
    },
    ourServicesTextContainer: {
      // border: '1px solid lightGreen',
      padding: 20,
      paddingTop: 5,
      flex: 1,
    },
    ourServicesTextContainerMobile: {
      // border: '1px solid lightGreen',
      padding: 30,
      paddingTop: 0,
      paddingBottom: 15,
    },
    welcomeMessageContainer: {
      flex: 1, 
      // border: '1px solid red', 
      marginLeft: '10%', 
      marginRight: '10%', 
      display: 'flex', 
      alignItems: 'center', 
      justifyContent: 'center', 
      fontSize: 46, 
      textAlign: 'center',
      lineHeight: !isMobile ? 1.3 : 1.1,
      flexDirection: 'column',
      padding: !isMobile ? 30 : 5,
      zIndex: 1,
      paddingBottom: 0,
    },
    contactContainerMain: {
      width: "100%",
      display: "flex",
      // alignItems: "center",
      justifyContent: "center",
      flexDirection: "row",
      marginTop: 0,
      padding: 20,
      paddingTop: 0,
      // border: '1px solid red'
    },
    glassContainerContact: {
      padding: 20,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      paddingBottom: 50,
    },
    contactTitle: {
      fontSize: 22,
      padding: 30,
      paddingTop: 50,
      color: "white",
    },
    contactTitleMobile: {
      fontSize: 22,
      padding: 0,
      paddingTop: 20,
      color: "white",
    },
    contactForm: {
      width: "100%",
      border: "0px solid white",
      display: "flex",
      justifyContent: "center",
    },
    contactLoadingOverlayMain: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    contactLoadingText: {
      fontSize: 25,
      color: "white",
    },
    contactFormSubmittedContainerMain: {
      width: "100%",
      flexDirection: "column",
      border: "0px solid white",
      display: "flex",
      justifyContent: "center",
      height: 350,
      alignItems: "center",
    },
    contactErrorText: {
      color: "red",
      fontSize: 20,
    },
    contactHeaderContainerMain: {
      width: "50%",
      border: "0px solid red",
      display: "flex",
      flexDirection: "column",
      height: "100%",
      marginBottom: "auto",
    },
    contactTitle: {
      fontSize: 25,
      padding: !isMobile ? 30 : 0,
      paddingTop: !isMobile ? 50 : 20,
      color: "#6e6ef4",
      letterSpacing: 2,
    },
    contactSubtitle: {
      fontSize: 22,
      paddingLeft: !isMobile ? 35 : 0,
      color: "white",
      textAlign: "left",
    },
    contactJordan: {
      marginTop: 5,
      fontSize: 22,
      paddingLeft: !isMobile ? 35 : 0,
      color: "white",
      textAlign: "left",
    },
    contactTitleMobileSecondary: {
      fontSize: 25,
      padding: 0,
      paddingTop: 0,
      color: "white",
      // letterSpacing: 10,
      textAlign: 'center'
    },
    contactFormContainer: {
      // width: "100%",
      flex: 2,
      // border: "1px solid white",
      display: "flex",
      justifyContent: "center",
      height: contactFormLoading === true ? 350 : "auto",
      // paddingRight: 30,
      // alignSelf: 'flex-end'
    },
    contactFormLoadingMobile: {
      display: "flex",
      flexDirection: "column",
      border: "0px solid white",
      alignItems: "center",
      justifyContent: "center",
    },
    contactFormLoadingTextMobile: {
      fontSize: 25,
      padding: !isMobile ? 30 : 0,
      color: "white",
    },
    contactFormSubmittedMobile: {
      width: "100%",
      flexDirection: "column",
      border: "0px solid white",
      display: "flex",
      justifyContent: "center",
      height: 350,
      alignItems: "center",
    },
    contactFormCircleCheckMobile: {
      fontSize: 25,
      // padding: !isMobile ? 15 : 0,
      // paddingTop: 0,
      // paddingBottom: 0,
      color: "#03fc0b",
      paddingLeft: 30,
      paddingRight: 30,
      textAlign: 'center'
    },
    contactFormInnerContainerMobile: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      maxWidth: 400,
    },
    contactFormInputNameMobile: {
      padding: 10,
      margin: 10,
      borderRadius: 5,
      outline: "none",
      fontSize: 16,
      color: "black",
    },
    contactFormInputEmailMobile: {
      padding: 10,
      margin: 10,
      borderRadius: 5,
      outline: "none",
      fontSize: 16,
      color: "black",
    },
    contactFormInputMessageMobile: {
      padding: 10,
      margin: 10,
      borderRadius: 5,
      outline: "none",
      fontSize: 16,
      color: "black",
    },
    contactFormSubmitButtonMobile: {
      padding: 10,
      margin: 10,
      backgroundColor: theme === "light" ? "#5a59c9" : "#6e6ef4",
      color: "white",
      border: "none",
      borderRadius: 5,
      cursor: "pointer",
      fontSize: 16,
      transition: "background-color 0.3s",
    },
    contactFormErrorsMobile: { color: "red", fontSize: 20 },
  };

  const buttonStyle = {
    width: "30px",
    background: 'none',
    border: '0px',
    marginLeft: -20,
    marginRight: -20,
  };

  const properties = {
    duration: 2500,
    autoplay: true,
    transitionDuration: 250,
    // arrows: !isMobile ? true : false,
    arrows: false,
    infinite: true,
    easing: "ease",
    pauseOnHover: false,
    canSwipe: false,
    // prevArrow: <button style={{ ...buttonStyle }}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill='#6E6EF4'><path d="M242 180.6v-138L0 256l242 213.4V331.2h270V180.6z"/></svg></button>,
    // nextArrow: <button style={{ ...buttonStyle }}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill='#6E6EF4'><path d="M512 256L270 42.6v138.2H0v150.6h270v138z"/></svg></button>
    // indicators: (i) => <div style={{cursor: 'pointer', letterSpacing: 35}} className="indicator">{i + 1}</div>
  };

  const formatUrl = (url) => {
    if (!url.startsWith("http")) {
      return `https://${url}`;
    }
    return url;
  };

  const handleMoveToProjectScreen = (projectId) => {
    navigate('ProjectDetailScreen', {state: { projectId: projectId}})
  };

  return (
    <div id={'home'} style={styles.container}>
      <Helmet>
          <meta
            name="description"
            content="Versatile Web & Mobile App Development made simple. Whether you need a simple website to showcase your business, or have an idea for the next big mobile app, our team has you covered."
          />
          {/* <title>Hixenis Enterprises</title> */}
          <title>Hixenis</title>
          <noscript>
            Versatile Web & Mobile App Development made simple. Whether you need a simple website to showcase your business, or have an idea for the next big mobile app, our team has you covered.
          </noscript>
          <link rel="icon" href={Favicon} />
          <link rel="icon" type="image/png" sizes="192x192" href={Logo192} />
          <link rel="icon" type="image/png" sizes="512x512" href={Logo512} />
          <link rel="apple-touch-icon" href={Logo192} />
        </Helmet>
      <img src={Earth} alt="Earth" style={styles.earth} />
      {loading && (
        <div style={styles.loadingOverlayOuterContainer}>
          <div style={styles.loadingOverlayInnerContainer}>
            <BounceLoader
              color={"#717DFA"}
              size={50}
              aria-label="Loading Spinner"
              data-testid="loader"
              speedMultiplier={1}
            />
            <p style={{ marginTop: 5 }}>{loadingStateMessage}</p>
          </div>
        </div>
      )}
      <div />
      {messageOverlayOpen && (
        <div style={styles.messageOverlayContainer1}>
          <div style={styles.messageOverlayContainer2}>
            <div style={styles.messageOverlayTitleContainer}>
              <p
                className="bolden-gate"
                style={styles.messageOverylayTitleText}
              >
                {messageOverlayTitleText}
              </p>
            </div>
            <div style={styles.messageOverlayBodyTextContainer}>
              <p style={{ marginTop: 5, textAlign: "center" }}>
                {messageOverlayText}
              </p>
            </div>
            <div
              onClick={() => setMessageOverlayOpen(false)}
              style={styles.messageOverlayButtonTextContainer}
            >
              <p style={styles.messageOverlayButtonText}>{"OK"}</p>
            </div>
          </div>
        </div>
      )}
      {(!isMobile && (
        <div style={styles.topBarContainer}>
          <div style={styles.leftPanelContainer}>
            <img src={Hixenis} alt="Logo" style={styles.hixenisLogo} />
          </div>
          <div style={styles.middlePanelContainer}>
            {/* <h2 style={styles.screenTitle}>Client Dashboard</h2>
            <p style={{ color: "white" }}>
              {`Welcome back, ${user ? user.firstName : "blank"}!`}
            </p> */}
          </div>

          <div style={styles.logoutButtonContainer}>
          {!isMobile && (
        <>
          <div
            onClick={() => handleGoToRegisterScreen(navigate)}
            style={styles.registerButton}
          >
            <p style={styles.registerText}>Register</p>
          </div>
          <div
            onClick={() => handleGoToLoginScreen(navigate)}
            style={styles.loginButton}
          >
            <p className="bolden-gate" style={styles.loginText}>
              Log in
            </p>
          </div>
        </>
      )}
          </div>
        </div>
      )) || (
        <div style={styles.topBarContainerMobile}>
          <div style={styles.topBarImageContainer}>
            <img src={Hixenis} alt="Logo" style={styles.hixenisLogoMobile} />
            <div style={styles.screenTitleMobile}>
              {/* <h2 style={styles.screenTitleTextMobile}>Client Dashboard</h2>
              <p style={{ color: "white", lineHeight: 0 }}>
                {`Welcome back, ${user ? user.firstName : "blunk"}!`}
              </p> */}
            </div>
          </div>
        </div>
      )}

      <div style={styles.navbarContainer}>
        <HixenisNavBar
          theme={'dark'}
          handleMoveToComponent={(value) => handleMoveToComponent(value)}
          handleGoToLoginScreen={() => handleGoToLoginScreen(navigate)}
          handleGoToRegisterScreen={() => handleGoToRegisterScreen(navigate)}
        />

      </div>
      <div style={styles.welcomeMessageContainer}>
        <h2 style={{zIndex: 1, marginBottom: !isMobile ? 0 : 10,}} className="bolden-gate">Versatile Web & Mobile App Development made simple.</h2>
        <p style={{zIndex: 1, fontSize: 24, textAlign: 'center'}} >Whether you need a simple website to showcase your business, or have an idea for the next big mobile app, our team has you covered.</p>
      </div>
      {!isMobile && (
        
        <div id={'projects'} style={{width: '100%', border: '0px solid red', display:"flex", alignItems: 'center', justifyContent: 'center', marginTop: 0,}}>
          {/* onMouseOver={() => setShowPause(true)} onMouseOut={() => setShowPause(false)}  */}
          <div style={{width: !isMobile ? '100%' : '100%', border: '0px solid lightGreen', }}>
         
          
            <Slide {...properties}>
              {slideImages.map((slideImage, index) => (
                <div style={{border: '0px solid yellow'}} key={index}>
                  <div
                    style={{
                      backgroundImage: `url(${slideImage.url})`,
                      height: !isMobile ? 600 : 300,
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",

                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      backgroundSize:  "contain",
                      maxHeight: "100%", // Ensures full image visibility
                      maxWidth: "100%", // Prevents overflow
                      objectFit: "contain", // Fits image without cropping
                    }}
                    
                  />
                  <div style={{display: 'flex', border: '0px solid red', width: '100%', justifyContent: 'center'}}>
                    <button 
                      // onClick={() => handleMoveToProjectScreen(slideImage.project)} 
                      onClick={() => window.open(formatUrl(slideImage.webUrl), "_blank")}
                      className="bolden-gate"
                      style={{border: '0px solid green', backgroundColor: '#6E6EF4', padding: 10, borderRadius: 10}}>
                     Learn more
                     </button>
                  </div>
                  
                </div>
              ))}
            </Slide>
            {/* {showPause && (
            <div style={{width: '99.90%', border:'0px solid lightBlue', position: 'absolute', display: 'flex', height: 'auto', alignItems: 'center', justifyContent: 'center'}}>
              <div style={{zIndex: 2, display: 'flex', flexDirection: 'row', border: '0px solid yellow', alignItems: 'center', justifyContent: "center"}}>
                <MdOutlinePause size={30} style={{color: 'white'}} />
              </div>
            </div>
            
            )} */}
            </div>
            
        </div>
      )
      ||
      (
        <div id={'projects'} style={{width: '100%', border: '0px solid red', display:"flex", alignItems: 'center', justifyContent: 'center', marginTop: 0,}}>
          <div style={{width: !isMobile ? '100%' : '100%', border: '0px solid red', }}>
            <Slide {...properties}>
              {slideImagesMobile.map((slideImage, index) => (
                <div style={{}} key={index}>
                  <div
                    style={{
                      backgroundImage: `url(${slideImage.url})`,
                      height: 450,
                      // width: '120%',
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                      // width: '110%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      backgroundSize:  "contain",
                      maxHeight: "100%", // Ensures full image visibility
                      maxWidth: "100%", // Prevents overflow
                      objectFit: "contain", // Fits image without cropping
                    }}
                  />
                  <div style={{display: 'flex', border: '0px solid red', width: '100%', justifyContent: 'center'}}>
                    <button 
                      // onClick={() => handleMoveToProjectScreen(slideImage.project)} 
                      onClick={() => window.open(formatUrl(slideImage.webUrl), "_blank")}
                      className="bolden-gate"
                      style={{border: '0px solid green', backgroundColor: '#6E6EF4', padding: 10, borderRadius: 10, fontSize: 20}}>
                     Learn more
                     </button>
                  </div>
                </div>
              ))}
            </Slide>
            </div>
        </div>
      )
      }
      

      <div id={'about'} style={styles.hixenisHomeMainContainer}>
        {(!isMobile && (
          <div
            // className="frosted-glass-container-blur-plus-plus"
            style={styles.frostedGlassContainer}
          >
            <div style={styles.ourServicesOuterContainer}>
              <div style={styles.ourServicesIconContainer}>
               
                <GiResize size={90} style={{color: '#6E6EF4'}} />

              </div>
              <div style={styles.ourServicesIconContainer}>
                <LiaConnectdevelop size={100} style={{color: '#6E6EF4'}} />

              </div>
              <div style={styles.ourServicesIconContainer}>
                <BsRocketTakeoff size={90} style={{color: '#6E6EF4'}} />

                
              </div>
            </div>

            <div style={styles.ourServicesOuterContainer}>
              <div style={styles.ourServicesSubtitleContainer}>
               
              <p style={{fontSize: 24, textAlign: 'center'}} className="bolden-gate" >Right-Sized Solutions</p>

              </div>
              <div style={styles.ourServicesSubtitleContainer}>
              <p style={{fontSize: 24, textAlign: 'center'}} className="bolden-gate" >Reliable, No-Downtime Development</p>

              </div>
              <div style={styles.ourServicesSubtitleContainer}>
              <p style={{fontSize: 24, textAlign: 'center',}} className="bolden-gate" >Startup & Product Strategy</p>

                
              </div>
            </div>

            <div style={styles.ourServicesOuterContainer}>
              <div style={styles.ourServicesTextContainer}>
               
              <p style={{textAlign:"center"}}>We build exactly what you need—nothing more, nothing less. No unnecessary features, no wasted budget, just the right fit. Scalable architecture that grows with your business when you're ready.</p>

              </div>
              <div style={styles.ourServicesTextContainer}>
              <p style={{textAlign:"center"}}>Designed for stability, ensuring your business stays online. Proactive monitoring and robust infrastructure to prevent failures. Zero-downtime deployments and efficient bug fixes.</p>

              </div>
              <div style={styles.ourServicesTextContainer}>
              <p style={{textAlign:"center"}}>End-to-end support for turning ideas into functional products. MVP development, iterative product scaling, and market fit strategy. Advising on monetization, security, and user experience best practices.</p>
                
              </div>
            </div>

            <div style={{display: 'flex', flexDirection: 'row', borderRadius: 20, backgroundColor: '#120f28', border: '0px solid #FFDB59',  marginBottom: 10, padding: 30, paddingLeft: 30, paddingRight: 30,}}>
              <div style={{display: 'flex', flex: 1, border: '0px solid black', alignItems: 'center', justifyContent:'center', }}>
                <img src={Essential2} alt="Essential" style={{objectFit: "cover", width: '100%', height: '100%', borderRadius: 10}} />
              </div>
              <div style={{display: 'flex', flex: 1.5, border: '0px solid black', flexDirection: 'column', }}>
                <h2 className="bolden-gate" style={{fontSize: 30, paddingLeft: 15, }}>Pre-built Solutions</h2>
                <div style={{backgroundColor: '#FFDB59', height: 3, marginLeft: 15, marginBottom: 5}}/>
                <div style={{ marginBottom: 'auto', paddingLeft: 15,}}>
                  <p style={{textAlign:"left", marginBottom: 5,}}>WordPress setup & customization tailored to your needs. Whether you need a personal blog, a business website, or an e-commerce store, we ensure a seamless installation and configuration process. We customize themes, optimize performance, and integrate essential plugins to enhance functionality. Security and SEO best practices are always a priority, ensuring your site is both safe and easily discoverable online. Additionally, we provide ongoing maintenance and support to keep your website running smoothly. Get a professional, polished site without the hassle of coding.</p>
                </div>
              </div>
            </div>
            
            <div style={{display: 'flex', flexDirection: 'row', borderRadius: 20, backgroundColor: '#120f28', border: '0px solid #15D699', marginBottom: 10, padding: 30, paddingLeft: 30, paddingRight: 30,}}>
             
              <div style={{display: 'flex', flex: 1.5, border: '0px solid yellow', padding: 20, flexDirection: 'column', borderRadius: 20,  marginBottom: 10,}}>
                <h2 className="bolden-gate" style={{fontSize: 30, textAlign:"right"}}>Custom Development & Mobile Apps</h2>
                <div style={{backgroundColor: '#15D699', height: 3, marginLeft: 15, marginBottom: 5,}}/>
                <div style={{ marginBottom: 'auto',}}>
                  <p style={{textAlign:"right", marginBottom: 5,}}>We build your custom solution from the ground up, tailored specifically to your business needs. Unlike pre-built templates, our development process ensures complete flexibility in functionality, design, and scalability. Whether you require a web application, a mobile app, or an internal business tool, we work closely with you to craft a high-quality, future-proof solution. Our approach focuses on performance, security, and user experience, ensuring your product is not only functional but also intuitive and efficient. From initial consultation to deployment and support, we guide you every step of the way.</p>
                </div>
              </div>
              <div style={{display: 'flex', flex: 1, border: '0px solid black', alignItems: 'center', justifyContent:'center',}}>
                <img src={Essential} alt="Essential" style={{objectFit: "cover", width: '100%', height: '100%', borderRadius: 10, maxHeight: 500}} />
              </div>
            </div>

            <div style={{display: 'flex', flexDirection: 'row', borderRadius: 20, backgroundColor: '#120f28', border: '0px solid #6E6EF4', marginBottom: 10, padding: 30, paddingLeft: 30, paddingRight: 30,}}>
              <div style={{display: 'flex', flex: 1, border: '0px solid black', alignItems: 'center', justifyContent:'center',}}>
                <img src={Enterprise} alt="Essential" style={{objectFit: "cover", width: '100%', height: '100%', borderRadius: 10, maxHeight: 400}} />
              </div>
              <div style={{display: 'flex', flex: 1.5 , border: '0px solid black', flexDirection: 'column', }}>
                <h2 className="bolden-gate" style={{fontSize: 30, paddingLeft: 15}}>Enterprise & Innovation</h2>
                <div style={{backgroundColor: '#6E6EF4', height: 3, marginLeft: 15, marginBottom: 5,}}/>
                <div style={{ marginBottom: 'auto', }}>
                  <p style={{textAlign:"left", marginBottom: 5, paddingLeft: 15}}>Advanced custom development for large-scale, high-traffic applications that demand top-tier performance and reliability. We specialize in building robust, scalable architectures capable of handling thousands to millions of users simultaneously. Whether you need a high-performance API, a real-time data processing system, or a cloud-native enterprise solution, our engineering expertise ensures stability and efficiency. Our solutions leverage cutting-edge technologies, including microservices, containerization, and cloud computing, to optimize cost and performance. We also focus on advanced security measures, ensuring that your application is protected against threats and downtime. Let us help you push the boundaries of innovation.</p>
                </div>
              </div>
            </div>

  

            <div style={{ width: 25 }} />

            <div style={{ flexDirection: "column", width: "100%" }}>
              
            </div>
          </div>
        )) || (
          <div
            style={{}}
          >
            <div style={styles.ourServicesOuterContainerMobile}>
              <div style={styles.ourServicesIconContainer}>
               
                <GiResize size={90} style={{color: '#6E6EF4'}} />
                <p style={{fontSize: 24, textAlign: 'center'}} className="bolden-gate" >Right-Sized Solutions</p>
                <div style={styles.ourServicesTextContainerMobile}>
                  <p style={{textAlign:"center"}}>We build exactly what you need—nothing more, nothing less. No unnecessary features, no wasted budget, just the right fit. Scalable architecture that grows with your business when you're ready.</p>
                
                </div>
              </div>
              <div style={styles.ourServicesIconContainer}>
                <LiaConnectdevelop size={100} style={{color: '#6E6EF4'}} />
                <p style={{fontSize: 24, textAlign: 'center'}} className="bolden-gate" >Reliable, No-Downtime Development</p>
                <div style={styles.ourServicesTextContainerMobile}>
                <p style={{textAlign:"center"}}>Designed for stability, ensuring your business stays online. Proactive monitoring and robust infrastructure to prevent failures. Zero-downtime deployments and efficient bug fixes.</p>
                </div>
                
              </div>
              <div style={styles.ourServicesIconContainer}>
                <BsRocketTakeoff size={90} style={{color: '#6E6EF4'}} />
                <p style={{fontSize: 24, textAlign: 'center',}} className="bolden-gate" >Startup & Product Strategy</p>
                <div style={styles.ourServicesTextContainerMobile}>
                <p style={{textAlign:"center"}}>End-to-end support for turning ideas into functional products. MVP development, iterative product scaling, and market fit strategy. Advising on monetization, security, and user experience best practices.</p>
                </div>
                
              </div>

              <div style={{display: 'flex', flexDirection: 'column',  borderRadius: 20, border: '0px solid #FFDB59',  padding: 30, paddingLeft: 10, paddingRight: 10,}}>
              <div style={{display: 'flex', flex: 1, border: '0px solid black', alignItems: 'center', justifyContent:'center', }}>
                <img src={Essential2} alt="Essential" style={{objectFit: "cover", width: '100%', height: '100%', borderRadius: 10}} />
              </div>
              <div style={{display: 'flex', flex: 1.5, border: '0px solid black', flexDirection: 'column', }}>
                <h2 className="bolden-gate" style={{fontSize: 30, paddingLeft: 15, marginTop: 10, borderBottom: '0px solid #FFDB59', marginBottom: 5, lineHeight: 1.2}}>Pre-built Solutions</h2>
                <div style={{backgroundColor: '#FFDB59', height: 3, marginLeft: 15, marginBottom: 5}}/>
                <div style={{ marginBottom: 'auto', paddingLeft: 15,}}>
                  <p style={{textAlign:"left", marginBottom: 5,}}>WordPress setup & customization tailored to your needs. Whether you need a personal blog, a business website, or an e-commerce store, we ensure a seamless installation and configuration process. We customize themes, optimize performance, and integrate essential plugins to enhance functionality. Security and SEO best practices are always a priority, ensuring your site is both safe and easily discoverable online. Additionally, we provide ongoing maintenance and support to keep your website running smoothly. Get a professional, polished site without the hassle of coding.</p>
                </div>
              </div>
            </div>
            
            <div style={{display: 'flex', flexDirection: 'column', borderRadius: 20,  border: '0px solid #15D699', padding: 30, paddingLeft: 10, paddingRight: 10,}}>
            <div style={{display: 'flex', flex: 1, border: '0px solid black', alignItems: 'center', justifyContent:'center',}}>
                <img src={Essential} alt="Essential" style={{objectFit: "cover", width: '100%', height: '100%', borderRadius: 10, maxHeight: 500}} />
              </div>
              <div style={{display: 'flex', flex: 1.5, border: '0px solid yellow', padding: 20, flexDirection: 'column', borderRadius: 20,  marginBottom: 10,}}>
                <h2 className="bolden-gate" style={{fontSize: 30, textAlign:"right", marginTop: 10, borderBottom: '0px solid #15D699', marginBottom: 5, lineHeight: 1.2, }}>Custom Development & Mobile Apps</h2>
                <div style={{backgroundColor: '#15D699', height: 3, marginLeft: 15, marginBottom: 5}}/>
                <div style={{ marginBottom: 'auto',}}>
                  <p style={{textAlign:"right", marginBottom: 5,}}>We build your custom solution from the ground up, tailored specifically to your business needs. Unlike pre-built templates, our development process ensures complete flexibility in functionality, design, and scalability. Whether you require a web application, a mobile app, or an internal business tool, we work closely with you to craft a high-quality, future-proof solution. Our approach focuses on performance, security, and user experience, ensuring your product is not only functional but also intuitive and efficient. From initial consultation to deployment and support, we guide you every step of the way.</p>
                </div>
              </div>
              
            </div>

            <div style={{display: 'flex', flexDirection: 'column', borderRadius: 20,  border: '0px solid #6E6EF4', padding: 30, paddingLeft: 10, paddingRight: 10,}}>
              <div style={{display: 'flex', flex: 1, border: '0px solid black', alignItems: 'center', justifyContent:'center',}}>
                <img src={Enterprise} alt="Essential" style={{objectFit: "cover", width: '100%', height: '100%', borderRadius: 10,}} />
              </div>
              <div style={{display: 'flex', flex: 1.5 , border: '0px solid black', flexDirection: 'column', }}>
                <h2 className="bolden-gate" style={{fontSize: 30, paddingLeft: 15, marginTop: 10, borderBottom: '0px solid #6E6EF4', marginBottom: 5, lineHeight: 1.2}}>Enterprise & Innovation</h2>
                <div style={{backgroundColor: '#6E6EF4', height: 3, marginLeft: 15, marginBottom: 5}}/>
                <div style={{ marginBottom: 'auto', }}>
                  <p style={{textAlign:"left", marginBottom: 5, paddingLeft: 15}}>Advanced custom development for large-scale, high-traffic applications that demand top-tier performance and reliability. We specialize in building robust, scalable architectures capable of handling thousands to millions of users simultaneously. Whether you need a high-performance API, a real-time data processing system, or a cloud-native enterprise solution, our engineering expertise ensures stability and efficiency. Our solutions leverage cutting-edge technologies, including microservices, containerization, and cloud computing, to optimize cost and performance. We also focus on advanced security measures, ensuring that your application is protected against threats and downtime. Let us help you push the boundaries of innovation.</p>
                </div>
              </div>
            </div>
              

        


            </div>


            <div style={{ width: 25 }} />

            <div style={{ flexDirection: "column", width: "100%" }}>
              
            </div>
          </div>
        )}
      </div>
      
        

      <div id={"contact"} style={styles.contactContainerMain}>
        <>
        
            <div style={styles.contactFormContainer}>
              {(contactFormLoading && (
                <div
                  className="sweet-loading"
                  style={styles.contactFormLoadingMobile}
                >
                  <BounceLoader
                    color={contactFormIndicatorColor}
                    loading={contactFormLoading}
                    size={60}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                    speedMultiplier={1}
                  />
                  <h3 style={styles.contactFormLoadingTextMobile}>
                    making contact...
                  </h3>
                </div>
              )) ||
                (contactFormSubmitted && (
                  <div style={styles.contactFormSubmittedMobile}>
                    <FaRegCheckCircle size={45} style={{ color: "#03fc0b" }} />
                    <h3 style={styles.contactFormCircleCheckMobile}>
                      {`Message received!`}
                    </h3>
                    <p style={styles.contactFormCircleCheckMobile}>
                      {`We'll be in touch shortly.`}
                    </p>
                  </div>
                )) || (
                  <form
                    onSubmit={handleSubmit}
                    style={styles.contactFormInnerContainerMobile}
                  >
                   {!contactFormSubmitted && (
                      <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'center', marginTop: 10}}>
                      <IoIosSend  size={90} style={{color: '#6E6EF4', }} />
                      <h2 className="bolden-gate" style={styles.contactTitleMobileSecondary}>Contact us</h2>
                      <p style={{textAlign: 'center'}}>To better understand your vision and provide a tailored response, please include a detailed overview of your idea along with the key features and components you have in mind. The more details you provide, the better we can assess your needs and offer insights.</p>
                      </div>
                      
                    )
                    ||
                    (
                      <div style={{height: 25}}></div>
                    )
                    }
            
                    <input
                      type="text"
                      name="name"
                      placeholder="Your Name"
                      value={formData.name}
                      onChange={handleInputChange}
                      required
                      style={styles.contactFormInputNameMobile}
                    />
                    <input
                      type="email"
                      name="email"
                      placeholder="Your Email"
                      value={formData.email}
                      onChange={handleInputChange}
                      required
                      style={styles.contactFormInputEmailMobile}
                    />
                    <textarea
                      name="message"
                      placeholder="Your Message"
                      value={formData.message}
                      onChange={handleInputChange}
                      required
                      rows="4"
                      style={styles.contactFormInputMessageMobile}
                    />
                    <button
                      type="submit"
                      style={styles.contactFormSubmitButtonMobile}
                      onMouseEnter={(e) => {
                        e.target.style.backgroundColor =
                          theme === "light" ? "#6e6ef4" : "white";
                        e.target.style.color =
                          theme === "light" ? "#6e6ef4" : "black";
                      }}
                      onMouseLeave={(e) => {
                        e.target.style.backgroundColor =
                          theme === "light" ? "#6e6ef4" : "#6e6ef4";
                        e.target.style.color =
                          theme === "light" ? "#ffffff" : "white";
                      }}
                    >
                      Send Message
                    </button>
                    {contactFormHasErrors && (
                      <p
                        className="bolden-gate"
                        style={styles.contactFormErrorsMobile}
                      >
                        Error: Please try again later!
                      </p>
                    )}
                    <div style={{ height: 50 }}></div>
                  </form>
                )}
            </div>
        </>

        
    
    
      </div>

      <HixenisFooter
        handleMoveToComponent={(value) => handleMoveToComponent(value)}
      />
    </div>
  );
}

export default HixenisHome;
