// JJ-108
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  initialTest,
  greet,
  add,
  isEven,
  filterEvens,
  fetchUserData,
} from "../api/dataServiceV2";
import Animal from "../Classes/Animal";

function SupportScreen() {
  const dog = new Animal("dog", "mammal", "male", 20, 3, 8);
  dog.identifySelf();
  dog.updateAge(4);
  console.log(dog);

  const [enabled, setEnabled] = useState<boolean>(true);
  const [statusButtonIsHovered, setStatusButtonIsHovered] =
    useState<boolean>(false);
  const [statusChangerValue, setStatusChangerValue] = useState<string>();
  const [row2Value, setRow2Value] = useState<string>("");
  const [test3ValueA, setTest3ValueA] = useState<number>(0);
  const [test3ValueB, setTest3ValueB] = useState<number>(0);

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const inputValue = event.target.value;

    // Validate input (allow only numbers and a single decimal point)
    if (/^\d*\.?\d*$/.test(inputValue) || inputValue === "") {
      setStatusChangerValue(inputValue);
    }
  };

  const handleRow2Change = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const inputValue = event.target.value;
    setRow2Value(inputValue);
    console.log(inputValue);
  };

  const handleRunTest2 = (value: string) => {
    if (value.length > 0) {
      const returnValue = greet(value);
      alert(returnValue);
    }
  };

  const handleRunTest3 = (valueA: number, valueB: number): number => {
    const result = add(valueA, valueB);
    return result;
  };

  const handleSetTest3ValueA = (
    event: React.ChangeEvent<HTMLInputElement>
  ): boolean => {
    var incomingValue = event.target.value;
    setTest3ValueA(parseFloat(incomingValue));
    return false;
  };

  // const handleSetTest3ValueB = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   var incomingValue = event.target.value;
  //   setTest3ValueB(parseFloat(incomingValue));
  // };

  function handleSetTest3ValueB(
    event: React.ChangeEvent<HTMLInputElement>
  ): void {
    var incomingValue = event.target.value;
    setTest3ValueB(parseFloat(incomingValue));
    

  }

  return (
    <div style={styles.container}>
      {/* <p
        className="bolden-gate"
        style={styles.titleText}
      >{`Welcome to the Support dashboard!`}</p>
      <p style={styles.body}>{`Where all your dreams come true!`}</p>

      <div style={styles.simpleRow}>
        <div
          onClick={() => setEnabled(enabled ? false : true)}
          onMouseOver={() => setStatusButtonIsHovered(true)}
          onMouseOut={() => setStatusButtonIsHovered(false)}
          style={
            statusButtonIsHovered
              ? styles.statusButtonHover
              : styles.statusButton
          }
        >
          <p style={styles.statusButtonText}>{`Run Test 1`}</p>
        </div>
        <div style={styles.rowInput}>
          <textarea
            placeholder="Input NUMBER..."
            value={statusChangerValue}
            onChange={(value) => handleChange(value)}
            style={{
              fontSize: 16,
              border: "1px solid grey",
              color: "black",
              fontWeight: "normal",
              width: "100%",
              height: "100%",
            }}
          />
        </div>
        <div style={styles.rowLabel}>
          <div style={styles.statusWrap}>
            <p style={styles.statusLabel}>{`CURRENT STATUS:`}</p>
            <p style={enabled ? styles.enabled : styles.disabled}>
              {` ${enabled}`.toUpperCase()}
            </p>
          </div>
        </div>
      </div>

      <div style={styles.simpleRow}>
        <div
          onClick={() => handleRunTest2(row2Value)}
          style={styles.statusButton}
        >
          <p style={styles.statusButtonText}>{`Run Test 2`}</p>
        </div>
        <div style={styles.rowInput}>
          <textarea
            placeholder="Input STRING..."
            value={statusChangerValue}
            onChange={(value) => handleRow2Change(value)}
            style={{
              fontSize: 16,
              border: "1px solid grey",
              color: "black",
              fontWeight: "normal",
              width: "100%",
              height: "100%",
            }}
          />
        </div>
        <div style={styles.rowLabel}>
          <div style={styles.statusWrap}>
            <p style={enabled ? styles.enabled : styles.disabled}>
              {` ${row2Value}`.toUpperCase()}
            </p>
          </div>
        </div>
      </div>

      <div style={styles.simpleRow}>
        <div
          onClick={() => handleRunTest3(test3ValueA, test3ValueB)}
          style={styles.statusButton}
        >
          <p style={styles.statusButtonText}>{`Run Test 3`}</p>
        </div>
        <div style={styles.rowInput}>
          <input
            type={"number"}
            placeholder="Input number a..."
            value={test3ValueA}
            onChange={(value) => handleSetTest3ValueA(value)}
            style={{
              fontSize: 16,
              border: "1px solid grey",
              color: "black",
              fontWeight: "normal",
              width: "100%",
              height: "20%",
              padding: 0,
            }}
          />
          <input
            type={"number"}
            placeholder="Input number b..."
            value={test3ValueB}
            onChange={(value) => handleSetTest3ValueB(value)}
            style={{
              fontSize: 16,
              border: "1px solid grey",
              color: "black",
              fontWeight: "normal",
              width: "100%",
              height: "20%",
            }}
          />
        </div>
        <div style={styles.rowLabel}>
          <div style={styles.statusWrap}>
            <p style={enabled ? styles.enabled : styles.disabled}>
              {` ${row2Value}`.toUpperCase()}
            </p>
          </div>
        </div>
      </div> */}
    </div>
  );
}

const styles: Record<string, React.CSSProperties> = {
  rowLabel: {
    // border: '3px solid red',
    flex: 2,
    paddingLeft: 15,
  },
  rowInput: {
    // border: '3px solid red',
    flex: 1,
    padding: 0,
    margin: 0,
    display: "flex",
    flexDirection: "column",
  },
  simpleRow: {
    border: "1px solid black",
    display: "flex",
    flexDirection: "row",
    flex: 2,
    maxHeight: 120,
  },
  container: {
    display: "flex",
    // alignItems: "center",
    // justifyContent: "center",
    fontSize: 56,
    backgroundColor: "#0D101C",
    width: "100%",
    minHeight: "100vh",
    color: "blue",
    flexDirection: "column",
    // border: "1px solid red",
    padding: 30,
  },
  statusButtonText: {
    // color: "white",
    // fontSize: 26,
    textAlign: "center",
    fontSize: `clamp(16px, 5vw, 26px)`, // Min: 16px, Preferred: 5% of viewport width, Max: 26px
    // flex: 1,
    // border: '2px solid red'
  },
  statusButton: {
    flex: 1,
    // width: 300,
    // height: 75,
    backgroundColor: "blue",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "white",
    cursor: "pointer",
    padding: 10,
    // borderRadius: 30,
    userSelect: "none", // Prevents text selection
  },
  statusButtonHover: {
    // width: 300,
    flex: 1,
    backgroundColor: "yellow",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "black",
    cursor: "pointer",
    padding: 10,
    userSelect: "none", // Prevents text selection
  },
  titleText: {
    display: "flex",
    // alignItems: "center",
    // justifyContent: "center",
    // border: '1px solid red',
    // textAlign: "center",
    lineHeight: 1.1,
  },
  statusLabel: {
    color: "DimGray",
    fontSize: 26,
    marginRight: 5,
  },
  body: {
    color: "black",
    fontSize: 26,
    // textAlign: 'center'
  },
  enabled: {
    color: "green",
    fontSize: 26,
  },
  disabled: {
    color: "red",
    fontSize: 26,
  },
  statusWrap: {
    display: "flex",
    flexDirection: "row",
  },
};
export default SupportScreen;
