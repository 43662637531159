import "./App.css";
import { useState, useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import "./fonts/fonts.css";
import NavBar from "./components/NavBar";
import BasicNewsArticle from "./components/BasicNewsArticle";
import GlassContainer from "./components/GlassContainer";
import ProjectItem from "./components/ProjectItem";
import Footer from "./components/Footer";
import LoginWindow from "./components/LoginWindow";
import { FaRegCheckCircle } from "react-icons/fa";
import BounceLoader from "react-spinners/BounceLoader";
import Home from "./Screens/Home";
import AdminDashboard from "./Screens/AdminDashboard";
import ClientDashboard from "./Screens/ClientDashboard";
import LoginScreen from "./Screens/LoginScreen";

function App() {
  return (
    <div style={{width: '100%', minHeight: "100vh" , display: "flex", border: '0px solid red'}}>
        <Routes>
          <Route
              path="/"
              exact
              element={
                <Home/>
              }
            />
            <Route
              path="/LoginScreen"
              exact
              element={
                <LoginScreen/>
              }
            />
            <Route
              path="/AdminDashboard"
              exact
              element={
                <AdminDashboard/>
              }
            />
            <Route
              path="/ClientDashboard"
              exact
              element={
                <ClientDashboard/>
              }
            />
            {/* <Route
              path="/ClientDashboard"
              exact
              element={
                <AdminDashboard/>
              }
            /> */}
          </Routes>
    </div>
  ); 
}
let styles = {
  container: {
    width: "100%",
    minHeight: "100vh", // Ensures the container fills the viewport height
    display: "flex",
  },
};
export default App;
