// JJ-108
import React, { useState, useEffect } from "react";
import "../App.css";
import { useLocation, useNavigate } from "react-router-dom";
import Hixenis from "../images/HIXENIS_NEW_W_P.png";
import Xena from "../images/xena.jpg";
import Xena2 from "../images/xena2.jpg";
import Code from "../images/code.jpg";
import zIndex from "@mui/material/styles/zIndex";
import DashboardNavBar from "../components/DashboardNavbar";
import SubscriptionListItem from "../components/SubscriptionListItem";
import SubscriptionListItemMobile from "../components/SubscriptionListItemMobile";
import SubscriptionSpotlight from "../components/SubscriptionSpotlight";
import ProductListItemMobile from "../components/ProductListItemMobile";
import ProductListItem from "../components/ProductListItem";
import ProductSpotlight from "../components/ProductSpotlight";
import moment from "moment";
import {
  getSubscriptions,
  getProducts,
  getEmailVerificationStatus,
  resendVerificationEmail,
  cancelSubscription,
  typescriptTest,
} from "../api/dataService";
import { useUser } from "../stores/UserContext";
import BounceLoader from "react-spinners/BounceLoader";
import { StyleSharp } from "@mui/icons-material";

function ClientDashboard() {
  const { user, updateUser } = useUser();

  const location = useLocation();
  const props = location.state || {};

  const [messageOverlayOpen, setMessageOverlayOpen] = useState(false);
  const [messageOverlayTitleText, setMessageOverlayTitleText] = useState("");
  const [messageOverlayTitleTextColor, setMessageOverlayTitleTextColor] =
    useState("#bbbbed");
  const [messageOverlayText, setMessageOverlayText] = useState("");

  const [subscriptions, setSubscriptions] = useState([]);
  const [subscriptionsLoading, setSubscriptionsLoading] = useState(true);

  const [products, setProducts] = useState([]);
  const [productsLoading, setProductsLoading] = useState(true);

  const [isMobile, setIsMobile] = useState(window.innerWidth < 900); // Adjust the threshold as needed

  const [emailVerificationRequired, setEmailVerificationRequired] =
    useState(false);
  const [emailVerificationSending, setEmailVerificationSending] =
    useState(false);
  const [emailVerificationSent, setEmailVerificationSent] = useState(false);

  const [selectedSubscription, setSelectedSubscription] = useState();
  const [selectedProduct, setSelectedProduct] = useState();

  const [loading, setLoadingState] = useState(false);
  const [loadingStateMessage, setLoadingStateMessage] = useState("");

  const handleResize = () => {
    const isCurrentlyMobile = window.innerWidth < 900;
    setIsMobile(isCurrentlyMobile);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    handleGetSubscriptions();
    handleGetProducts();
  }, [user]);

  useEffect(() => {
    // handleTypescriptTest();
  }, []);

  const handleGetEmailVerificationStatus = () => {
    getEmailVerificationStatus({ userId: user.id })
      .then((response) => {
        if (response.status === "OK") {
          var userEmailVerified = response.data;
          if (userEmailVerified === true) {
            setEmailVerificationRequired(false);
          } else {
            setEmailVerificationRequired(true);
          }
        } else {
          console.log("Error getting email verification status", response);
        }
      })
      .catch((error) => {
        console.log(`Error getting email verification status: ${error}`);
      });
  };

  const handleGetSubscriptions = () => {
    if (user) {
      getSubscriptions({ userId: user.id, email: user.email })
        .then((response) => {
          if (response.status === "OK") {
            setSubscriptions(response.data);
            setSelectedSubscription(response.data[0]);
            setSubscriptionsLoading(false);
          } else {
            console.log(response);
            setSubscriptions([]);
            setSubscriptionsLoading(false);
          }
        })
        .catch((error) => {
          console.log(`error, ${error}`);
          setSubscriptions([]);
          setSubscriptionsLoading(false);
        });
    }
  };

  const handleGetProducts = () => {
    getProducts()
      .then((response) => {
        if (response.status === "OK") {
          console.log(response);
          setProducts(response.data);
          setSelectedProduct(response.data[0]);
          setProductsLoading(false);
        } else {
          console.log(response);
          setProducts([]);
          setProductsLoading(false);
        }
      })
      .catch((error) => {
        console.log(`error, ${error}`);
        setProducts([]);
        setProductsLoading(false);
      });
  };

  const handleResendVerificationEmail = () => {
    setEmailVerificationRequired(false);
    setEmailVerificationSending(true);
    resendVerificationEmail()
      .then((response) => {
        if (response.status === "OK") {
          setEmailVerificationRequired(false);
          setEmailVerificationSending(false);
          setEmailVerificationSent(true);
          console.log(response);
          setProducts(response.data);
          setProductsLoading(false);
        } else {
          setEmailVerificationRequired(false);
          setEmailVerificationSending(false);
          setEmailVerificationSent(true);
          console.log(response);
          setProducts([]);
          setProductsLoading(false);
        }
      })
      .catch((error) => {
        setEmailVerificationRequired(false);
        setEmailVerificationSending(false);
        setEmailVerificationSent(true);
        console.log(`error, ${error}`);
      });
  };

  const handleLogout = (navigate) => {
    updateUser(null);
    navigate("/");
  };

  const handleMoveToScreen = (screen, navigate) => {
    if (screen === "AccountEditScreen") {
      navigate(`/${screen}`, { state: null });
    }
    else if(screen === "SupportScreen") {
      navigate(`/${screen}`, { state: null });
    }
     else {
      alert("This screen is not ready yet!");
    }
  };

  const handleCheckoutProduct = (stripePriceId, stripeProductId) => {
    navigate(`/checkout`, {
      state: {
        stripePriceId: stripePriceId,
        stripeProductId: stripeProductId,
        userId: user.id,
      },
      replace: true, // Ensures this navigation replaces the current history entry
    });
  };

  const handleReactivateSubscription = (subscription) => {
    console.log("Reactivate this one", subscription);
  };

  const handleCancelSubscription = (subscription) => {
    setLoadingState(true);
    setLoadingStateMessage("Cancelling subscription...");
    cancelSubscription({ userId: user.id, subscriptionId: subscription.id })
      .then((response) => {
        if (response.status === "OK") {
          console.log(response.data);
          handleGetSubscriptions();
          setMessageOverlayOpen(true);
          setMessageOverlayTitleText("Subscription Cancelled");
          setMessageOverlayText(
            `You have successfully cancelled your subscription.`
          );
          setLoadingState(false);
        } else {
          setMessageOverlayOpen(true);
          setMessageOverlayTitleText("ERROR");
          setMessageOverlayText(`${response.detail}`);
          setLoadingState(false);
        }
      })
      .catch((error) => {
        setMessageOverlayOpen(true);
        setMessageOverlayTitleText("ERROR");
        setMessageOverlayText(
          `Something went wrong. Please try again later. ${error}`
        );
        setLoadingState(false);
      });
  };

  const handleTypescriptTest = () => {
    setLoadingState(true);
    setLoadingStateMessage("Testing typescript...");
    typescriptTest({ testStringData: 'hoohaw' })
      .then((response) => {
        if (response.status === "OK") {
          console.log(response.data);
          setMessageOverlayOpen(true);
          setMessageOverlayTitleText("typescript Test Success");
          setMessageOverlayText(
            `${response.data}`
          );
          setLoadingState(false);
        } else {
          setMessageOverlayOpen(true);
          setMessageOverlayTitleText("ERROR");
          setMessageOverlayText("ERROR");
          setLoadingState(false);
        }
      })
      .catch((error) => {
        setMessageOverlayOpen(true);
        setMessageOverlayTitleText("ERROR");
        setMessageOverlayText(
          `Something went wrong. Please try again later. ${error}`
        );
        setLoadingState(false);
      });
  };

  const navigate = useNavigate();

  let styles = {
    container: {
      backgroundColor: "#090714",
      justifyContent: "center",
      width: "100%",
      color: "white",
    },
    loadingOverlayOuterContainer: {
      position: "absolute",
      zIndex: 12000,
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      width: "100%",
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      transition: "opacity 1s ease-in-out",
    },
    loadingOverlayInnerContainer: {
      padding: 30,
      backgroundColor: "rgba(44, 41, 63, 1)",
      borderRadius: 10,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      color: "white",
      boxShadow: "0 0px 10px rgba(120, 0, 255, 1)",
      border: "1px solid #6E6EF4",
    },
    messageOverlayContainer1: {
      position: "absolute",
      zIndex: 12000,
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      width: "100%",
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      transition: "opacity 1s ease-in-out",
    },
    messageOverlayContainer2: {
      width: 350,
      backgroundColor: "#191632",
      borderRadius: 10,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      color: "white",
      boxShadow: "0 0px 15px rgba(0, 0, 0, .8)",
      border: "1px solid #6E6EF4",
      overflow: "hidden",
    },
    messageOverlayTitleContainer: {
      borderBottom: "0px solid #6E6EF4",
      width: "90%",
      flex: 1,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      paddingTop: 5,
    },
    messageOverlayTitleText: {
      letterSpacing: 2,
      marginTop: 5,
      fontSize: 18,
      color: messageOverlayTitleTextColor,
    },
    messageOverlayBodyTextContainer: {
      border: "0px solid red",
      width: "100%",
      flex: 1,
      display: "flex",
      justifyContent: "center",
      padding: 16,
      paddingTop: 0,
    },
    messageOverlayButtonTextContainer: {
      cursor: "pointer",
      borderTop: "1px solid #6E6EF4",
      width: "100%",
      flex: 1,
      display: "flex",
      justifyContent: "center",
      padding: 16,
    },
    messageOverlayButtonText: {
      fontSize: 22,
      marginTop: 5,
      textAlign: "center",
    },
    topBarContainer: {
      width: "100%",
      height: 175,
      display: "flex",
      flexDirection: "row",
    },
    leftPanelContainer: {
      width: "30%",
      height: "100%",
      border: "0px solid blue",
      padding: 30,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
    },
    hixenisLogo: {
      maxWidth: 250,
      maxHeight: "100%",
      objectFit: "contain",
    },
    middlePanelContainer: {
      display: "flex",
      width: "40%",
      height: "100%",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
    },
    screenTitle: {
      color: "#7484F9",
      fontSize: 22,
      paddingBottom: 10,
    },
    logoutButtonContainer: {
      width: "30%",
      border: "0px solid yellow",
      height: "100%",
      padding: 30,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    logoutButtonInnerContainer: {
      zIndex: 12000,
      cursor: "pointer",
      display: "flex",
      borderRadius: 5,
      width: 120,
      height: 40,
      backgroundColor: "#6e6ef4",
      alignItems: "center",
      justifyContent: "center",
    },
    topBarContainerMobile: {
      width: "100%",
      height: 175,
    },
    topBarImageContainer: {
      width: "100%",
      height: "100%",
      padding: 30,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
    },
    hixenisLogoMobile: {
      maxWidth: 400,
      maxHeight: "100%",
      objectFit: "contain",
    },
    screenTitleMobile: {
      display: "flex",
      border: "0px solid green",
      height: "100%",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
    },
    screenTitleTextMobile: {
      color: "#7484F9",
      fontSize: 22,
      paddingBottom: 10,
    },
    navbarContainer: {
      width: "100%",
    },
    emailVerificationAlertContainer: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      border: "0px solid yellow",
    },
    emailVerificationAlertContainer2: {
      width: "75%",
      display: "flex",
      backgroundColor: "#965609",
      alignItems: "center",
      borderRadius: 5,
      padding: 10,
      justifyContent: "center",
      border: "1px solid #ff8c00",
    },
    emailVerificationAlertSendingContainer: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      border: "0px solid yellow",
    },
    emailVerificationAlertSendingInnerContainer: {
      width: "75%",
      display: "flex",
      backgroundColor: "#965609",
      alignItems: "center",
      borderRadius: 5,
      padding: 10,
      justifyContent: "center",
      border: "1px solid #ff8c00",
    },
    emailVerificationAlertSentContainer: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      border: "0px solid yellow",
    },
    emailVerificationAlertSentInnerContainer: {
      width: "75%",
      display: "flex",
      backgroundColor: "#965609",
      alignItems: "center",
      borderRadius: 5,
      padding: 10,
      justifyContent: "center",
      border: "1px solid #ff8c00",
    },
    clientDashboardMainContainer: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    frostedGlassContainer: {
      marginTop: 15,
      border: "1px solid #3c4684",
      width: "95%",
      padding: 30,
      borderRadius: 5,
      display: "flex",
      flexDirection: "column",
    },
    subscriptionsMainContainer: {
      flexDirection: "column",
      width: "100%",
      marginBottom: 25,
    },
    subscriptionsInnerContainer: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
    },
    subscriptionsLeftPanel: {
      display: "flex",
      borderRadius: 5,
      flexDirection: "column",
      width: "40%",
      backgroundColor: "#1c1538",
      border: "1px solid #7484F9",
      padding: 15,
    },
    subscriptionsTitleContainer: {
      flexDirection: "column",
      width: "100%",
    },
    subscriptionsTitleContainer2: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
    },
    subscriptionsTitleContainer3: {
      borderBottom: "1px solid #34227c",
      flex: 1,
    },
    subscriptionsTitle: {
      color: "white",
      fontSize: 18,
      padding: 10,
      paddingTop: 0,
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    subscriptionsDivider: { borderBottom: "1px solid #34227c", flex: 1 },
    subscriptionsListContainer: {
      textAlign: "center",
      width: "100%",
      minHeight: 250,
      alignItems: "center",
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
    },

    storeMain: {
      display: "flex",
      borderRadius: 5,
      flexDirection: "column",
      width: "100%",
      backgroundColor: "#1c1538",
      border: "1px solid #7484F9",
      padding: 15,
      paddingLeft: 0,
      paddingRight: 0,
      minHeight: 250,
    },
    storeTitleMain: { flexDirection: "column", width: "100%" },
    storeTitleInner: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      marginBottom: 5,
    },
    storeTitleDivider: {
      borderBottom: "1px solid #34227c",
      flex: 1,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    storeHeading: {
      color: "#94a1f7",
      fontSize: 18,
      padding: 10,
      paddingTop: 0,
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      letterSpacing: 2,
    },
    storeSecondaryContainerMain: {
      textAlign: "center",
      width: "100%",
      alignItems: "flex-start",
      display: "flex",
      justifyContent: "flex-start",
      flexDirection: "row",
      padding: 15,
      paddingTop: 0,
      paddingBottom: 15,
    },
    storeLeftPanel: {
      display: "flex",
      borderRadius: 5,
      flexDirection: "column",
      width: "40%",
      backgroundColor: "#1c1538",
      border: "1px solid #7484F9",
      padding: 15,
    },
    storeLeftPanelInner: { flexDirection: "column", width: "100%" },
    storeLeftPanelTitle: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
    },
    storeLeftPanelTitleContainer: {
      borderBottom: "1px solid #34227c",
      flex: 1,
    },
    storeLeftPanelHeader: {
      color: "white",
      fontSize: 18,
      padding: 10,
      paddingTop: 0,
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "flex",
    },
    productsListContainerMain: {
      textAlign: "center",
      width: "100%",
      minHeight: 250,
      alignItems: "center",
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
    },

    mobileContainerMain: {
      marginTop: 15,
      width: "100%",
      borderRadius: 5,
      display: "flex",
      flexDirection: "column",
      borderRight: "none",
    },
    mobileContainerInner: {
      flexDirection: "column",
      width: "100%",
      marginBottom: 25,
    },
    mobileSubsInnerContainer: {
      display: "flex",
      borderRadius: 5,
      flexDirection: "column",
      width: "100%",
      backgroundColor: "#1c1538",
      border: "1px solid #34227C",
      padding: 15,
      paddingLeft: 3,
      paddingRight: 3,
      minHeight: 250,
    },
    mobileSubsTitleContainer: { flexDirection: "column", width: "100%" },
    mobileSubsTitleInner: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      // border: '1px solid yellow'
    },
    mobileSubsDivider: { borderBottom: "1px solid #34227c", flex: 1 },
    mobileSubsTitleText: {
      color: "white",
      fontSize: 18,
      padding: 10,
      paddingTop: 0,
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      paddingLeft: 20,
    },
    mobileSubsList: {
      textAlign: "center",
      width: "100%",
      minHeight: 250,
      border: "0px solid white",
      alignItems: "center",
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
    },
    mobileStoreMain: { flexDirection: "column", width: "100%" },
    mobileStoreInner: {
      display: "flex",
      borderRadius: 5,
      flexDirection: "column",
      width: "100%",
      backgroundColor: "#1c1538",
      border: "1px solid #34227C",
      padding: 15,
      paddingLeft: 3,
      paddingRight: 3,
      minHeight: 250,
      borderBottom: "1px solid #3c4ab7",
    },
    mobileStoreTitleContainer: { flexDirection: "column", width: "100%" },
    mobileStoreTitleInner: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
    },
    mobileStoreTitleDivider: {
      borderBottom: "1px solid #34227c",
      flex: 1,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    mobileStoreTitleText: {
      color: "#94a1f7",
      fontSize: 18,
      padding: 10,
      paddingTop: 0,
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      letterSpacing: 2,
    },
    mobileStoreProducts: {
      textAlign: "center",
      width: "100%",
      minHeight: 250,
      border: "0px solid white",
      alignItems: "center",
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
    },
  };

  return (
    <div style={styles.container}>
      {loading && (
        <div style={styles.loadingOverlayOuterContainer}>
          <div style={styles.loadingOverlayInnerContainer}>
            <BounceLoader
              color={"#717DFA"}
              size={50}
              aria-label="Loading Spinner"
              data-testid="loader"
              speedMultiplier={1}
            />
            <p style={{ marginTop: 5 }}>{loadingStateMessage}</p>
          </div>
        </div>
      )}
      <div />
      {messageOverlayOpen && (
        <div style={styles.messageOverlayContainer1}>
          <div style={styles.messageOverlayContainer2}>
            <div style={styles.messageOverlayTitleContainer}>
              <p
                className="bolden-gate"
                style={styles.messageOverylayTitleText}
              >
                {messageOverlayTitleText}
              </p>
            </div>
            <div style={styles.messageOverlayBodyTextContainer}>
              <p style={{ marginTop: 5, textAlign: "center" }}>
                {messageOverlayText}
              </p>
            </div>
            <div
              onClick={() => setMessageOverlayOpen(false)}
              style={styles.messageOverlayButtonTextContainer}
            >
              <p style={styles.messageOverlayButtonText}>{"OK"}</p>
            </div>
          </div>
        </div>
      )}
      {(!isMobile && (
        <div style={styles.topBarContainer}>
          <div style={styles.leftPanelContainer}>
            <img src={Hixenis} alt="Logo" style={styles.hixenisLogo} />
          </div>
          <div style={styles.middlePanelContainer}>
            <h2 style={styles.screenTitle}>Client Dashboard</h2>
            <p style={{ color: "white" }}>
              {`Welcome back, ${user ? user.firstName : "blank"}!`}
            </p>
          </div>

          <div style={styles.logoutButtonContainer}>
            <div
              onClick={() => handleLogout(navigate)}
              style={styles.logoutButtonInnerContainer}
            >
              <p className="bolden-gate" style={{ color: "white" }}>
                Log out
              </p>
            </div>
          </div>
        </div>
      )) || (
        <div style={styles.topBarContainerMobile}>
          <div style={styles.topBarImageContainer}>
            <img src={Hixenis} alt="Logo" style={styles.hixenisLogoMobile} />
            <div style={styles.screenTitleMobile}>
              <h2 style={styles.screenTitleTextMobile}>Client Dashboard</h2>
              <p style={{ color: "white", lineHeight: 0 }}>
                {`Welcome back, ${user ? user.firstName : "blunk"}!`}
              </p>
            </div>
          </div>
        </div>
      )}

      <div style={styles.navbarContainer}>
        <DashboardNavBar
          handleMoveToScreen={(screen) => handleMoveToScreen(screen, navigate)}
          theme={"dark"}
          handleToggleTheme={() => alert()}
          handleGoToLoginScreen={() => handleLogout(navigate)}
        />

        {emailVerificationRequired && (
          <div style={styles.emailVerificationAlertContainer}>
            <div style={styles.emailVerificationAlertContainer2}>
              <p>Your email address is not yet verified.</p>
              <div
                onClick={() => handleResendVerificationEmail()}
                style={{ cursor: "pointer" }}
              >
                <p className="bolden-gate" style={{ color: "yellow" }}>
                  &nbsp;Resend verification email
                </p>
              </div>
            </div>
          </div>
        )}

        {emailVerificationSending && (
          <div style={styles.emailVerificationAlertSendingContainer}>
            <div style={styles.emailVerificationAlertSendingInnerContainer}>
              <p>Your email address is not yet verified.</p>
              <div>
                <p className="bolden-gate" style={{ color: "yellow" }}>
                  &nbsp;Sending...
                </p>
              </div>
            </div>
          </div>
        )}

        {emailVerificationSent && (
          <div style={styles.emailVerificationAlertSentContainer}>
            <div style={styles.emailVerificationAlertSentInnerContainer}>
              <p>Your email address is not yet verified.</p>
              <div>
                <p className="bolden-gate" style={{ color: "yellow" }}>
                  &nbsp;Verification email sent
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
      <div style={styles.clientDashboardMainContainer}>
        {(!isMobile && (
          <div
            className="frosted-glass-container-blur-plus-plus"
            style={styles.frostedGlassContainer}
          >
            <div style={styles.subscriptionsMainContainer}>
              <div style={styles.subscriptionsInnerContainer}>
                <div style={styles.subscriptionsLeftPanel}>
                  {/*  */}
                  <div style={styles.subscriptionsTitleContainer}>
                    <div style={styles.subscriptionsTitleContainer2}>
                      <div style={styles.subscriptionsTitleContainer3}>
                        <h2
                          style={styles.subscriptionsTitle}
                          className="bolden-gate"
                        >
                          Subscriptions
                        </h2>
                      </div>
                      <div style={styles.subscriptionsDivider}></div>
                      <div style={styles.subscriptionsDivider}></div>
                    </div>
                  </div>
                  <div style={styles.subscriptionsListContainer}>
                    {(!subscriptionsLoading &&
                      ((subscriptions.length > 0 &&
                        subscriptions.map((subscription, index) => (
                          <SubscriptionListItem
                            style={{}}
                            setSelectedSubscription={(value) =>
                              setSelectedSubscription(value)
                            }
                            subscription={subscription}
                            selectedSubscription={selectedSubscription}
                            isMobile={isMobile}
                          />
                        ))) ||
                        `You currently aren't subscribed to any products or services.`)) || (
                      <div>{`Loading...`}</div>
                    )}
                  </div>
                </div>
                <div style={{ width: ".3%" }} />
                <SubscriptionSpotlight
                  selectedSubscription={selectedSubscription}
                  handleCancelSubscription={(value) =>
                    handleCancelSubscription(value)
                  }
                  handleReactivateSubscription={(value) =>
                    handleReactivateSubscription(value)
                  }
                />
              </div>
            </div>

            <div style={{ width: 25 }} />

            <div style={{ flexDirection: "column", width: "100%" }}>
              <div style={styles.storeMain}>
                <div style={styles.storeTitleMain}>
                  <div style={styles.storeTitleInner}>
                    <div style={styles.storeTitleDivider}>
                      <h2 style={styles.storeHeading}>HIXENIS STORE</h2>
                    </div>
                  </div>
                </div>
                <div style={styles.storeSecondaryContainerMain}>
                  <div style={styles.storeLeftPanel}>
                    <div style={styles.storeLeftPanelInner}>
                      <div style={styles.storeLeftPanelTitle}>
                        <div style={styles.storeLeftPanelTitleContainer}>
                          <h2
                            style={styles.storeLeftPanelHeader}
                            className="bolden-gate"
                          >
                            Products & Services
                          </h2>
                        </div>
                      </div>
                    </div>
                    <div style={styles.productsListContainerMain}>
                      {(!productsLoading &&
                        ((products.length > 0 &&
                          products.map((product, index) => (
                            <ProductListItem
                              setSelectedProduct={(value) =>
                                setSelectedProduct(value)
                              }
                              product={product}
                              selectedProduct={selectedProduct}
                              subscriptions={subscriptions}
                            />
                          ))) ||
                          `HIXENIS products are currently unavailable.`)) || (
                        <div>{`Loading...`}</div>
                      )}
                    </div>
                  </div>

                  <div style={{ width: ".3%" }} />

                  <ProductSpotlight
                    selectedSubscription={selectedSubscription}
                    selectedProduct={selectedProduct}
                    handleCheckoutProduct={(value) =>
                      handleCheckoutProduct(value)
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        )) || (
          <div style={styles.mobileContainerMain}>
            <div style={styles.mobileContainerInner}>
              <div style={styles.mobileSubsInnerContainer}>
                <div style={styles.mobileSubsTitleContainer}>
                  <div style={styles.mobileSubsTitleInner}>
                    <div style={styles.mobileSubsDivider}>
                      <h2
                        style={styles.mobileSubsTitleText}
                        className="bolden-gate"
                      >
                        Subscriptions
                      </h2>
                    </div>
                    <div
                      style={{ borderBottom: "1px solid #34227c", flex: 1 }}
                    ></div>
                    <div
                      style={{ borderBottom: "1px solid #34227c", flex: 1 }}
                    ></div>
                  </div>
                </div>
                <div style={styles.mobileSubsList}>
                  {(!subscriptionsLoading &&
                    ((subscriptions.length > 0 &&
                      subscriptions.map((subscription, index) => (
                        <SubscriptionListItemMobile
                          style={{}}
                          setSelectedSubscription={(value) =>
                            setSelectedSubscription(value)
                          }
                          subscription={subscription}
                          selectedSubscription={selectedSubscription}
                          isMobile={isMobile}
                        />
                      ))) ||
                      `You currently aren't subscribed to any products or services.`)) || (
                    <div>{`Loading...`}</div>
                  )}
                </div>
              </div>
            </div>

            <div style={{ width: 25 }} />

            <div style={styles.mobileStoreMain}>
              <div style={styles.mobileStoreInner}>
                {/*  */}
                <div style={styles.mobileStoreTitleContainer}>
                  <div style={styles.mobileStoreTitleInner}>
                    <div style={styles.mobileStoreTitleDivider}>
                      <h2 style={styles.mobileStoreTitleText}>HIXENIS STORE</h2>
                    </div>
                  </div>
                </div>
                <div style={styles.mobileStoreProducts}>
                  {(!productsLoading &&
                    ((products.length > 0 &&
                      products.map((product, index) => (
                        <ProductListItemMobile
                          handleCheckoutProduct={(value) =>
                            handleCheckoutProduct(value)
                          }
                          product={product}
                          subscriptions={subscriptions}
                        />
                      ))) ||
                      `You currently aren't subscribed to any products or services.`)) || (
                    <div>{`Loading...`}</div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ClientDashboard;
