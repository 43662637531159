import { AnimalInterface } from "../Interfaces/AnimalInterface";

export default class Animal implements AnimalInterface {
  constructor(
    public name: string,
    public species: string,
    public sex: string,
    public weight: number,
    public age: number = 0, // Default age if not provided
    public tailSize: number
  ) {};

  identifySelf(): void {
    console.log(
      `Hi, I'm ${this.name}, a ${this.species}, and I'm a ${this.sex}. I am ${this.age} years old! My tail is ${this.tailSize} inches long!`
    );
  };

  updateAge(age: number): void {
    this.age = age;
    console.log(`The age of ${this.name} has been updated to ${this.age}.`);
  };
}
