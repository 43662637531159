import React, { useState, useEffect } from "react";
import "../App.css";
import AnimatedTextButton from "./AnimatedTextButton";
import { FaBars } from "react-icons/fa";
import { FaArrowLeft } from "react-icons/fa";

function DashboardNavBar({
  handleToggleTheme,
  theme,
  handleMoveToComponent,
  handleGoToLoginScreen,
}) {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 900); // Adjust the threshold as needed
  const handleResize = () => {
    const isCurrentlyMobile = window.innerWidth < 900;
    setIsMobile(isCurrentlyMobile);

    // Close drawer if currently open and resized to desktop
    if (drawerOpen && !isCurrentlyMobile) {
      setDrawerOpen(false);
    }
  };

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [drawerOpen]);

  const handleLoginPress = () => {
    toggleDrawer();
    handleGoToLoginScreen();
  };

  return (
    <div className="dashboard-navbar-container" >
      {/* Drawer (slides from top and only takes required height) */}
      <div className={`drawer ${drawerOpen ? "open" : ""}`}>
        <div className="drawer-header">
          {/* Close Button */}
          <button
            style={{ marginLeft: 20 }}
            className="close-button"
            onClick={toggleDrawer}
          >
            <FaArrowLeft size={30} />
          </button>
        </div>
        <div className="drawer-content">
          <AnimatedTextButton
            text={"Account"}
            onClick={() => handleMoveToComponent(0)}
            theme={theme}
          />
          <AnimatedTextButton
            text={"Support"}
            onClick={() => handleMoveToComponent(1)}
            theme={theme}
          />
          <AnimatedTextButton
            text={"Log out"}
            onClick={() => handleLoginPress()}
            theme={theme}
            style={{ background: "#6e6ef4" }}
            className={"bolden-gate"}
          />
        </div>
      </div>

      {/* Navbar content */}

      {/* className="navbar-content frosted-glass-container-blur-plus" */}
      {/* Conditionally render menu items based on isMobile state */}
      {!isMobile && (
        <>
          <div
            style={{
              border: "0px solid red",
              flexDirection: "row",
              width: "60%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <AnimatedTextButton
              text={"Account"}
              onClick={() => handleMoveToComponent(0)}
              theme={theme}
            />
            <AnimatedTextButton
              text={"Support"}
              onClick={() => handleMoveToComponent(1)}
              theme={theme}
            />
          </div>
        </>
      )}

      {/* Hamburger icon visible only on mobile */}
      {isMobile && (
        <div
          style={{
            border: "0px solid red",
            flexDirection: "row",
            width: "85%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <button
            style={{ color: "white", marginRight: "auto", fontSize: 30 }}
            onClick={toggleDrawer}
          >
            {<FaBars />}
          </button>
        </div>
      )}
      {/* {isMobile && (
          <div style={{width: '100%', marginRight: 'auto', display: 'flex', flexDirection: 'row'}} >
            <div style={{width: '50%', border: '0px solid white'}}>
              <button style={{ color: 'white', fontSize: 30 }}  onClick={toggleDrawer}>
                {<FaBars />}
              </button>
            </div>
            <div style={{width: '50%', border: '0px solid white', display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
              <h3 style={{ marginLeft: 'auto'}}>
                JORDAN&nbsp;
              </h3>
              <h3 className="bolden-gate" style={{ color: '#6e6ef4' }}>
                JANCIC
              </h3>
            </div>
          
          </div>
        )} */}
    </div>
  );
}

export default DashboardNavBar;
