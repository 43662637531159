import axios from "axios";

export const loginWithEmailAndPassword = (formData) => {
  return axios
    .post("https://api.jordanjancic.com/api/loginWithEmailAndPassword", formData) // Using POST to send form data
    .then((response) => response.data)
    .catch((error) => {
      console.error("Error submitting form:", error);
      throw error;
    });
};

// Fetch news articles with promise
export const fetchNewsArticles = () => {
  return axios
    .get("https://api.jordanjancic.com/api/getNewsArticles") // Hardcoding to test
    .then((response) => response.data)
    .catch((error) => {
      console.error("Error fetching news articles:", error);
      throw error;
    });
};

export const fetchPortfolioProjects = () => {
  return axios
    .get("https://api.jordanjancic.com/api/getPortfolioProjects") // Hardcoding to test
    .then((response) => response.data)
    .catch((error) => {
      console.error("Error fetching portfilio projects:", error);
      throw error;
    });
};

export const submitContactForm = (formData) => {
  return axios
    .post("https://api.jordanjancic.com/api/submitContactForm", formData) // Using POST to send form data
    .then((response) => response.data)
    .catch((error) => {
      console.error("Error submitting form:", error);
      throw error;
    });
};
