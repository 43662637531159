// JJ-108
import React, { useState, useEffect } from "react";
import "../App.css";
import { useLocation, useNavigate } from "react-router-dom";
// import Hixenis from "../images/HIXENIS_NEW_W_P.png";
import Hixenis from "../images/HIXENIS_NEW_W_P_SIMPLE.png";
import { FaArrowLeft } from "react-icons/fa";
import BounceLoader from "react-spinners/BounceLoader";
import { changeUserPassword, updateUserAccount } from "../api/dataService";
import PasswordChangeWindow from "../components/PasswordChangeWindow";
import { useUser } from "../stores/UserContext";

function ProjectDetailScreen() {

  // Uses React Context to get stored user data.
  const { user, updateUser } = useUser(); 

  const location = useLocation();
  const props = location.state || {}; 
  const userData = props.userData;
  const [isMobile, setIsMobile] = useState(window.innerWidth < 900); 
  const [loading, setLoadingState] = useState(false);
  const [loadingStateMessage, setLoadingStateMessage] = useState("");

  const handleResize = () => {
    const isCurrentlyMobile = window.innerWidth < 900;
    setIsMobile(isCurrentlyMobile);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const navigate = useNavigate();

  let styles = {
    container: {
      backgroundColor: "#090714",
      width: "100%",
      color: "white",
      display: "flex",
      flexDirection: "column",
    },
    loadingOverlayPrimaryContainer: {
      position: "absolute",
      zIndex: 12000,
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      width: "100%",
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      transition: "opacity 1s ease-in-out",
    },
    loadingOverlaySecondaryContainer: {
      padding: 30,
      backgroundColor: "rgba(44, 41, 63, 1)",
      borderRadius: 10,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      color: "white",
      boxShadow: "0 0px 10px rgba(120, 0, 255, 1)",
      border: "1px solid #6E6EF4",
    },
    messageOverlayPrimaryContainer: {
      position: "absolute",
      zIndex: 12000,
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      width: "100%",
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      transition: "opacity 1s ease-in-out",
    },
    messageOverlaySecondaryContainer: {
      width: 350,
      backgroundColor: "#191632",
      borderRadius: 10,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      color: "white",
      boxShadow: "0 0px 15px rgba(0, 0, 0, .8)",
      border: "1px solid #6E6EF4",
      overflow: "hidden",
    },
    messageOverlayTertiaryContainer: {
      borderBottom: "0px solid #6E6EF4",
      width: "90%",
      flex: 1,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      paddingTop: 5,
    },
    messageOverlayTitleText: {
      letterSpacing: 2,
      marginTop: 5,
      fontSize: 18,
    },
    messageOverlayBodyText: {
      border: "0px solid red",
      width: "100%",
      flex: 1,
      display: "flex",
      justifyContent: "center",
      padding: 16,
      paddingTop: 0,
    },
    messageOverlayButton: {
      cursor: "pointer",
      borderTop: "1px solid #6E6EF4",
      width: "100%",
      flex: 1,
      display: "flex",
      justifyContent: "center",
      padding: 16,
    },
    alertDialogContainer1: {
      position: "absolute",
      zIndex: 12000,
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      width: "100%",
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      transition: "opacity 1s ease-in-out",
    },
    alertDialogContainer2: {
      width: 350,
      backgroundColor: "#191632",
      borderRadius: 10,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      color: "white",
      boxShadow: "0 0px 15px rgba(0, 0, 0, .8)",
      border: "1px solid #6E6EF4",
      overflow: "hidden",
    },
    alertDialogContainer3: {
      borderBottom: "0px solid #6E6EF4",
      width: "90%",
      flex: 1,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      paddingTop: 5,
    },
    alertDialogTitleText: {
      letterSpacing: 2,
      marginTop: 5,
      fontSize: 18,
      color: "white",
    },
    alertDialogBodyText: {
      border: "0px solid red",
      width: "100%",
      flex: 1,
      display: "flex",
      justifyContent: "center",
      padding: 16,
      paddingTop: 0,
    },
    alertDialogButtonContainer1: {
      cursor: "pointer",
      borderTop: "1px solid #6E6EF4",
      width: "100%",
      flex: 1,
      display: "flex",
      justifyContent: "center",
      padding: 16,
    },
    alertDialogOKText: {
      fontSize: 16,
      marginTop: 5,
      textAlign: "center",
      color: "white",
    },
    headerContainer: {
      width: "100%",
      height: 175,
      border: "0px solid red",
      display: "flex",
      flexDirection: "row",
    },
    leftHeaderPanel: {
      width: "30%",
      height: "100%",
      border: "0px solid blue",
      height: "100%",
      padding: 30,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
    },
    hixenisLogoStyling: {
      maxWidth: 250,
      maxHeight: "100%",
      objectFit: "contain",
    },
    middlePanelHeader: {
      display: "flex",
      width: "40%",
      border: "0px solid green",
      height: "100%",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
    },
    screenTitle: {
      color: "#7484F9",
      fontSize: 22,
      paddingBottom: 10,
    },
    rightPanelHeader: {
      width: "30%",
      border: "0px solid yellow",
      height: "100%",
      padding: 30,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    mainContainerMobile: {
      width: "100%",
      height: 175,
      border: "0px solid red",
    },
    loadingOverlayContainer1: {
      position: "absolute",
      zIndex: 12000,
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      width: "100%",
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      transition: "opacity 1s ease-in-out",
    },
    loadingOverlayContainer2: {
      padding: 30,
      backgroundColor: "rgba(44, 41, 63, 1)",
      borderRadius: 10,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      color: "white",
      boxShadow: "0 0px 10px rgba(120, 0, 255, 1)",
      border: "1px solid #6E6EF4",
    },
    messageOverlayContainerMobile1: {
      position: "absolute",
      zIndex: 12000,
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      width: "100%",
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      transition: "opacity 1s ease-in-out",
    },
    messageOverlayContainerMobile2: {
      width: 350,
      backgroundColor: "#191632",
      borderRadius: 10,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      color: "white",
      boxShadow: "0 0px 15px rgba(0, 0, 0, .8)",
      border: "1px solid #6E6EF4",
      overflow: "hidden",
    },
    messageOverlayContainerMobile3: {
      borderBottom: "0px solid #6E6EF4",
      width: "90%",
      flex: 1,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      paddingTop: 5,
    },
    messageOverlayTitleTextMobile: {
      letterSpacing: 2,
      marginTop: 5,
      fontSize: 18,
    },
    messageOverlayBodyTextMobile: {
      border: "0px solid red",
      width: "100%",
      flex: 1,
      display: "flex",
      justifyContent: "center",
      padding: 16,
      paddingTop: 0,
    },
    messageOverlayButtonContainerMobile: {
      cursor: "pointer",
      borderTop: "1px solid #6E6EF4",
      width: "100%",
      flex: 1,
      display: "flex",
      justifyContent: "center",
      padding: 16,
    },
    actionDialogContainerMain: {
      position: "absolute",
      zIndex: 12000,
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      width: "100%",
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      transition: "opacity 1s ease-in-out",
    },
    actionDialogContainer2: {
      width: 350,
      backgroundColor: "#191632",
      borderRadius: 10,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      color: "white",
      boxShadow: "0 0px 15px rgba(0, 0, 0, .8)",
      border: "1px solid #6E6EF4",
      overflow: "hidden",
    },
    actionDialogContainer3: {
      borderBottom: "0px solid #6E6EF4",
      width: "90%",
      flex: 1,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      paddingTop: 5,
    },
    actionDialogTitleTextMobile: {
      letterSpacing: 2,
      marginTop: 5,
      fontSize: 18,
      color: "white",
    },
    actionDialogBodyTextMobile: {
      border: "0px solid red",
      width: "100%",
      flex: 1,
      display: "flex",
      justifyContent: "center",
      padding: 16,
      paddingTop: 0,
    },
    actionDialogButtonContainerMainMobile: {
      cursor: "pointer",
      borderTop: "1px solid #6E6EF4",
      width: "100%",
      flex: 1,
      display: "flex",
      justifyContent: "center",
      padding: 16,
    },
    actionDialogOKButtonMobile: {
      fontSize: 16,
      marginTop: 5,
      textAlign: "center",
      color: "white",
    },
    containerMobile1: {
      width: "100%",
      height: "100%",
      border: "0px solid blue",
      height: "100%",
      padding: 30,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
    },
    hixenisLogoMobile: {
      maxWidth: 400,
      maxHeight: "100%",
      objectFit: "contain",
    },
    containerMobile2: {
      display: "flex",
      border: "0px solid green",
      height: "100%",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
    },
    screenTitleMobile: {
      color: "#7484F9",
      fontSize: 22,
      paddingBottom: 10,
    },
    backButtonContainerMobile: {
      width: "100%",
      height: 50,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "flex-start",
      paddingLeft: 25,
      marginTop: 15,
    },
    backButtonContainerMobile2: {
      cursor: "pointer",
      flexDirection: "row",
      display: "flex",
      alignItems: "center",
      height: "100%",
    },
    bodyContainerMain: {
      width: "100%",
      height: "100vh",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: "20px",
      boxSizing: "border-box",
    },
    inputContainer1: {
      width: "100%",
      maxWidth: "400px",
    },
    inputLabel: {
      display: "block",
      marginBottom: "10px",
      color: "#6160BA",
    },
    inputNameFirst: {
      width: "100%",
      padding: "8px",
      marginTop: "5px",
      marginBottom: "15px",
      border: "1px solid #ccc",
      borderRadius: "4px",
      fontSize: "16px",
      color: "black",
    },
    inputNameLast: {
      width: "100%",
      padding: "8px",
      marginTop: "5px",
      marginBottom: "15px",
      border: "1px solid #ccc",
      borderRadius: "4px",
      fontSize: "16px",
      color: "black",
    },
    inputNameEmail: {
      width: "100%",
      padding: "8px",
      marginTop: "5px",
      marginBottom: "15px",
      border: "1px solid #ccc",
      borderRadius: "4px",
      fontSize: "16px",
      color: "black",
    },
    saveChangesButton: {
      width: "100%",
      padding: "10px",
      marginTop: "10px",
      backgroundColor: "#6E6EF4",
      color: "#fff",
      border: "none",
      borderRadius: "4px",
      cursor: "pointer",
      fontSize: "16px",
    },
    changePasswordButton: {
      width: "100%",
      padding: "10px",
      marginTop: "10px",
      backgroundColor: "#6c757d",
      color: "#fff",
      border: "none",
      borderRadius: "4px",
      cursor: "pointer",
      fontSize: "16px",
    },
  };

  return (
    <div style={styles.container}>
      {(!isMobile && (
        <div>
          {loading && (
            <div style={styles.loadingOverlayPrimaryContainer}>
              <div style={styles.loadingOverlaySecondaryContainer}>
                <BounceLoader
                  color={"#717DFA"}
                  size={50}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                  speedMultiplier={1}
                />
                <p style={{ marginTop: 5 }}>{loadingStateMessage}</p>
              </div>
            </div>
          )}


       
        </div>
      )) || (
        <div style={styles.mainContainerMobile}>
          {loading && (
            <div style={styles.loadingOverlayContainer1}>
              <div style={styles.loadingOverlayContainer2}>
                <BounceLoader
                  color={"#717DFA"}
                  size={50}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                  speedMultiplier={1}
                />
                <p style={{ marginTop: 5 }}>{loadingStateMessage}</p>
              </div>
            </div>
          )}
        </div>
    
      )}
      <div style={styles.backButtonContainerMobile}>
        <div
          onClick={() => navigate(-1)}
          style={styles.backButtonContainerMobile2}
        >
          <FaArrowLeft color={"#A6A6FC"} size={20} />
          <p style={{ marginLeft: 15, color: "#6E6EF4", lineHeight: 1.3 }}>
            {"Back to Dashboard"}
          </p>
        </div>
      </div>

    </div>
  );
}

export default ProjectDetailScreen;
