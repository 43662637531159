// JJ-108
import "../App.css";
import React, { useState, useEffect } from "react";

function AnimatedTextButton({ onClick, theme, text, style, className, onMouseOver, onMouseOut, isMobile }) {

  const [bold, setBold] = useState(false);  

  const handleClick = () => {
    onClick();
  };

  return (
    <div
      onMouseOver={() => setBold(true)}
      onMouseOut={() =>  setBold(false)}
      className={bold ? 'bolden-gate' : ''}
      onClick={handleClick}
      style={{
        ...style,
        height: 45,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        borderRadius: 5,
        paddingRight: 15,
        paddingLeft: 15,
        width: '60%',
        alignSelf: 'center',
        flexDirection: 'column'
      }}
    >
      <div
        style={{
          userSelect: 'none',
          color: theme === 'dark' ? '#ffffff' : '#5a5ac9',
          fontSize: 18,
          width: '80%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center'
        }}
      >
        {text}
      </div>
        {/* {!isMobile && (
          servicesPanelOpen && (
                <div style={{border: '0px solid red', padding: 30, width: '100%', backgroundColor: '#3a3a87', display: 'flex', flexDirection: 'column', position: 'absolute'}}>
                
                  <h2 className="bolden-gate">Consulting</h2>
                  
                </div>
              )
        )} */}
    </div>
  );
}

export default AnimatedTextButton;
