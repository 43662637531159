// JJ-108
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

function SupportScreen() {

  const [enabled, setEnabled] = useState<boolean>(true);
  const [statusButtonIsHovered, setStatusButtonIsHovered] = useState<boolean>(false);

  return (
    <div className="bolden-gate" style={styles.container}>
      <p style={styles.titleText}>{`Welcome to the Support dashboard!`}</p>
      <p style={styles.body}>{`Where all your dreams come true!`}</p>
      <div style={styles.statusWrap}>
        <p style={styles.statusLabel}>{`CURRENT STATUS:`}</p>
        <p style={enabled ? styles.enabled : styles.disabled}>
          {` ${enabled}`.toUpperCase()}
        </p>
      </div>
      <div
        onClick={() => setEnabled(enabled ? false : true)}
        onMouseOver={() => setStatusButtonIsHovered(true)}
        onMouseOut={() => setStatusButtonIsHovered(false)}
        style={
          statusButtonIsHovered ? styles.statusButtonHover : styles.statusButton
        }
      >
        <p style={styles.statusButtonText}>{`Click here to change the status!`}</p>
      </div>
    </div>
  );
}

const styles: Record<string, React.CSSProperties> = {
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: 56,
    backgroundColor: "white",
    width: "100%",
    minHeight: "100vh",
    color: "blue",
    flexDirection: "column",
    border: "1px solid red",
  },
  statusButtonText: {
    // color: "white",
    fontSize: 26,
    textAlign: 'center',
    
  },
  statusButton: {
    width: 400,
    height: 200,
    backgroundColor: "blue",
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
    cursor: 'pointer',
    padding: 10,
    borderRadius: 30,
    userSelect: "none", // Prevents text selection
  },
  statusButtonHover: {
    width: 400,
    height: 200,
    backgroundColor: "yellow",
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'black',
    cursor: 'pointer',
    padding: 10,
    borderRadius: 30,
    userSelect: "none", // Prevents text selection
  },
  titleText: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    // border: '1px solid red',
    textAlign: "center",
    lineHeight: 1.1,
  },
  statusLabel: {
    color: "DimGray",
    fontSize: 26,
    marginRight: 5,
  },
  body: {
    color: "black",
    fontSize: 26,
    // textAlign: 'center'
  },
  enabled: {
    color: "green",
    fontSize: 26,
  },
  disabled: {
    color: "red",
    fontSize: 26,
  },
  statusWrap: {
    display: "flex",
    flexDirection: "row",
  },
};
export default SupportScreen;
