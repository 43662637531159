// JJ-108
import React, { useState, useEffect } from "react";
import "../App.css";
import AnimatedTextButton from "./AnimatedTextButton";
import { FaBars } from "react-icons/fa";
import { FaArrowLeft } from "react-icons/fa";
import { StyleSharp } from "@mui/icons-material";

function NavBar({
  handleToggleTheme,
  theme,
  handleMoveToComponent,
  handleGoToLoginScreen,
  handleGoToRegisterScreen,
}) {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768); // Adjust the threshold as needed

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleResize = () => {
    const isCurrentlyMobile = window.innerWidth < 768;
    if(window.innerHeight > 500 && isCurrentlyMobile === true) {
      setIsMobile(isCurrentlyMobile);
    }
    else {
      setIsMobile(false);
    }

    if (drawerOpen && !isCurrentlyMobile) {
      setDrawerOpen(false);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [drawerOpen]);

  const handleLoginPress = () => {
    toggleDrawer();
    handleGoToLoginScreen();
  };
  const handleRegisterPress = () => {
    toggleDrawer();
    handleGoToRegisterScreen();
  };

  let styles = {
    desktopContainer: {
      border: "0px solid red",
      flexDirection: "row",
      width: "60%",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    mobileContainer: {
      border: "0px solid red",
      flexDirection: "row",
      width: "85%",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    hamburgerContainer: { color: "white", marginRight: "auto", fontSize: 30 },
  };

  return (
    <div className="navbar-container">
      {window.innerHeight > 500 && (
      <div className={`drawer ${drawerOpen ? "open" : ""}`}>
        
          <div className="drawer-header">
          <button
            style={{ marginLeft: 20 }}
            className="close-button"
            onClick={toggleDrawer}
          >
            <FaArrowLeft size={30} />
          </button>
        </div>
        
        
        {window.innerHeight > 500 && (
          <div className="drawer-content">
            <AnimatedTextButton
              text={"Home"}
              onClick={() => handleMoveToComponent(0)}
              theme={theme}
            />
            <AnimatedTextButton
              text={"Projects"}
              onClick={() => handleMoveToComponent(1)}
              theme={theme}
            />
            <AnimatedTextButton
              text={"About Me"}
              onClick={() => handleMoveToComponent(2)}
              theme={theme}
            />

            <AnimatedTextButton
              text={"Contact"}
              onClick={() => handleMoveToComponent(3)}
              theme={theme}
            />
            <AnimatedTextButton
              text={"Register"}
              onClick={() => handleRegisterPress()}
              theme={theme}
              style={{ border: "1px solid #6e6ef4" }}
            />
            <AnimatedTextButton
              text={"Log in"}
              onClick={() => handleLoginPress()}
              theme={theme}
              style={{ background: "#6e6ef4" }}
              className={"bolden-gate"}
            />
          </div>
        )}
        
      </div>
    )}
      {!isMobile && (
        <>
          <div style={styles.desktopContainer}>
            <AnimatedTextButton
              text={"Home"}
              onClick={() => handleMoveToComponent(0)}
              theme={theme}
            />
            <AnimatedTextButton
              text={"Projects"}
              onClick={() => handleMoveToComponent(1)}
              theme={theme}
            />
            <AnimatedTextButton
              text={"About Me"}
              onClick={() => handleMoveToComponent(2)}
              theme={theme}
            />

            <AnimatedTextButton
              text={"Contact"}
              onClick={() => handleMoveToComponent(3)}
              theme={theme}
            />
          </div>
        </>
      )}

      {isMobile && window.innerHeight > 500 &&  (
        <div style={styles.mobileContainer}>
          <button style={styles.hamburgerContainer} onClick={toggleDrawer}>
            {<FaBars />}
          </button>
        </div>
      )}
    </div>
  );
}

export default NavBar;
