// JJ-108
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import LoginWindow from "../components/LoginWindow";

function LoginScreen() {
  const location = useLocation();
  const props = location.state;
  const [isMobile, setIsMobile] = useState(props.isMobile);

  const closeLoginWindow = () => {
    navigate(-1);
  };

  const handleOpenAdminDashboard = (navigate) => {
    navigate("/AdminDashboard", { state: { data: null } });
  };
  const handleOpenClientDashboard = (navigate) => {
    navigate("/ClientDashboard", { state: { data: null } });
  };
  const handleGoToPasswordResetScreen = (navigate) => {
    //
    navigate("/PasswordResetScreen", { state: { data: null } });
  };

  const navigate = useNavigate();

  return (
    <div style={styles.container}>
      {/* <div style={styles.coloredBackground} /> */}
        <LoginWindow
          style={{}}
          handleOpenAdminDashboard={() =>
            handleOpenAdminDashboard(navigate)
          }
          handleOpenClientDashboard={() =>
            handleOpenClientDashboard(navigate)
          }
          handleGoToPasswordResetScreen={() => handleGoToPasswordResetScreen(navigate)}
          isMobile={isMobile}
          windowHeight={window.innerHeight}
          closeWindow={() => closeLoginWindow()}
        />
    </div>
  );
}
let styles = {
  coloredBackground: {
    backgroundColor: "#090714",
    width: "100%",
    height: "100vh",
    position: "absolute",
  },
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    // height: "100vh",
    color: "white",
    flexDirection: 'column',
    backgroundColor: "#090714",
  },
};
export default LoginScreen;
