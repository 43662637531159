import "../App.css";
import React from "react";
import moment from 'moment';

function SubscriptionListItem({ setSelectedSubscription, subscription, selectedSubscription, isMobile }) {

  let styles = {
    subscriptionListItemContainerMain: {
      cursor: "pointer",
      userSelect: "none",
      textAlign: "center",
      width: "100%",
      display: "flex",
      justifyContent: "center",
      backgroundColor: "#281e51",
      borderRadius: 5,
      flexDirection: "column",
      marginTop: 5,
      marginBottom: "auto",
    },
    subscriptionListItemInnerContainer: {
      width: "100%",
      flexDirection: "row",
      display: "flex",
    },
    subscriptionListItemInnerContainer2: {
      width: "25%",
      border: "0px solid yellow",
      padding: 10,
    },
    subscriptionThumbnail: {
      maxHeight: "100%",
      objectFit: "contain",
      borderRadius: 5,
      border: "2px solid #3C4684",
    },
    subscriptionInfoMain: {
      border: "0px solid white",
      height: "100%",
      width: "75%",
      display: "flex",
      flexDirection: "column",
      marginBottom: "auto",
      maxHeight: 200,
      alignItems: "flex-end",
    },
    subscriptionTitle: {
      width: "100%",
      display: "flex",
      border: "0px solid yellow",
      flexDirection: "row",
      paddingTop: 5,
    },
    subscriptionInnerTitle: {
      width: "80%",
      border: "0px solid yellow",
      marginTop: 7,
      display: "flex",
      flexDirection: "row",
    },
    subscriptionTitleText: {
      lineHeight: 1.3,
      paddingBottom: 0,
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      color: "white",
      fontSize: 22,
    },
    subscriptionTitleSpacer: {
      lineHeight: 1,
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      color: "white",
      fontSize: 22,
    },
    subscriptionPlanContainerMain: {
      marginTop: 3,
    },
    subscriptionPlanText: {
      lineHeight: 1.3,
      padding: 3,
      paddingBottom: 2,
      borderRadius: 8,
      paddingLeft: 7,
      paddingRight: 7,
      border: "1px solid white",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      color: "white",
      fontSize: 10,
      marginTop: "auto",
    },
    subscriptionStatus: {
      width: "15%",
      alignItems: "center",
      justifyContent: "center",
      border: "0px solid white",
      display: "flex",
      flexDirection: "column",
    },
    subscriptionStatusInnerContainer: {
      width: 15,
      height: 15,
      borderRadius: 30,
      marginTop: "auto",
      border: "2px solid white",
    },
    subscriptionStatusText: {
      fontSize: 10,
      color: "white",
    },
    subscriptionURLContainer: {
      width: "100%",
      display: "flex",
      border: "0px solid green",
      flexDirection: "row",
    },
    subscriptionURLInnerContainer: {
      width: "100%",
      paddingLeft: 0,
      paddingRight: 20,
      border: "0px solid yellow",
      display: "flex",
      flexDirection: "column",
    },
    subscriptionURLText: {
      textAlign: "left",
      fontSize: 16,
      display: "-webkit-box",
      WebkitLineClamp: 2,
      WebkitBoxOrient: "vertical",
      overflow: "hidden",
    },
    purchaseDateContainerMain: {
      width: "100%",
      marginBottom: 10,
      display: "flex",
      border: "0px solid red",
      flexDirection: "column",
    },
    purchaseDateInnerContainer: {
      width: "100%",
      paddingLeft: 0,
      paddingRight: 20,
      border: "0px solid yellow",
      display: "flex",
      flexDirection: "column",
    },
    purchaseDate: {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      textAlign: "left",
      color: "#C6C6F2",
      fontSize: 14,
    },
  };

  return (
    <div
      onClick={() => setSelectedSubscription(subscription)}
      style={{
        ...styles.subscriptionListItemContainerMain,
        boxShadow:
          selectedSubscription.id === subscription.id
            ? "0 0px 25px rgba(120, 0, 255, .4)"
            : "0 0px 15px rgba(0, 0, 0, .5)",
        border:
          selectedSubscription.id === subscription.id
            ? "1px solid #bcc5ff"
            : "1px solid #7484F9",
        backgroundColor:
          selectedSubscription.id === subscription.id
            ? "#342a60"
            : "#281E51",
      }}
    >
      <div style={styles.subscriptionListItemInnerContainer}>
        <div style={styles.subscriptionListItemInnerContainer2}>
          <img
            src={subscription.stripeProduct.iconThumbnail.url}
            alt={subscription.stripeProduct.iconThumbnail.alt}
            style={styles.subscriptionThumbnail}
          />
        </div>
        <div style={styles.subscriptionInfoMain}>
          <div style={styles.subscriptionTitle}>
            <div style={styles.subscriptionInnerTitle}>
              <p
                className="bolden-gate"
                style={styles.subscriptionTitleText}
              >{`${subscription.stripeProduct.baseName}`}</p>
              <p className="bolden-gate" style={styles.subscriptionTitleSpacer}>
                &nbsp;
              </p>
              <div style={styles.subscriptionPlanContainerMain}>
                <p
                  style={{
                    ...styles.subscriptionPlanText,
                    backgroundColor: subscription.plan === "essentials"
                        ? "grey"
                        : subscription.plan === "pro"
                          ? "#6984ef"
                          : "goldenrod",
                  }}
                >{`${subscription.plan.toUpperCase()}`}</p>
              </div>
            </div>

            <div style={styles.subscriptionStatus}>
              <div
                style={{
                  ...styles.subscriptionStatusInnerContainer,
                  backgroundColor: subscription.active ? "#2AD640" : "red",
                }}
              />
              <p
                style={styles.subscriptionStatusText}
              >{`${subscription.active ? "ACTIVE" : "INACTIVE"}`}</p>
            </div>
            <div style={{ width: "5%" }} />
          </div>

          <div style={styles.subscriptionURLContainer}>
            <div style={styles.subscriptionURLInnerContainer}>
              <p
                className="bolden-gate"
                style={{
                  ...styles.subscriptionURLText,
                  color: subscription.clientWebsiteURL
                    ? subscription.active
                      ? "#2AD640"
                      : "red"
                    : "#FFA500",
                }}
              >
                {subscription.clientWebsiteURL
                  ? subscription.clientWebsiteURL
                  : isMobile
                    ? "Tap here to configure"
                    : "Click here to configure"}
              </p>
            </div>
          </div>

          {/* marginTop: 'auto',  */}
          <div style={styles.purchaseDateContainerMain}>
            <div style={styles.purchaseDateInnerContainer}>
              <p
                style={styles.purchaseDate}
              >{`Purchased: ${moment(subscription.purchaseDate).format("MMMM D yyyy")}`}</p>
              <p
                style={styles.purchaseDate}
              >{`Renews: ${moment(subscription.renewalDate).format("MMMM D yyyy")}`}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SubscriptionListItem;

// import "../App.css";
// import React from 'react';

// function SubscriptionListItem({ style, theme, onClick }) {

//   const handleClick = () => {
//     onClick();
//   };

//   let styles = {

//   };

//   return (
//     <div
//       onClick={handleClick}
//     >
//     </div>
//   );
// }

// export default SubscriptionListItem;
