import axios from "axios";

var domain = `api.${window.location.hostname.replace(/^www\./, '')}`;

if(domain === 'api.localhost') {
  domain = 'api.jordanjancic.com';
}

export function initialTest(incoming: number): string {
  var returnString = `The string is ${incoming}`;
  return returnString;
};

export function greet(name: string): string {
  return `Hello, ${name}!`;
};

export function add(a: number, b: number): number {
  return a + b;
};

export function isEven(num: number):boolean {
  return num % 2 === 0;
};

export function filterEvens(numbers: number[]): number[] {
  return numbers.filter(num => num % 2 === 0);
};

export async function fetchUserData(endpoint: string): Promise<any> {
  return axios
    .get(`https://${domain}/api/${endpoint}`) // Hardcoding to test
    .then((response) => response.data)
    .catch((error) => {
      console.error("Error fetching news articles:", error);
      throw error;
    });
};