import "../App.css";
import React from "react";
import moment from 'moment';

function SubscriptionSpotlight({selectedSubscription, handleCancelSubscription, handleReactivateSubscription}) {
  let styles = {
    selectedSubContainer: {
      display: "flex",
      borderRadius: 5,
      flexDirection: "column",
      width: "59.7%",
      backgroundColor: "#1c1538",
      border: "1px solid #7484F9",
      padding: 15,
    },
    selectedSubTitleContainer: { flexDirection: "column", width: "100%" },
    selectedSubTitleInnerContainer: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
    },
    selectedSubTitleDivider: { borderBottom: "1px solid #34227c", flex: 1 },
    selectedSubcriptionTitleOverlay: {
      color: "white",
      fontSize: 18,
      padding: 10,
      paddingTop: 0,
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      marginRight: "auto",
    },
    selectedSubscriptionContainerMain: {
      textAlign: "center",
      width: "100%",
      height: "100%",
      border: "0px solid white",
      alignItems: selectedSubscription ? "flex-start" : "center",
      display: "flex",
      justifyContent: "center",
    },
    selectedSubscriptionInnerContainer: {
      userSelect: "none",
      textAlign: "center",
      width: "100%",
      height: "100%",
      border: "1px solid #7484F9",
      display: "flex",
      justifyContent: "flex-start",
      backgroundColor: "#342a60",
      borderRadius: 5,
      flexDirection: "column",
      marginTop: 5,
      marginBottom: "auto",
    },
    selectedSubcriptionInnerContainer2: {
      width: "100%",
      flexDirection: "row",
      display: "flex",
    },
    selectedSubscriptionInnerContainer3: {
      width: "25%",
      border: "0px solid yellow",
      padding: 10,
    },
    selectedSubThumbnail: {
      maxHeight: "100%",
      objectFit: "contain",
      borderRadius: 5,
      border: "2px solid #3C4684",
    },
    selectedSubContentMain: {
      border: "0px solid white",
      height: "100%",
      width: "75%",
      display: "flex",
      flexDirection: "column",
      marginBottom: "auto",
      minHeight: 180,
      alignItems: "flex-end",
    },
    selectedSubContentTitle: {
      width: "100%",
      display: "flex",
      border: "0px solid yellow",
      flexDirection: "row",
    },
    selectedSubContentInnerTitle: {
      width: "80%",
      height: 50,
      border: "0px solid yellow",
      display: "flex",
      alignItems: "center",
      paddingLeft: 0,
      flexDirection: "row",
    },
    selectedSubContentTitleText: {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      color: "white",
      fontSize: 22,
      marginTop: "auto",
    },
    selectedSubStatus: {
      width: "15%",
      alignItems: "center",
      justifyContent: "center",
      height: 50,
      display: "flex",
      flexDirection: "column",
    },
    selectedSubStatusLight: {
      width: 15,
      height: 15,
      borderRadius: 30,
      marginTop: "auto",
      border: "2px solid white",
    },
    selectedSubDescription: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
    },
    selectedSubDescriptionInner: {
      width: "100%",
      paddingLeft: 0,
      paddingRight: 20,
      display: "flex",
      flexDirection: "column",
    },
    subscriptionPlanInfoMain: {
      width: "100%",
      marginTop: "auto",
      display: "flex",
      flexDirection: "column",
    },
    subscriptionPlanInfoInner: {
      width: "100%",
      paddingLeft: 0,
      paddingRight: 20,
      border: "0px solid yellow",
      display: "flex",
      flexDirection: "column",
    },
    subPlanInfo: {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      textAlign: "left",
      color: "white",
      fontSize: 14,
    },
    cancelSubMain: {
      display: "flex",
      justifyContent: "flex-start",
      marginLeft: "auto",
      marginRight: 5,
    },
    cancelSubInner: {
      padding: 7.5,
      cursor: "pointer",
      width: "100%",
      height: "100%",
      alignItems: "center",
      justifyContent: "center",
      display: "flex",
      backgroundColor: "red",
      borderRadius: 5,
    },
    reactivateSubMain: {
      padding: 7.5,
      cursor: "pointer",
      width: "100%",
      height: "100%",
      alignItems: "center",
      justifyContent: "center",
      display: "flex",
      backgroundColor: "#008000",
      borderRadius: 5,
    },
  };

  return (
    <div style={styles.selectedSubContainer}>
      <div style={styles.selectedSubTitleContainer}>
        <div style={styles.selectedSubTitleInnerContainer}>
          <div style={styles.selectedSubTitleDivider}>
            <h2
              style={styles.selectedSubcriptionTitleOverlay}
              className="bolden-gate"
            >
              {selectedSubscription
                ? selectedSubscription.stripeProduct.baseName
                : "Spotlight"}
            </h2>
          </div>
          <div style={{ borderBottom: "1px solid #34227c", flex: 1 }}></div>
          <div style={{ borderBottom: "1px solid #34227c", flex: 1 }}></div>
        </div>
      </div>
      <div style={styles.selectedSubscriptionContainerMain}>
        {(selectedSubscription && (
          <div style={styles.selectedSubscriptionInnerContainer}>
            <div style={styles.selectedSubcriptionInnerContainer2}>
              <div style={styles.selectedSubscriptionInnerContainer3}>
                <img
                  src={selectedSubscription.stripeProduct.iconThumbnail.url}
                  alt={selectedSubscription.stripeProduct.iconThumbnail.alt}
                  style={styles.selectedSubThumbnail}
                />
              </div>
              <div style={styles.selectedSubContentMain}>
                <div style={styles.selectedSubContentTitle}>
                  <div
                    className="bolden-gate"
                    style={styles.selectedSubContentInnerTitle}
                  >
                    <p style={styles.selectedSubContentTitleText}>
                      {selectedSubscription.stripeProduct.baseName}
                    </p>
                  </div>

                  <div style={styles.selectedSubStatus}>
                    <div
                      style={{
                        ...styles.selectedSubStatusLight,
                        backgroundColor: selectedSubscription.active
                          ? "#2AD640"
                          : "red",
                      }}
                    />
                    <p
                      style={{ fontSize: 10, color: "white" }}
                    >{`${selectedSubscription.active ? "ACTIVE" : "INACTIVE"}`}</p>
                  </div>
                  <div style={{ width: "5%" }} />
                </div>

                <div style={styles.selectedSubDescription}>
                  <div style={styles.selectedSubDescriptionInner}>
                    <p
                      style={{
                        textAlign: "left",
                        color: "#C6C6F2",
                        fontSize: 16,
                      }}
                    >
                      {`${selectedSubscription.stripeProduct.description}`}
                    </p>
                  </div>
                </div>

                <div style={styles.subscriptionPlanInfoMain}>
                  <div style={styles.subscriptionPlanInfoInner}>
                    <p
                      className="bolden-gate"
                      style={styles.subPlanInfo}
                    >{`Plan: ${selectedSubscription.plan.charAt(0).toUpperCase()}${selectedSubscription.plan.slice(1)}`}</p>
                    <p
                      className="bolden-gate"
                      style={styles.subPlanInfo}
                    >{`Purchased: ${moment(selectedSubscription.purchaseDate).format("MMMM D yyyy")}`}</p>
                    <p
                      className="bolden-gate"
                      style={styles.subPlanInfo}
                    >{`Renews: ${moment(selectedSubscription.renewalDate).format("MMMM D yyyy")}`}</p>
                  </div>
                </div>
              </div>
            </div>
            <div style={styles.cancelSubMain}>
              {(selectedSubscription.active && (
                <div
                  onClick={() => handleCancelSubscription(selectedSubscription)}
                  style={styles.cancelSubInner}
                >
                  <p className="bolden-gate" style={{ fontSize: 14 }}>
                    Cancel Subscription
                  </p>
                </div>
              )) || (
                <div
                  onClick={() =>
                    handleReactivateSubscription(selectedSubscription)
                  }
                  style={styles.reactivateSubMain}
                >
                  <p className="bolden-gate" style={{ fontSize: 14 }}>
                    Reactivate
                  </p>
                </div>
              )}
            </div>
          </div>
        )) ||
          "Your subscription's product details will show up here."}
      </div>
    </div>
  );
}

export default SubscriptionSpotlight;
