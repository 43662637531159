// JJ-108
import React, { useState, useEffect, useRef } from "react";
import { FaTimes } from "react-icons/fa";
// import Hixenis from "../images/HIXENIS_NEW_W_P.png";
import Hixenis from "../images/HIXENIS_NEW_W_P_SIMPLE.png";
import BounceLoader from "react-spinners/BounceLoader";
import { resetPassword, validatePasswordResetCode } from "../api/dataService";

function PasswordChangeWindow({
  style,
  windowHeight,
  closeWindow,
  changeUserPassword,
}) {
  const [visible, setVisible] = useState(false);
  const [loading, setLoadingState] = useState(false);
  const [loadingStateMessage, setLoadingStateMessage] = useState("");
  const [messageOverlayOpen, setMessageOverlayOpen] = useState(false);
  const [messageOverlayTitleText, setMessageOverlayTitleText] = useState("");
  const [messageOverlayTitleTextColor, setMessageOverlayTitleTextColor] =
    useState("#bbbbed");
  const [messageOverlayText, setMessageOverlayText] = useState("");

  const [emailInputActive, setEmailInputActive] = useState(true);
  const [passwordResetCodeInputActive, setPasswordResetCodeInputActive] =
    useState(false);

  const [emailInputVisible, setEmailInputVisible] = useState(true);
  const [passwordResetCodeInputVisible, setPasswordResetCodeInputVisible] =
    useState(false);

  const [passwordFieldsDisabled, setPasswordFieldsDisabled] = useState(false);

  const [passwordResetCode, setPasswordResetCode] = useState();

  const [fadeIn, setFadeIn] = useState(false);
  const [email, setEmail] = useState("");

  const [password, setPassword] = useState("");
  const [passwordConfirmed, setPasswordConfirmed] = useState("");

  const [messageOverlayWidth, setMessageOverlayWidth] = useState(0); //max 255

  const [phaseOneEnabled, setPhaseOneEnabled] = useState(false);
  const [phaseTwoEnabled, setPhaseTwoEnabled] = useState(false);
  const [phaseThreeEnabled, setPhaseThreeEnabled] = useState(true);

  const handleResetPasswordClick = (e) => {
    setLoadingState(true);
    setLoadingStateMessage("Requesting...");

    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

    if (!email || !emailRegex.test(email)) {
      setMessageOverlayOpen(true);

      setMessageOverlayTitleText("INVALID EMAIL");
      setMessageOverlayText("Please enter a valid email address.");
      setLoadingState(false);
      return;
    }

    resetPassword({ email: email })
      .then((response) => {
        if (response.status === "OK") {
          setPhaseOneEnabled(false);
          setPhaseTwoEnabled(true);
          setEmailInputActive(false);
          setPasswordResetCodeInputActive(true);
          setLoadingState(false);
          setMessageOverlayTitleText("CODE SENT");
          setMessageOverlayTitleTextColor("#03fc0b");
          setMessageOverlayText("We sent a password reset code to your email.");
          setMessageOverlayOpen(true);
        } else {
          setLoadingState(false);
          setMessageOverlayOpen(true);
          setMessageOverlayTitleText("ERROR");
          setMessageOverlayTitleTextColor("red");
          setMessageOverlayText(`${response.detail}`);
        }
      })
      .catch((error) => {
        setLoadingState(false);
        setMessageOverlayOpen(true);
        setMessageOverlayTitleText("ERROR");
        setMessageOverlayTitleTextColor("red");
        setMessageOverlayText(`${error.detail}`);
      });
  };

  const handleSubmitCodeClick = (e) => {
    setLoadingState(true);
    setLoadingStateMessage("Validating code...");

    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

    if (!email || !emailRegex.test(email)) {
      setMessageOverlayOpen(true);

      setMessageOverlayTitleText("Invalid Email");
      setMessageOverlayText("Please enter a valid email address.");
      setLoadingState(false);
      return;
    }

    validatePasswordResetCode({
      email: email,
      passwordResetCode: passwordResetCode,
    })
      .then((response) => {
        if (response.status === "OK") {
          setPhaseTwoEnabled(false);
          setPhaseThreeEnabled(true);
          setPasswordResetCodeInputActive(false);
          setLoadingState(false);
          setMessageOverlayTitleText("CODE VALID");
          setMessageOverlayTitleTextColor("#03fc0b");
          setMessageOverlayText(
            "Your code has been validated. Please enter your new password."
          );
          setEmailInputActive(false);
          setEmailInputVisible(false);

          setMessageOverlayOpen(true);
        } else {
          setLoadingState(false);
          setMessageOverlayOpen(true);
          setMessageOverlayTitleText("ERROR");
          setMessageOverlayTitleTextColor("red");
          setMessageOverlayText(`${response.detail}`);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoadingState(false);
        setMessageOverlayOpen(true);
        setMessageOverlayTitleText("ERROR");
        setMessageOverlayTitleTextColor("red");
        setMessageOverlayText(`${error.detail}`);
      });
  };

  const handleSubmitNewPasswordClick = () => {
    if (!password || password.length < 8) {
      setMessageOverlayOpen(true);
      setMessageOverlayTitleText("Invalid Password");
      setMessageOverlayText("Password must be at least 8 characters long.");
      return;
    }

    if (password !== passwordConfirmed) {
      setMessageOverlayOpen(true);
      setMessageOverlayTitleText("Passwords Do Not Match");
      setMessageOverlayText("Please ensure both password fields match.");
      return;
    }
    changeUserPassword(password);
  };

  let styles = {
    mainContainer: {
      ...style,
      width: "100%",
      height: "100vh",
      position: "absolute",
      backgroundColor: "rgba(0, 0, 0, 0.8)",
    },
    loadingOverlayMainContainer: {
      position: "absolute",
      zIndex: 12000,
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      width: "100%",
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      transition: "opacity 1s ease-in-out",
    },
    loadingOverlayInnerContainer: {
      padding: 30,
      backgroundColor: "rgba(44, 41, 63, 1)",
      borderRadius: 10,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      color: "white",
      boxShadow: "0 0px 10px rgba(120, 0, 255, 1)",
      border: "1px solid #6E6EF4",
    },
    messageOverlayMainContainer: {
      position: "absolute",
      zIndex: 12000,
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      width: "100%",
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      transition: "opacity 1s ease-in-out",
    },
    messageOverlayInnerContainer: {
      width: 350,
      backgroundColor: "#191632",
      borderRadius: 10,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      color: "white",
      boxShadow: "0 0px 15px rgba(0, 0, 0, .8)",
      border: "1px solid #6E6EF4",
      overflow: "hidden",
    },
    messageOverlayTitleContainer: {
      borderBottom: "0px solid #6E6EF4",
      width: "90%",
      flex: 1,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      paddingTop: 5,
    },
    messageOverlayTitleText: {
      marginTop: 5,
      fontSize: 18,
      color: messageOverlayTitleTextColor,
    },
    messageOverlayBodyText: {
      border: "0px solid red",
      width: "100%",
      flex: 1,
      display: "flex",
      justifyContent: "center",
      padding: 16,
      paddingTop: 0,
    },
    messageOverlayOKButtonContainer: {
      cursor: "pointer",
      borderTop: "1px solid #6E6EF4",
      width: "100%",
      flex: 1,
      display: "flex",
      justifyContent: "center",
      padding: 16,
    },
    messageOverlayOKText: { fontSize: 22, marginTop: 5, textAlign: "center" },
    innerContainer: {
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    innerContainer2: {
      border: "1.5px solid #6e6ef4",
      width: "100%",
      maxWidth: "375px",
      paddingBottom: 30,
      borderRadius: 10,
      transition: "width 0.3s ease",
      boxShadow: "0 0px 15px rgba(120, 0, 255, .5)",
      position: "relative",
    },
    closeWindowButtonContainer: { width: "100%", color: "white" },
    closeWindow: {
      marginLeft: 15,
      marginTop: 15,
      color: "#a6a6fc",
      cursor: "pointer",
    },
    logoContainer: {
      height: "15%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    logo: {
      maxWidth: "80%",
      maxHeight: "100%",
      objectFit: "contain",
    },
    changePasswordContainer: {
      height: "15%",
      color: "white",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: 15,
      textAlign: "center",
      flexDirection: "column",
    },
    changePasswordHeader: {
      fontSize: "1.5rem",
      fontWeight: "bold",
      color: "#a6a6fc",
      paddingBottom: 0,
      marginBottom: 0,
      lineHeight: 1,
    },
    changePasswordSubheader: { fontSize: "1rem" },
    phaseContainer: {
      height: "10%",
      color: "white",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
    },
    phaseOneMainContainer: {
      width: "100%",
      border: "0px solid red",
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      alignItems: "center",
    },
    phaseOneEmailInput: {
      color: "black",
      width: "80%",
      height: 50,
      padding: 15,
      borderRadius: 5,
    },
    phaseOneEmailInputDisabled: {
      color: "black",
      width: "80%",
      height: 50,
      padding: 15,
      borderRadius: 5,
      backgroundColor: "grey",
    },
    phaseTwoPasswordInput: {
      marginTop: 15,
      width: "80%",
      height: 50,
      padding: 15,
      borderRadius: 5,
      textAlign: "center",
      color: "#200844",
    },
    phaseThreeMainContainer: { width: "100%" },
    phaseThreeInnerContainer: {
      height: "10%",
      color: "white",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginBottom: 10,
    },
    phaseThreePasswordInput: {
      backgroundColor: passwordFieldsDisabled ? "grey" : "white",
      color: "black",
      width: "80%",
      height: 50,
      padding: 15,
      borderRadius: 5,
    },
    phaseThreePasswordInputContainerDisabled: {
      height: "10%",
      color: "white",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    phaseThreePasswordInputDisabled: {
      backgroundColor: passwordFieldsDisabled ? "grey" : "white",
      color: "black",
      width: "80%",
      height: 50,
      padding: 15,
      borderRadius: 5,
    },
    buttonsContainerMain: {
      height: "8%",
      color: "white",
      display: "flex",
      marginTop: 20,
      justifyContent: "center",
    },
    phaseOneResetPassword: {
      color: "white",
      backgroundColor: "#6e6ef4",
      width: "80%",
      height: 50,
      padding: 15,
      borderRadius: 5,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    phaseTwoValidateCode: {
      color: "white",
      backgroundColor: "#6e6ef4",
      width: "80%",
      height: 50,
      padding: 15,
      borderRadius: 5,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    phaseThreeChangePassword: {
      color: "white",
      backgroundColor: "#6e6ef4",
      width: "80%",
      height: 50,
      padding: 15,
      borderRadius: 5,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    backToLoginButton: {
      color: "white",
      backgroundColor: "green",
      width: "80%",
      height: 50,
      padding: 15,
      borderRadius: 5,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    legal: {
      height: "15%",
      color: "#a6a6fc",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
      padding: 20,
      fontSize: "0.65rem",
      lineHeight: "1.5",
    },
  };

  return (
    <div style={styles.mainContainer}>
      {loading && (
        <div style={styles.loadingOverlayMainContainer}>
          <div style={styles.loadingOverlayInnerContainer}>
            <BounceLoader
              color={"#717DFA"}
              size={50}
              aria-label="Loading Spinner"
              data-testid="loader"
              speedMultiplier={1}
            />
            <p style={{ marginTop: 5 }}>{loadingStateMessage}</p>
          </div>
        </div>
      )}

      {messageOverlayOpen && (
        <div style={styles.messageOverlayMainContainer}>
          <div style={styles.messageOverlayInnerContainer}>
            <div style={styles.messageOverlayTitleContainer}>
              <p className="bolden-gate" style={styles.messageOverlayTitleText}>
                {messageOverlayTitleText}
              </p>
            </div>
            <div style={styles.messageOverlayBodyText}>
              <p style={{ marginTop: 5, textAlign: "center" }}>
                {messageOverlayText}
              </p>
            </div>
            <div
              onClick={() => setMessageOverlayOpen(false)}
              style={styles.messageOverlayOKButtonContainer}
            >
              <p style={styles.messageOverlayOKText}>{"OK"}</p>
            </div>
          </div>
        </div>
      )}

      <div style={styles.innerContainer}>
        <div
          className="frosted-glass-container-blur-plus-plus-plus"
          style={styles.innerContainer2}
        >
          <div style={styles.closeWindowButtonContainer}>
            <FaTimes
              onClick={closeWindow}
              size={25}
              style={styles.closeWindow}
            />
          </div>
          <div style={styles.logoContainer}>
            <img src={Hixenis} alt="Logo" style={styles.logo} />
          </div>

          <div style={styles.changePasswordContainer}>
            <div style={styles.changePasswordHeader}>Change Your Password</div>
            <div style={styles.changePasswordSubheader}>
              Enter your new password.
            </div>
          </div>

          <div style={styles.phaseContainer}>
            {phaseOneEnabled && (
              <div style={styles.phaseOneMainContainer}>
                {(emailInputActive && (
                  <input
                    style={styles.phaseOneEmailInput}
                    type={"email"}
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                )) || (
                  <input
                    disabled
                    style={styles.phaseOneEmailInputDisabled}
                    type={"email"}
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                )}
              </div>
            )}

            {passwordResetCodeInputActive && phaseTwoEnabled && (
              <input
                className="bolden-gate"
                style={styles.phaseTwoPasswordInput}
                type={"text"}
                placeholder="Enter your code"
                value={passwordResetCode}
                onChange={(e) => {
                  const newValue = e.target.value
                    .replace(/[^0-9]/g, "")
                    .slice(0, 6);
                  setPasswordResetCode(newValue);
                }}
                maxLength={6}
              />
            )}

            {phaseThreeEnabled && (
              <div style={styles.phaseThreeMainContainer}>
                <div style={styles.phaseThreeInnerContainer}>
                  <input
                    disabled={passwordFieldsDisabled ? true : false}
                    style={styles.phaseThreePasswordInput}
                    type={"password"}
                    placeholder="New Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
                <div style={styles.phaseThreePasswordInputContainerDisabled}>
                  <input
                    disabled={passwordFieldsDisabled ? true : false}
                    style={styles.phaseThreePasswordInputDisabled}
                    type={"password"}
                    placeholder="Confirm New Password"
                    value={passwordConfirmed}
                    onChange={(e) => setPasswordConfirmed(e.target.value)}
                  />
                </div>
              </div>
            )}
          </div>

          <div style={styles.buttonsContainerMain}>
            {phaseOneEnabled && (
              <button
                className="bolden-gate"
                onClick={() => handleResetPasswordClick()}
                style={styles.phaseOneResetPassword}
              >
                Get Code
              </button>
            )}
            {phaseTwoEnabled && (
              <button
                className="bolden-gate"
                onClick={() => handleSubmitCodeClick()}
                style={styles.phaseTwoValidateCode}
              >
                Validate Code
              </button>
            )}
            {phaseThreeEnabled && (
              <button
                className="bolden-gate"
                onClick={() => handleSubmitNewPasswordClick()}
                style={styles.phaseThreeChangePassword}
              >
                Change Password
              </button>
            )}
            {!phaseOneEnabled && !phaseTwoEnabled && !phaseThreeEnabled && (
              <button
                className="bolden-gate"
                onClick={() => closeWindow()}
                style={styles.backToLoginButton}
              >
                Back to Login
              </button>
            )}
          </div>

          <div style={styles.legal}>
            By initiating a password change, you agree to our Terms of Service
            and Privacy Policy. Your data will be handled securely during the
            reset process. Please ensure that you follow the instructions
            carefully to protect your account.
          </div>
        </div>
      </div>
    </div>
  );
}

export default PasswordChangeWindow;
