// JJ-108
import React, { useState, useEffect } from "react";
import "../App.css";
import { useLocation, useNavigate } from "react-router-dom";
import Hixenis from "../images/HIXENIS_NEW_W_P.png";
import { FaArrowLeft } from "react-icons/fa";
import BounceLoader from "react-spinners/BounceLoader";
import { changeUserPassword, updateUserAccount } from "../api/dataService";
import PasswordChangeWindow from "../components/PasswordChangeWindow";
import { useUser } from "../stores/UserContext";

function AccountEditScreen() {

  // Uses React Context to get stored user data.
  const { user, updateUser } = useUser(); 

  const location = useLocation();
  const props = location.state || {}; 
  const userData = props.userData;
  const [isMobile, setIsMobile] = useState(window.innerWidth < 900); 
  const [loading, setLoadingState] = useState(false);
  const [loadingStateMessage, setLoadingStateMessage] = useState("");
  const [messageOverlayOpen, setMessageOverlayOpen] = useState(false);
  const [messageOverlayTitleText, setMessageOverlayTitleText] = useState("");
  const [messageOverlayTitleTextColor, setMessageOverlayTitleTextColor] = useState("#bbbbed");
  const [messageOverlayText, setMessageOverlayText] = useState("");
  const [actionDialogOpen, setActionDialogOpen] = useState(false);
  const [actionDialogTitleText, setActionDialogTitleText] = useState("");
  const [actionDialogText, setActionDialogText] = useState("");
  const [actionDialogAction, setActionDialogAction] = useState();
  const [firstName, setFirstName] = useState(user.firstName);
  const [lastName, setLastName] = useState(user.lastName);
  const [email, setEmail] = useState(user.email);
  const [firstNameOriginal, setFirstNameOriginal] = useState(user.firstName);
  const [lastNameOriginal, setLastNameOriginal] = useState(user.lastName);
  const [emailOriginal, setEmailOriginal] = useState(user.email);

  const [passwordChangeWindowOpen, setPasswordChangeWindowOpen] =
    useState(false);

  const handleResize = () => {
    const isCurrentlyMobile = window.innerWidth < 900;
    setIsMobile(isCurrentlyMobile);
  };

  

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const saveChanges = () => {

    setLoadingState(true);
    setLoadingStateMessage("Saving changes...");

    // Input validation checks fields before
    // changes are saved using Regex.
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

    if (!firstName || firstName.trim() === "") {
      setMessageOverlayOpen(true);
      setMessageOverlayTitleText("Invalid First Name");
      setMessageOverlayText("Please enter your first name.");
      setLoadingState(false);
      return;
    }

    if (!lastName || lastName.trim() === "") {
      setMessageOverlayOpen(true);
      setMessageOverlayTitleText("Invalid Last Name");
      setMessageOverlayText("Please enter your last name.");
      setLoadingState(false);
      return; 
    }

    if (!email || !emailRegex.test(email)) {
      setMessageOverlayOpen(true);
      setMessageOverlayTitleText("Invalid Email");
      setMessageOverlayText("Please enter a valid email address.");
      setLoadingState(false);
      return; 
    }

    var userAccountParams = {};

    // Checks for changes in data and prevents
    // unnecessary save if no changes are found.
    if (
      firstName === firstNameOriginal &&
      lastName === lastNameOriginal &&
      email === emailOriginal
    ) {
      setMessageOverlayOpen(true);
      setMessageOverlayTitleText("NOTIFICATION");
      setMessageOverlayText("No Changes Detected.");
      setLoadingState(false);
    } else {
      userAccountParams.userId = user.id;
      if (firstName !== firstNameOriginal) {
        userAccountParams.firstName = firstName;
      }
      if (lastName !== lastNameOriginal) {
        userAccountParams.lastName = lastName;
      }
      if (email !== emailOriginal) {
        userAccountParams.email = email;
      }

      updateUserAccount(userAccountParams)
        .then((response) => {
          if (response.status === "OK") {
            
            const userData = response.data;

            if (userAccountParams.email) {
              setActionDialogTitleText("Success");
              setActionDialogText(
                "Your changes have been saved. You must log in again to re-authenticate."
              );
              setActionDialogAction("force_logout");
              setActionDialogOpen(true);
              setLoadingState(false);
            } else {
              updateUser({
                id: userData.id,
                firstName: userData.firstName,
                lastName: userData.lastName,
                email: userData.email,
                isEmailVerified: userData.isEmailVerified,
                role: userData.role,
              });
              setFirstNameOriginal(userData.firstName);
              setLastNameOriginal(userData.lastName);
              setEmailOriginal(userData.email);
              setMessageOverlayOpen(true);
              setMessageOverlayTitleText("Success");
              setMessageOverlayText("Your changes have been saved.");
              setLoadingState(false);
            }
          } else {
            setMessageOverlayOpen(true);
            setMessageOverlayTitleText("ERROR");
            setMessageOverlayText(`${response.detail}`);
            setLoadingState(false);
          }
        })
        .catch((error) => {
          setMessageOverlayOpen(true);
          setMessageOverlayTitleText("ERROR");
          setMessageOverlayText(
            `Something went wrong. Please try again later. ${error}`
          );
          setLoadingState(false);
        });
    }
  };

  const executeActionDialogDirective = (navigate) => {
    if (actionDialogAction === "force_logout") {
      navigate("/");
    } else {
      setMessageOverlayOpen(true);
      setMessageOverlayTitleText("ERROR");
      setMessageOverlayText(`Something went wrong. Please try again later.`);
      setLoadingState(false);
    }
  };

  const closePasswordChangeWindow = () => {
    setPasswordChangeWindowOpen(false);
  };

  const onChangePassword = () => {
    setPasswordChangeWindowOpen(true);
  };

  const handleChangeUserPassword = (password) => {
    setPasswordChangeWindowOpen(false);
    setLoadingState(true);
    setLoadingStateMessage("Changing password...");
    changeUserPassword({ userId: user.id, password: password })
      .then((response) => {
        if (response.status === "OK") {
          console.log(response);
          setLoadingState(false);
          setActionDialogTitleText("Password Changed");
          setActionDialogText(
            "Your password has been changed. You must log in again to re-authenticate."
          );
          setActionDialogAction("force_logout");
          setActionDialogOpen(true);
          setLoadingState(false);
        } else {
          console.log(response);
          setLoadingState(false);
          setMessageOverlayOpen(true);
          setMessageOverlayTitleText("ERROR");
          setMessageOverlayTitleTextColor("red");
          setMessageOverlayText(`${response.detail}`);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoadingState(false);
        setMessageOverlayOpen(true);
        setMessageOverlayTitleText("ERROR");
        setMessageOverlayTitleTextColor("red");
        setMessageOverlayText(`${error.detail}`);
      });
  };

  const navigate = useNavigate();

  let styles = {
    container: {
      backgroundColor: "#090714",
      width: "100%",
      color: "white",
      display: "flex",
      flexDirection: "column",
    },
    loadingOverlayPrimaryContainer: {
      position: "absolute",
      zIndex: 12000,
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      width: "100%",
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      transition: "opacity 1s ease-in-out",
    },
    loadingOverlaySecondaryContainer: {
      padding: 30,
      backgroundColor: "rgba(44, 41, 63, 1)",
      borderRadius: 10,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      color: "white",
      boxShadow: "0 0px 10px rgba(120, 0, 255, 1)",
      border: "1px solid #6E6EF4",
    },
    messageOverlayPrimaryContainer: {
      position: "absolute",
      zIndex: 12000,
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      width: "100%",
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      transition: "opacity 1s ease-in-out",
    },
    messageOverlaySecondaryContainer: {
      width: 350,
      backgroundColor: "#191632",
      borderRadius: 10,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      color: "white",
      boxShadow: "0 0px 15px rgba(0, 0, 0, .8)",
      border: "1px solid #6E6EF4",
      overflow: "hidden",
    },
    messageOverlayTertiaryContainer: {
      borderBottom: "0px solid #6E6EF4",
      width: "90%",
      flex: 1,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      paddingTop: 5,
    },
    messageOverlayTitleText: {
      letterSpacing: 2,
      marginTop: 5,
      fontSize: 18,
      color: messageOverlayTitleTextColor,
    },
    messageOverlayBodyText: {
      border: "0px solid red",
      width: "100%",
      flex: 1,
      display: "flex",
      justifyContent: "center",
      padding: 16,
      paddingTop: 0,
    },
    messageOverlayButton: {
      cursor: "pointer",
      borderTop: "1px solid #6E6EF4",
      width: "100%",
      flex: 1,
      display: "flex",
      justifyContent: "center",
      padding: 16,
    },
    alertDialogContainer1: {
      position: "absolute",
      zIndex: 12000,
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      width: "100%",
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      transition: "opacity 1s ease-in-out",
    },
    alertDialogContainer2: {
      width: 350,
      backgroundColor: "#191632",
      borderRadius: 10,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      color: "white",
      boxShadow: "0 0px 15px rgba(0, 0, 0, .8)",
      border: "1px solid #6E6EF4",
      overflow: "hidden",
    },
    alertDialogContainer3: {
      borderBottom: "0px solid #6E6EF4",
      width: "90%",
      flex: 1,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      paddingTop: 5,
    },
    alertDialogTitleText: {
      letterSpacing: 2,
      marginTop: 5,
      fontSize: 18,
      color: "white",
    },
    alertDialogBodyText: {
      border: "0px solid red",
      width: "100%",
      flex: 1,
      display: "flex",
      justifyContent: "center",
      padding: 16,
      paddingTop: 0,
    },
    alertDialogButtonContainer1: {
      cursor: "pointer",
      borderTop: "1px solid #6E6EF4",
      width: "100%",
      flex: 1,
      display: "flex",
      justifyContent: "center",
      padding: 16,
    },
    alertDialogOKText: {
      fontSize: 16,
      marginTop: 5,
      textAlign: "center",
      color: "white",
    },
    headerContainer: {
      width: "100%",
      height: 175,
      border: "0px solid red",
      display: "flex",
      flexDirection: "row",
    },
    leftHeaderPanel: {
      width: "30%",
      height: "100%",
      border: "0px solid blue",
      height: "100%",
      padding: 30,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
    },
    hixenisLogoStyling: {
      maxWidth: 250,
      maxHeight: "100%",
      objectFit: "contain",
    },
    middlePanelHeader: {
      display: "flex",
      width: "40%",
      border: "0px solid green",
      height: "100%",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
    },
    screenTitle: {
      color: "#7484F9",
      fontSize: 22,
      paddingBottom: 10,
    },
    rightPanelHeader: {
      width: "30%",
      border: "0px solid yellow",
      height: "100%",
      padding: 30,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    mainContainerMobile: {
      width: "100%",
      height: 175,
      border: "0px solid red",
    },
    loadingOverlayContainer1: {
      position: "absolute",
      zIndex: 12000,
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      width: "100%",
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      transition: "opacity 1s ease-in-out",
    },
    loadingOverlayContainer2: {
      padding: 30,
      backgroundColor: "rgba(44, 41, 63, 1)",
      borderRadius: 10,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      color: "white",
      boxShadow: "0 0px 10px rgba(120, 0, 255, 1)",
      border: "1px solid #6E6EF4",
    },
    messageOverlayContainerMobile1: {
      position: "absolute",
      zIndex: 12000,
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      width: "100%",
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      transition: "opacity 1s ease-in-out",
    },
    messageOverlayContainerMobile2: {
      width: 350,
      backgroundColor: "#191632",
      borderRadius: 10,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      color: "white",
      boxShadow: "0 0px 15px rgba(0, 0, 0, .8)",
      border: "1px solid #6E6EF4",
      overflow: "hidden",
    },
    messageOverlayContainerMobile3: {
      borderBottom: "0px solid #6E6EF4",
      width: "90%",
      flex: 1,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      paddingTop: 5,
    },
    messageOverlayTitleTextMobile: {
      letterSpacing: 2,
      marginTop: 5,
      fontSize: 18,
      color: messageOverlayTitleTextColor,
    },
    messageOverlayBodyTextMobile: {
      border: "0px solid red",
      width: "100%",
      flex: 1,
      display: "flex",
      justifyContent: "center",
      padding: 16,
      paddingTop: 0,
    },
    messageOverlayButtonContainerMobile: {
      cursor: "pointer",
      borderTop: "1px solid #6E6EF4",
      width: "100%",
      flex: 1,
      display: "flex",
      justifyContent: "center",
      padding: 16,
    },
    actionDialogContainerMain: {
      position: "absolute",
      zIndex: 12000,
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      width: "100%",
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      transition: "opacity 1s ease-in-out",
    },
    actionDialogContainer2: {
      width: 350,
      backgroundColor: "#191632",
      borderRadius: 10,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      color: "white",
      boxShadow: "0 0px 15px rgba(0, 0, 0, .8)",
      border: "1px solid #6E6EF4",
      overflow: "hidden",
    },
    actionDialogContainer3: {
      borderBottom: "0px solid #6E6EF4",
      width: "90%",
      flex: 1,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      paddingTop: 5,
    },
    actionDialogTitleTextMobile: {
      letterSpacing: 2,
      marginTop: 5,
      fontSize: 18,
      color: "white",
    },
    actionDialogBodyTextMobile: {
      border: "0px solid red",
      width: "100%",
      flex: 1,
      display: "flex",
      justifyContent: "center",
      padding: 16,
      paddingTop: 0,
    },
    actionDialogButtonContainerMainMobile: {
      cursor: "pointer",
      borderTop: "1px solid #6E6EF4",
      width: "100%",
      flex: 1,
      display: "flex",
      justifyContent: "center",
      padding: 16,
    },
    actionDialogOKButtonMobile: {
      fontSize: 16,
      marginTop: 5,
      textAlign: "center",
      color: "white",
    },
    containerMobile1: {
      width: "100%",
      height: "100%",
      border: "0px solid blue",
      height: "100%",
      padding: 30,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
    },
    hixenisLogoMobile: {
      maxWidth: 400,
      maxHeight: "100%",
      objectFit: "contain",
    },
    containerMobile2: {
      display: "flex",
      border: "0px solid green",
      height: "100%",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
    },
    screenTitleMobile: {
      color: "#7484F9",
      fontSize: 22,
      paddingBottom: 10,
    },
    backButtonContainerMobile: {
      width: "100%",
      height: 50,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "flex-start",
      paddingLeft: 25,
      marginTop: 15,
    },
    backButtonContainerMobile2: {
      cursor: "pointer",
      flexDirection: "row",
      display: "flex",
      alignItems: "center",
      height: "100%",
    },
    bodyContainerMain: {
      width: "100%",
      height: "100vh",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: "20px",
      boxSizing: "border-box",
    },
    inputContainer1: {
      width: "100%",
      maxWidth: "400px",
    },
    inputLabel: {
      display: "block",
      marginBottom: "10px",
      color: "#6160BA",
    },
    inputNameFirst: {
      width: "100%",
      padding: "8px",
      marginTop: "5px",
      marginBottom: "15px",
      border: "1px solid #ccc",
      borderRadius: "4px",
      fontSize: "16px",
      color: "black",
    },
    inputNameLast: {
      width: "100%",
      padding: "8px",
      marginTop: "5px",
      marginBottom: "15px",
      border: "1px solid #ccc",
      borderRadius: "4px",
      fontSize: "16px",
      color: "black",
    },
    inputNameEmail: {
      width: "100%",
      padding: "8px",
      marginTop: "5px",
      marginBottom: "15px",
      border: "1px solid #ccc",
      borderRadius: "4px",
      fontSize: "16px",
      color: "black",
    },
    saveChangesButton: {
      width: "100%",
      padding: "10px",
      marginTop: "10px",
      backgroundColor: "#6E6EF4",
      color: "#fff",
      border: "none",
      borderRadius: "4px",
      cursor: "pointer",
      fontSize: "16px",
    },
    changePasswordButton: {
      width: "100%",
      padding: "10px",
      marginTop: "10px",
      backgroundColor: "#6c757d",
      color: "#fff",
      border: "none",
      borderRadius: "4px",
      cursor: "pointer",
      fontSize: "16px",
    },
  };

  return (
    <div style={styles.container}>
      {(!isMobile && (
        <div>
          {loading && (
            <div style={styles.loadingOverlayPrimaryContainer}>
              <div style={styles.loadingOverlaySecondaryContainer}>
                <BounceLoader
                  color={"#717DFA"}
                  size={50}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                  speedMultiplier={1}
                />
                <p style={{ marginTop: 5 }}>{loadingStateMessage}</p>
              </div>
            </div>
          )}

          {messageOverlayOpen && (
            <div style={styles.messageOverlayPrimaryContainer}>
              <div style={styles.messageOverlaySecondaryContainer}>
                <div style={styles.messageOverlayTertiaryContainer}>
                  <p
                    className="bolden-gate"
                    style={styles.messageOverlayTitleText}
                  >
                    {messageOverlayTitleText}
                  </p>
                </div>
                <div style={styles.messageOverlayBodyText}>
                  <p style={{ marginTop: 5, textAlign: "center" }}>
                    {messageOverlayText}
                  </p>
                </div>
                <div
                  onClick={() => setMessageOverlayOpen(false)}
                  style={styles.messageOverlayButton}
                >
                  <p
                    style={{ fontSize: 22, marginTop: 5, textAlign: "center" }}
                  >
                    {"OK"}
                  </p>
                </div>
              </div>
            </div>
          )}

          {actionDialogOpen && (
            <div style={styles.alertDialogContainer1}>
              <div style={styles.alertDialogContainer2}>
                <div style={styles.alertDialogContainer3}>
                  <p
                    className="bolden-gate"
                    style={styles.alertDialogTitleText}
                  >
                    {actionDialogTitleText}
                  </p>
                </div>
                <div style={styles.alertDialogBodyText}>
                  <p style={{ marginTop: 5, textAlign: "center" }}>
                    {actionDialogText}
                  </p>
                </div>

                <div style={styles.alertDialogButtonContainer1}>
                  <div
                    onClick={() => executeActionDialogDirective(navigate)}
                    style={{ width: "50%" }}
                  >
                    <p style={styles.alertDialogOKText}>{"OK"}</p>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div style={styles.headerContainer}>
            <div style={styles.leftHeaderPanel}>
              <img src={Hixenis} alt="Logo" style={styles.hixenisLogoStyling} />
            </div>
            <div style={styles.middlePanelHeader}>
              <h2 style={styles.screenTitle}>Account Edit</h2>
              <p style={{ color: "white" }}>
                {`${firstNameOriginal} ${lastNameOriginal}`}
              </p>
            </div>

            <div style={styles.rightPanelHeader}></div>
          </div>
        </div>
      )) || (
        <div style={styles.mainContainerMobile}>
          {loading && (
            <div style={styles.loadingOverlayContainer1}>
              <div style={styles.loadingOverlayContainer2}>
                <BounceLoader
                  color={"#717DFA"}
                  size={50}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                  speedMultiplier={1}
                />
                <p style={{ marginTop: 5 }}>{loadingStateMessage}</p>
              </div>
            </div>
          )}

          {messageOverlayOpen && (
            <div style={styles.messageOverlayContainerMobile1}>
              <div style={styles.messageOverlayContainerMobile2}>
                <div style={styles.messageOverlayContainerMobile3}>
                  <p
                    className="bolden-gate"
                    style={styles.messageOverlayTitleTextMobile}
                  >
                    {messageOverlayTitleText}
                  </p>
                </div>
                <div style={styles.messageOverlayBodyTextMobile}>
                  <p style={{ marginTop: 5, textAlign: "center" }}>
                    {messageOverlayText}
                  </p>
                </div>
                <div
                  onClick={() => setMessageOverlayOpen(false)}
                  style={styles.messageOverlayButtonContainerMobile}
                >
                  <p
                    style={{ fontSize: 22, marginTop: 5, textAlign: "center" }}
                  >
                    {"OK"}
                  </p>
                </div>
              </div>
            </div>
          )}

          {actionDialogOpen && (
            <div style={styles.actionDialogContainerMain}>
              <div style={styles.actionDialogContainer2}>
                <div style={styles.actionDialogContainer3}>
                  <p
                    className="bolden-gate"
                    style={styles.actionDialogTitleTextMobile}
                  >
                    {actionDialogTitleText}
                  </p>
                </div>
                <div style={styles.actionDialogBodyTextMobile}>
                  <p style={{ marginTop: 5, textAlign: "center" }}>
                    {actionDialogText}
                  </p>
                </div>

                <div style={styles.actionDialogButtonContainerMainMobile}>
                  <div
                    onClick={() => executeActionDialogDirective(navigate)}
                    style={{ width: "50%" }}
                  >
                    <p style={styles.actionDialogOKButtonMobile}>{"OK"}</p>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div style={styles.containerMobile1}>
            <img src={Hixenis} alt="Logo" style={styles.hixenisLogoMobile} />
            <div style={styles.containerMobile2}>
              <h2 style={styles.screenTitleMobile}>Account Edit</h2>
              <p style={{ color: "white", lineHeight: 0 }}>
                {`${firstNameOriginal} ${lastNameOriginal}`}
              </p>
            </div>
          </div>
        </div>
      )}
      <div style={styles.backButtonContainerMobile}>
        <div
          onClick={() => navigate(-1)}
          style={styles.backButtonContainerMobile2}
        >
          <FaArrowLeft color={"#A6A6FC"} size={20} />
          <p style={{ marginLeft: 15, color: "#6E6EF4", lineHeight: 1.3 }}>
            {"Back to Dashboard"}
          </p>
        </div>
      </div>

      <div style={styles.bodyContainerMain}>
        <div style={styles.inputContainer1}>
          <label style={styles.inputLabel}>
            First Name:
            <input
              type="text"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              placeholder="First Name"
              style={styles.inputNameFirst}
            />
          </label>

          <label style={styles.inputLabel}>
            Last Name:
            <input
              type="text"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              style={styles.inputNameLast}
            />
          </label>

          <label style={styles.inputLabel}>
            Email:
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              style={styles.inputNameEmail}
            />
          </label>

          <button
            onClick={() => saveChanges()}
            className="bolden-gate"
            style={styles.saveChangesButton}
          >
            💾 Save Changes
          </button>

          <button
            onClick={onChangePassword}
            style={styles.changePasswordButton}
          >
            🔑 Change Password
          </button>
        </div>

      </div>
      {passwordChangeWindowOpen && (
        <PasswordChangeWindow
          // style={{width: '100%', height: '100vh'}}
          isMobile={isMobile}
          windowHeight={window.innerHeight}
          closeWindow={() => closePasswordChangeWindow()}
          changeUserPassword={(password) =>
            handleChangeUserPassword(password, navigate)
          }
        />
      )}
    </div>
  );
}

export default AccountEditScreen;
