import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

function AdminDashboard() {

  const location = useLocation();
  const { userData } = location.state || {};  // Safely extract data

  const [user, setUser] = useState(userData.user);
  const [userNameFirst, setUserNameFirst] = useState(userData.user.firstName);
  const [userNameLast, setUserNameLast] = useState(userData.user.lastName);
  const [userToken, setUserToken] = useState(userData.token);

  return (
    <div className="bolden-gate" style={{display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: 56, backgroundColor: 'red', width: '100%', minHeight:'100vh', color: 'white'}}>
        {`Welcome to the Admin dashboard, ${userNameFirst}!`}
    </div>
  ); 
}
let styles = {
  container: {
    width: "100%",
    minHeight: "100vh", // Ensures the container fills the viewport height
    display: "flex",
  },
};
export default AdminDashboard;
